import React from 'react';
import { withSitecoreContext, Text, RichText, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import Asset from '../../../Foundation/DFS/Asset';
import { withDatasourceCheck } from '../../../Foundation/HOC/withDatasourceCheck';
import SocialMedia from './socialMedia';

const AuthorCopy = ({ fields, rendering, sitecoreContext }) => {
  const isEditing = sitecoreContext && sitecoreContext.pageEditing;
  const Author = fields?.Author?.fields;

  if (!Author) {
    return null;
  }

  return (
    <div className="intro">
      <div className="author-copy container">
        <div className="row ">
          <div className="col-12">
            <Text tag="h1" className="one-column" field={Author.Name} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 col-lg-3 col-xs-12 text-center p-3">
            <div className="row"></div>
            <div className="author-image-cropper">
              <Asset editor={isEditing} field={Author.Photo} className="author-image" />
            </div>
            <SocialMedia fields={Author} type={'top'} />
          </div>
          <div className="col-md-8 col-lg-9 col-xs-12">
            <div className="content-one-column bio-panel">
              <RichText field={Author.Biography} />
              <SocialMedia fields={Author} type={'bottom'} isEditing={isEditing} />
              <div className="cta">
                <Placeholder name="phCTA" rendering={rendering} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withSitecoreContext()(withDatasourceCheck()(AuthorCopy));
