import React from 'react';
import { Text, RichText, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { ReactComponent as LocationSvg } from '../../../../assets/icons/location.svg';
import { withDatasourceCheck } from '../../../Foundation/HOC/withDatasourceCheck';

const IntroTwoColumn = ({ fields, rendering }) => (
  <div className="intro">
    <div className="container">
      <div className="row">
        <div role="main" aria-label="Intro" className="col-md-6">
          {fields.Title.value !== '' && <Text tag="h1" field={fields.Title} />}
          {fields['Label 1'].value !== '' && fields['Address 1'].value !== '' && (
            <div className="col-md-12">
              <div className="text-center">
                <p className="address">
                  <Text field={fields['Label 1']} />{' '}
                  <a
                    href={'https://maps.google.ca/maps?q=' + fields['Address 1'].value}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <LocationSvg /> <Text field={fields['Address 1']} />
                  </a>
                </p>
              </div>
            </div>
          )}
          {fields['Label 2'].value !== '' && fields['Address 2'].value !== '' && (
            <div className="col-md-12">
              <div className="text-center">
                <p className="address">
                  <Text field={fields['Label 2']} />{' '}
                  <a
                    href={'https://maps.google.ca/maps?q=' + fields['Address 2'].value}
                    rel="noreferrer"
                    target="_blank"
                  >
                    <LocationSvg /> <Text field={fields['Address 2']} />
                  </a>
                </p>
              </div>
            </div>
          )}
        </div>
        <div className="col-md-6">
          <RichText field={fields.Copy} />
          <div className="cta">
            <Placeholder name="phCTA" rendering={rendering} />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default withDatasourceCheck()(IntroTwoColumn);
