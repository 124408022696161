import React from 'react';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';

const ContactUs = ({ title, officeAddress, phoneNumber }) => (
  <React.Fragment>
    <Text tag="div" field={title} className="div-as-h2-nav" />
    <p>
      <Text field={officeAddress} />
      <br />
      <Text tag="span" field={phoneNumber} className="phone" />
    </p>
  </React.Fragment>
);

export default ContactUs;
