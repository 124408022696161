import { FieldTypes } from '@sitecore-jss/sitecore-jss-react-forms';

/*
 Overload fieldTypes basic to support custom field definition
 if you want to add new form field add the field name and sitecore Id here
*/
const AddedFieldType = {
  'Contact Email Hidden': '{ECA4C0D1-2A43-427D-99B8-1E2A52A4C8DC}',
  'CID Hidden': '{B3F4D703-AD11-4642-BF54-223DB3A4E2BF}',
  'Residence Hidden': '{E1EF9575-EF70-4F90-8804-83607422E946}',
  'Guest List': '{00349461-F7E2-44DA-BB07-2EAB44FEAAE0}',
  'Image Video': '{0E9BB892-90DE-44EF-9E79-1DAD4465C50F}',
  'Two Column Divider': '{C6837E12-DB1F-42FA-A22F-FDA2124C1208}',
  Section: '{447AA745-6D29-4B65-A5A3-8173AA8AF548}',
  'Residence Selection CBL': '{8F7529EE-5B84-48AF-BEEF-3C4887346400}',
  'Radio Button List': '{EDBD38A8-1AE9-42EC-8CCD-F5B0E2998B4F}',
  'Checkbox List': '{D86A361A-D4FF-46B2-9E97-A37FC5B1FE1A}',
  Email: '{886ADEC1-ABF8-40E1-9926-D9189C4E8E1B}',
  Telephone: '{0908030B-4564-42EA-A6FA-C7A5A2D921A8}',
  'Raw Html': '{F6ABF2D4-D864-48D7-A464-75025A42162E}',
};

const fieldsExtended = {
  ...AddedFieldType,
  ...FieldTypes,
};
export default fieldsExtended;
