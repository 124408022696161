import React, { useEffect } from 'react';
import { isExperienceEditorActive, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import useWindowSize from '../../../../../Foundation/Hooks/useWindowSize';

const Header = ({ children, styleCode, hideOnMobile, id }) => {
  const { width } = useWindowSize();
  const { sitecoreContext } = useSitecoreContext();

  useEffect(() => {
    const updateHeroPosition = () => {
      setTimeout(() => {
        const scElement = document.querySelector('#scCrossPiece');

        const height = scElement?.style.height;
        const imageEl = document.querySelector('.hero>picture img');
        const videoEl = document.querySelector('.hero>video');

        if (imageEl) {
          imageEl.style.top = height;
        }

        if (videoEl) {
          videoEl.style.top = height;
        }
      }, 0);
    };

    if (
      (isExperienceEditorActive() || sitecoreContext?.pageState === 'preview') &&
      typeof document !== 'undefined'
    ) {
      let frameDocument;

      //  update hero position in the first render before clicking anything
      const intervalId = setInterval(() => {
        if (document.querySelector('#scCrossPiece')) {
          frameDocument = document.querySelector('#scWebEditRibbon')?.contentWindow.document;

          updateHeroPosition();
          frameDocument?.addEventListener('click', updateHeroPosition);
          clearInterval(intervalId);
        }
      }, 500);

      return () => frameDocument?.removeEventListener('click', updateHeroPosition);
    }
  }, []);

  const getHeroBannerClassName = () => {
    const classNames = ['hero'];

    classNames.push(styleCode);

    if (!hideOnMobile) {
      return classNames.join(' ');
    }

    if (width < 1200) {
      styleCode === 'small' ? classNames.push('x-small') : classNames.push(styleCode, 'x-small');
    } else {
      styleCode === 'x-small' ? classNames.push('small') : classNames.push(styleCode, 'small');
    }

    if (isExperienceEditorActive()) {
      classNames.push('experience-editor');
    }
    return [...new Set(classNames)].join(' ');
  };
  return (
    <header className={getHeroBannerClassName()} id={id}>
      {children}
    </header>
  );
};

export default Header;
