import React from 'react';
import { Style } from '../../../../Foundation/Utils/stringUtils';
import { canUseDOM } from '../../../../Foundation/Utils';
import { residenceService } from '../../../../Foundation/services';
// elements
import SelectCities from './components/SelectCities';
import SelectProvinces from './components/selectProvinces';
import Checkbox from './components/checkbox';
import Map from './components/map';
import ResidencesHidden from './components/residencesHidden';
import TbResidenceValidator from './components/tbResidenceValidator';
import { isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';

const ResidenceSelectionCBList = (props) => {
  const { fields, itemId, defaultEvent, errors, FieldErrorComponent } = props;
  const isMobile = canUseDOM ? window.matchMedia('(max-width: 768px)').matches : false;
  const showUnderConstruction = fields.model.showUnderConstruction === '1';
  const [provinces, setProvinces] = React.useState([]);
  const [cities, setCities] = React.useState([]);
  const [notFirstLoad, setNotFirstLoad] = React.useState(false);

  const [provienceSel, setProvienceSel] = React.useState('');
  const [citiesSel, setCitiesSel] = React.useState('');

  const [checkbox, setCheckbox] = React.useState([]);
  const [markers, setMarkers] = React.useState([]);
  const [resMapLocations, setResMapLocations] = React.useState([]);
  const [markerIcon, setMarkerIcon] = React.useState({});
  const [highligtedMarkerIcon, setHighligtedMarkerIcon] = React.useState({});
  const [contextitemId, setCntextitemId] = React.useState(itemId);
  const [selected, setSelected] = React.useState('');
  const [showMap, setShowMap] = React.useState(fields.model.showMap === '1');
  const mapKey = fields.model.mapKey || '';
  const maxSelectedItem = +parseInt(fields.model.maxNumberOfResidencesSelectable) || 0;
  const [selectedItemList, setSelectedItemList] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const showHealthcareProfessionalsLabel =
    fields.model.showHealthcareProfessionalsLabel === '1' || false;

  const provinceAccessibilityLabel = fields.model.provinceDropdownAccessibilityLabel || '';
  const cityAccessibilityLabel = fields.model.cityDropdownAccessibilityLabel || '';

  React.useEffect(() => {
    if (fields.model.prepopulateResidence === '1') {
      getResidenceSelection();
    } else {
      getProvinsesAndCities();
    }
  }, []);
  const getResidenceSelection = () => {
    residenceService
      .getResidenceById(contextitemId)
      .then((data) => {
        if (data) {
          setCntextitemId(data);
          returnFormState(data);
        }
      })
      .catch(() => {});
  };

  const getProvinsesAndCities = async () => {
    setLoading(true);
    const [provinces, cities] = await Promise.all([
      residenceService.getProvinces().catch(() => {}),
      residenceService.getCities(showUnderConstruction).catch(() => {}),
    ]);
    setLoading(false);
    if (!notFirstLoad && (!cities?.length || !provinces?.length)) {
      setNotFirstLoad(true);
    }
    // the cities come from the API sorted by distance, so we make copies of the initial entry, to ensure that the first selected city is the closest one, then we can sort the cities by name afterwards.
    const firstProvince = cities?.length ? cities[0].ParentId.slice(0) : '';
    const firstCity = cities?.length ? cities[0].Value.slice(0) : '';
    cities?.length && setSelected({ ...cities[0] });
    cities?.length && setCitiesSel(firstCity);
    cities?.length && setProvienceSel(firstProvince);
    provinces?.length && setProvinces(sortAlphabetically(provinces));
    cities?.length && setCities(sortAlphabetically(cities));

    function sortAlphabetically(array) {
      return array.sort((a, b) => (a.Text > b.Text ? 1 : -1)); // sort alphabetically by visual text
    }
  };

  const returnFormState = (e) => {
    defaultEvent(
      fields.valueField.id,
      e,
      e.length ? true : false,
      'Please select at least 1 residence.'
    );
  };
  React.useEffect(() => {
    getResidencesbyCity(citiesSel);
  }, [citiesSel]);

  const getResidencesbyCity = async (selectedCityId) => {
    if (!selectedCityId) {
      return;
    }
    setSelectedItemList([]);
    const result = await residenceService
      .getResidencesbyCityId(selectedCityId, showUnderConstruction)
      .catch(() => {});
    if (!result?.length) {
      return;
    }
    result.some((item) => {
      item.checked = false;
    });
    setCheckbox(result);
    setResMapLocations(result);
  };

  function handleSelectProvince(value) {
    let a = cities.find((item) => item.ParentId === value);
    setCitiesSel(a.Value);
    setProvienceSel(value);
  }

  if (!fields) {
    return <></>;
  }
  return (
    <React.Fragment>
      <ResidencesHidden
        selected={selected}
        contextitemId={contextitemId}
        fields={fields}
        defaultEvent={defaultEvent}
      />

      <input
        type="hidden"
        id="residence-selection-prepopulate"
        value={fields.model.prepopulateResidence}
      />
      {itemId && <input type="hidden" id="context-item-id" value={contextitemId} />}
      {fields.model.prepopulateResidence === '1' && isExperienceEditorActive() ? (
        <div>Residence Selection List is pre populated</div>
      ) : provinces.length && cities.length ? (
        <div id="res-sel-element">
          <div className="row">
            <div className="col-12 text-center py-3 corporate-row-height">
              <label className="label-as-h4">{fields.model.title}</label>
              <div className="wrapper" style={Style('position: relative;')}>
                <div className="navi"></div>
                <div
                  id="infoi"
                  style={Style(
                    'position: absolute; top: 0;  right: 0; width: 0; height:0; display: none;'
                  )}
                >
                  <TbResidenceValidator contextitemId={contextitemId} defaultEvent={defaultEvent} />
                </div>
              </div>
            </div>
          </div>
          <div className="row git-cooperate-row">
            <div className="col-sm-6" style={Style('padding-top: 4px')}>
              {provinces.length && (
                <SelectProvinces
                  ids={'provienceSelect'}
                  option={provinces}
                  selected={provienceSel}
                  handler={(value) => {
                    handleSelectProvince(value);
                  }}
                  values={setSelected}
                  labelText={provinceAccessibilityLabel}
                />
              )}
            </div>
            <div className="col-sm-6" style={Style('padding-top: 4px')}>
              {cities.length && (
                <SelectCities
                  ids={'citySelect'}
                  value={provienceSel}
                  selected={citiesSel}
                  option={cities}
                  reload={true}
                  handler={(value) => {
                    setCitiesSel(value);
                  }}
                  values={setSelected}
                  labelText={cityAccessibilityLabel}
                />
              )}
            </div>
          </div>

          <div className="row">
            <div className={`${showMap && 'col-md-6'} col-sm-12`}>
              <div id="checkbox-container" className="corporate-row-margin">
                {checkbox && (
                  <Checkbox
                    markers={markers}
                    residences={checkbox}
                    showMap={showMap}
                    handler={setSelected}
                    highligtedMarkerIcon={highligtedMarkerIcon}
                    markerIcon={markerIcon}
                    selected={selected}
                    maxSelectedItem={maxSelectedItem}
                    selectedItemList={selectedItemList}
                    setSelectedItemList={setSelectedItemList}
                    showLabel={showHealthcareProfessionalsLabel}
                    legendText={fields.model.title}
                  />
                )}
              </div>
            </div>
            {showMap && !isMobile && resMapLocations.length ? (
              <div className="col-sm-6" style={Style('padding-top: 10px;')}>
                <Map
                  markersData={resMapLocations}
                  setMarkersHandler={setMarkers}
                  setMarkerIconHandler={setMarkerIcon}
                  setHighligtedMarkerIconHandler={setHighligtedMarkerIcon}
                  mapKey={mapKey}
                  maxSelectedItem={maxSelectedItem}
                  selectedItemList={selectedItemList}
                  setSelectedItemList={setSelectedItemList}
                />
              </div>
            ) : null}
          </div>
          <FieldErrorComponent errors={errors} />
        </div>
      ) : loading ? (
        <LoadingAnimation />
      ) : (
        notFirstLoad && <div>Not fetch provincies or cities</div>
      )}
    </React.Fragment>
  );
};
const LoadingAnimation = () => (
  <svg
    width="100%"
    height="100%"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
    className="loading-indicator"
  >
    <circle cx="16" cy="50" r="10" fill="#587A8D">
      <animate
        attributeName="r"
        values="10;0;0;0;0"
        keyTimes="0;0.25;0.5;0.75;1"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
        calcMode="spline"
        dur="1.8s"
        repeatCount="indefinite"
        begin="0s"
      ></animate>
      <animate
        attributeName="cx"
        values="84;84;84;84;84"
        keyTimes="0;0.25;0.5;0.75;1"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
        calcMode="spline"
        dur="1.8s"
        repeatCount="indefinite"
        begin="0s"
      ></animate>
    </circle>
    <circle cx="16" cy="50" r="10" fill="#587A8D">
      <animate
        attributeName="r"
        values="0;10;10;10;0"
        keyTimes="0;0.25;0.5;0.75;1"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
        calcMode="spline"
        dur="1.8s"
        repeatCount="indefinite"
        begin="-0.9s"
      ></animate>
      <animate
        attributeName="cx"
        values="16;16;50;84;84"
        keyTimes="0;0.25;0.5;0.75;1"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
        calcMode="spline"
        dur="1.8s"
        repeatCount="indefinite"
        begin="-0.9s"
      ></animate>
    </circle>
    <circle cx="16" cy="50" r="10" fill="#587A8D">
      <animate
        attributeName="r"
        values="0;10;10;10;0"
        keyTimes="0;0.25;0.5;0.75;1"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
        calcMode="spline"
        dur="1.8s"
        repeatCount="indefinite"
        begin="-0.45s"
      ></animate>
      <animate
        attributeName="cx"
        values="16;16;50;84;84"
        keyTimes="0;0.25;0.5;0.75;1"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
        calcMode="spline"
        dur="1.8s"
        repeatCount="indefinite"
        begin="-0.45s"
      ></animate>
    </circle>
    <circle cx="16" cy="50" r="10" fill="#587a8d">
      <animate
        attributeName="r"
        values="0;10;10;10;0"
        keyTimes="0;0.25;0.5;0.75;1"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
        calcMode="spline"
        dur="1.8s"
        repeatCount="indefinite"
        begin="0s"
      ></animate>
      <animate
        attributeName="cx"
        values="16;16;50;84;84"
        keyTimes="0;0.25;0.5;0.75;1"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
        calcMode="spline"
        dur="1.8s"
        repeatCount="indefinite"
        begin="0s"
      ></animate>
    </circle>
    <circle cx="16" cy="50" r="10" fill="#587A8D">
      <animate
        attributeName="r"
        values="0;0;10;10;10"
        keyTimes="0;0.25;0.5;0.75;1"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
        calcMode="spline"
        dur="1.8s"
        repeatCount="indefinite"
        begin="0s"
      ></animate>
      <animate
        attributeName="cx"
        values="16;16;16;50;84"
        keyTimes="0;0.25;0.5;0.75;1"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
        calcMode="spline"
        dur="1.8s"
        repeatCount="indefinite"
        begin="0s"
      ></animate>
    </circle>
  </svg>
);
export default ResidenceSelectionCBList;
