import React from 'react';
import { withSitecoreContext, Text, RichText, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { withDatasourceCheck } from '../../../Foundation/HOC/withDatasourceCheck';
import Asset from '../../../Foundation/DFS/Asset';

const index = ({ fields, rendering, sitecoreContext }) => {
  const isEditing = sitecoreContext && sitecoreContext.pageEditing;
  return (
    <div id={fields['Anchor ID'].value} className="floorplan">
      <div className="container" role="complementary" aria-label={fields['Anchor ID'].value}>
        <div className="row">
          <div className="col-md-6">
            <Text tag="h2" className="display-4" field={fields.Title} />
            <RichText field={fields.Description} />
            <Placeholder name="phCTA" rendering={rendering} />
          </div>
          <div className="col-md-6">
            <a rel="noreferrer" target="_blank">
              <Asset editor={isEditing} field={fields.Image} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withSitecoreContext()(withDatasourceCheck()(index));
