import React from 'react';

const RawHtml = ({ fields }) => {
  const { model } = fields;

  return (
    <div className={`${model.cssClass}`}>
      {/* We use dangerouslySetInnerHTML to set the HTML content directly into the DOM.
        This approach is necessary when you want to render raw HTML content in React */}
      <div dangerouslySetInnerHTML={{ __html: model.html }} />
    </div>
  );
};

export default RawHtml;
