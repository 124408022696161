import React from 'react';
import SVG from 'react-inlinesvg';

const Logo = ({ imageUrl, linkUrl, description }) => (
  <a href={linkUrl} className="logo" aria-label={description ?? 'Amica Corporate Logo'}>
    <span className="svg-alt">{description ?? 'Amica Corporate Logo'}</span>
    <SVG
      src={imageUrl}
      title={description ?? 'Amica Corporate Logo'}
      uniquifyIDs={true}
      preProcessor={(code) => code.replace(/id=".*?"/g, '')}
    />
  </a>
);

export default Logo;
