import React, { useState, createContext } from 'react';
import { withDatasourceCheck } from '../../../Foundation/HOC/withDatasourceCheck';
import { Placeholder, Text } from '@sitecore-jss/sitecore-jss-react';

export const FAQContext = createContext({
  expandedAccordionId: '',
  setExpandedAccordionId: (any) => any,
});

const FAQContainer = ({ fields, rendering }) => {
  const [expandedAccordionId, setExpandedAccordionId] = useState('');

  if (!fields) return <></>;

  return (
    <div className="bg-white">
      <div className="container faq">
        {fields.Heading && <Text tag="h2" className="h2-text" field={fields.Heading} />}
        <div className="accordions-section">
          <FAQContext.Provider value={{ expandedAccordionId, setExpandedAccordionId }}>
            <Placeholder name="phFAQ" rendering={rendering} />
          </FAQContext.Provider>
        </div>
      </div>
    </div>
  );
};

export default withDatasourceCheck()(FAQContainer);
