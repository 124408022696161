import React from 'react';
import { Text, isExperienceEditorActive, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { withDatasourceCheck } from '../../../Foundation/HOC/withDatasourceCheck';

const NavigationPanelContainer = (props) => {
  var fields = props.fields;
  var alignment = fields?.Alignment?.fields?.Value?.value;
  var alignmentClass = alignment !== undefined ? 'headline ' + alignment : 'headline';
  return (
    <div role="complementary" aria-label="Panel" className="container-fluid navigation-panel p-0">
      {/* extra classNames: @ComponentHelper.DropdownStyles @Model.AdditionalStyles*/}
      <div className="container py-5">
        {((fields?.Headline && fields?.Headline !== '') || isExperienceEditorActive()) && (
          <div className={alignmentClass}>
            {fields?.Headline && <Text tag="h2" field={fields.Headline} />}
          </div>
        )}
        <div className="row justify-content-center text-center">
          <Placeholder name="phNavigationPanel" rendering={props.rendering} />
        </div>
      </div>
    </div>
  );
};
export default NavigationPanelContainer;
