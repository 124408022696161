import React from 'react';
import { canUseDOM, getCookie } from '../../../../Foundation/Utils';

const CIDHidden = ({ fields, defaultEvent }) => {
  const [value, setValue] = React.useState('');
  React.useEffect(() => {
    defaultEvent(fields.valueField.name, value, true);
  }, [value]);

  React.useEffect(() => {
    if (canUseDOM && window.ga) {
      window.ga(() => {
        setValue(window.ga.getAll()[0].get('clientId'));
      });
    } else {
      var gaCookie = getCookie('_ga');
      if (gaCookie) {
        var gaCookieArray = gaCookie.split('.');
        if (gaCookieArray.length > 3) {
          setValue(gaCookieArray[2] + '.' + gaCookieArray[3]);
        }
      }
    }
  });
  if (!fields) {
    return <></>;
  }
  return (
    <input
      className={fields.model.cssClass}
      id={fields.valueField.id}
      name={fields.valueField.name}
      type="hidden"
      value={value}
      data-sc-tracking={fields.model.isTrackingEnabled}
      data-sc-field-name={fields.model.name}
    />
  );
};

export default CIDHidden;
