import React from 'react';
import clsx from 'clsx';
import Collapse from 'react-bootstrap/Collapse';

const MainNavigation = ({ item }) => {
  const [open, setOpen] = React.useState(false);

  if (!item) {
    return null;
  }

  const hasChildren = item?.hasChildren && item?.childNavigation?.length > 0;

  return (
    <li role="menuitem" className={clsx(hasChildren && 'has-submenu')}>
      {hasChildren ? (
        <>
          <a
            aria-haspopup="true"
            aria-expanded={open}
            className={clsx(item?.isActive && 'current')}
            href={item?.linkUrl}
          >
            {item?.linkText}
          </a>
          <button
            className={clsx('submenu-toggler', 'plus', !open && 'collapsed')}
            data-toggle="collapse"
            onClick={() => setOpen((prev) => !prev)}
            data-target={item?.linkText?.replace(/(\s)/g, '')}
            aria-label="Toggle Sub Menu"
            aria-expanded={open}
          >
            <span className="plus-box">
              <span className="plus-inner"></span>
            </span>
          </button>
          <Collapse in={open}>
            <ul id={item?.linkText?.replace(/(\s)/g, '')}>
              {Array.isArray(item?.childNavigation) &&
                item?.childNavigation?.map((subItem, k) => (
                  <li key={k}>
                    <a href={subItem?.linkUrl}>{subItem?.linkText}</a>
                  </li>
                ))}
            </ul>
          </Collapse>
        </>
      ) : (
        <a
          className={clsx(item?.isActive && 'current', item.includeBorder && 'menu-border')}
          href={item?.linkUrl}
        >
          {item?.linkText}
        </a>
      )}
    </li>
  );
};

export default MainNavigation;
