import React from 'react';
import ResidenceList from '../ResidenceList';

const CityList = ({ cities }) => {
  if (!cities?.length) {
    return null;
  }

  const getLinkProps = (city = {}) => {
    const residencesToDisplay = () => {
      return city.Residences.filter((el) => el.show === '1' && !el.campaign);
    };
    if (city.LandingPage) {
      return { target: city.LinkTarget, href: city.LandingPage };
    } else if (city.Residences.length === 1) {
      return { href: city.Residences[0].url };
    } else if (residencesToDisplay().length === 1) {
      return { href: residencesToDisplay()[0].url };
    } else {
      return { href: `/find-a-residence/${city.Name.replace(' ', '-').toLowerCase()}` };
    }
  };

  return (
    <ul>
      {cities.map((city, index) =>
        city.Residences.length ? (
          <li key={index}>
            <h3>
              <a {...getLinkProps(city)}>{city.Name}</a>
            </h3>
            <ResidenceList residences={city.Residences} />
          </li>
        ) : null
      )}
    </ul>
  );
};

export default CityList;
