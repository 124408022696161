import React from 'react';
import { Navbar as BootstapNavbar } from 'react-bootstrap';

const ariaId = 'amica-navbar-nav';

const Navbar = ({ children }) => (
  <BootstapNavbar
    expand="lg"
    bsPrefix="main-nav" // override default classnames
  >
    <div className="container">{children}</div>
  </BootstapNavbar>
);

const Collapse = ({ children }) => (
  <BootstapNavbar.Collapse id={ariaId}>
    <ul className="menu-list" role="menu">
      {children}
    </ul>
  </BootstapNavbar.Collapse>
);

const Toggle = () => (
  <BootstapNavbar.Toggle aria-controls={ariaId} className="menu-toggler hamburger">
    <span className="hamburger-box">
      <span className="hamburger-inner" />
    </span>
  </BootstapNavbar.Toggle>
);

const Footer = ({ children }) => {
  return (
    <li role="menuitem" className="d-lg-none text-center footer-content">
      {children}
    </li>
  );
};

Navbar.Collapse = Collapse;
Navbar.Toggle = Toggle;
Navbar.Footer = Footer;

export default Navbar;
