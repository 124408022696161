import React from 'react';

const ResidenceHidden = ({ fields, defaultEvent }) => {
  const value = fields.model.contactResidences || '';
  React.useEffect(() => {
    defaultEvent(fields.valueField.name, value, true);
  }, []);
  if (!fields) {
    return <></>;
  }
  return (
    <input
      className={fields.model.cssClass}
      id={fields.valueField.id}
      name={fields.valueField.name}
      type="hidden"
      value={value}
      data-sc-tracking={fields.model.isTrackingEnabled}
      data-sc-field-name={fields.model.name}
    />
  );
};

export default ResidenceHidden;
