import React, { useState, useEffect } from 'react';
import { Text, withSitecoreContext, Placeholder, RichText } from '@sitecore-jss/sitecore-jss-react';
import Asset from '../../../Foundation/DFS/Asset';
import { graphQLEndpoint } from '../../../../temp/config';
import ShareButtons from '../../../Foundation/components/ShareButtons';

const shareThisQuery =
  'query={ datasource(value: "{27B984CA-F5BA-41DC-9687-7C2FAC3DEDB1}") { script: field(name: "value") { value } } }';

const useScript = (url) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = url;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [url]);
};

async function getShareThisSource() {
  const response = await fetch(graphQLEndpoint + '&' + shareThisQuery);
  const data = await response.json();
  const scriptTag = data.data.datasource.script.value;
  var src = scriptTag.substring(scriptTag.indexOf('src') + 5, scriptTag.indexOf('>') - 1);
  return src;
}

function resizeText(e, size, setArticleStyle) {
  e.preventDefault();
  const sizeModifier = size + 'em';
  setArticleStyle({ fontSize: sizeModifier });
}

const Article = (props) => {
  const isEditing = props.sitecoreContext && props.sitecoreContext.pageEditing;
  var fields = props.fields;
  const [shareThisTag, setShareThisTag] = useState('');
  const [classExperienceEditor, setExperienceEditorClass] = useState('article');
  const [articleStyle, setArticleStyle] = useState({});

  useEffect(() => {
    getShareThisSource().then((result) => {
      setShareThisTag(result);
    });
    if (isEditing) {
      setExperienceEditorClass('article articleExperienceEditor');
    }
  }, []);

  useScript(shareThisTag);

  return (
    <div>
      <article className={classExperienceEditor} style={articleStyle}>
        <div role="main" className="container">
          <div className="article-tools">
            <Placeholder name="phArticleTop" rendering={props.rendering} />
            <ShareButtons />
            {ResizeButtons(setArticleStyle)}
          </div>
          <header className="article-header">
            {fields['Primary Category'] && (
              <a href={fields.CategoryLandingPageLink}>{fields['Primary Category']?.displayName}</a>
            )}
            <Text tag="h1" field={fields['Page Title']} />
            <Text tag="p" className="lead" field={fields.Teaser} />
          </header>
          <div className="article-body">
            <RichText field={fields.Body} />
          </div>
          <div className="row article-author ">
            <div className="col-sm-12 col-md-2 author-img-panel">
              <div className="author-image-cropper">
                <a href={fields.Author?.url}>
                  <Asset
                    editor={isEditing}
                    field={fields.Author?.fields?.Photo}
                    className="author-img"
                  />
                </a>
              </div>
            </div>
            <div className="col-sm-12 col-md-10 bio-panel">
              <RichText field={fields.Author?.fields?.Byline} />
            </div>
          </div>

          <footer className="article-footer">
            <div className="footer-main" style={{ backgroundColor: 'white' }}>
              <div className="wrapper">
                {fields.PreviousArticle && (
                  <a href={fields.PreviousArticle} className="article-prev">
                    <svg width="6" height="11" xmlns="http://www.w3.org/2000/svg">
                      <path
                        transform="rotate(180, 3, 5.5)"
                        d="M0 .906L4.222 5.5 0 10.094.889 11 6 5.5.889 0z"
                        fill="#767679"
                        fillRule="evenodd"
                      />
                    </svg>
                    Previous
                  </a>
                )}
                {fields.NextArticle && (
                  <a href={fields.NextArticle} className="article-next">
                    Next
                    <svg width="6" height="11" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M0 .906L4.222 5.5 0 10.094.889 11 6 5.5.889 0z"
                        fill="#767679"
                        fillRule="evenodd"
                      />
                    </svg>
                  </a>
                )}
              </div>
            </div>

            <div className="d-flex flex-column justify-content-end flex-grow-1">
              <Placeholder name="phCTA" rendering={props.rendering} />
            </div>

            <ShareButtons />
          </footer>
        </div>
      </article>
      <Placeholder name="phArticleBottom" rendering={props.rendering} />
      <Placeholder name="phBlogRollup" rendering={props.rendering} />
    </div>
  );
};

export default withSitecoreContext()(Article);

function ResizeButtons(setArticleStyle) {
  return (
    <div className="text-resize" aria-hidden="true" tabIndex={-1}>
      <button
        tabIndex={-1}
        className="text-resize-normal"
        onClick={(e) => resizeText(e, 1, setArticleStyle)}
      >
        A
      </button>
      <button
        tabIndex={-1}
        className="text-resize-big"
        onClick={(e) => resizeText(e, 1.25, setArticleStyle)}
      >
        A
      </button>
      <button
        tabIndex={-1}
        className="text-resize-bigger"
        onClick={(e) => resizeText(e, 1.5, setArticleStyle)}
      >
        A
      </button>
    </div>
  );
}
