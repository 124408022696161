import React from 'react';
import useWindowSize from '../../../../Foundation/Hooks/useWindowSize';

const ArticlesFilter = (data) => {
  const { option, handler, selected } = data;
  const { width } = useWindowSize();

  if (width < 992) {
    return (
      <select
        id="blog-filter-categories"
        className="custom-select"
        onChange={(e) => handler(e.target.value)}
        selected={selected}
      >
        <option value="">All Categories</option>
        {option.map((opt, i) => (
          <option
            value={opt.m_Item1 || opt.Item1}
            key={i}
            selected={selected === opt.m_Item1 || selected === opt.Item1}
          >
            {opt.m_Item2 || opt.Item2}
          </option>
        ))}
      </select>
    );
  }
  return option.map((opt, i) => (
    <button
      className={`btn btn-outline-secondary ${
        selected === opt.m_Item1 || selected === opt.Item1 ? 'active' : ''
      }`}
      data-category={opt.m_Item1 || opt.Item1}
      key={i}
      onClick={() =>
        handler(opt.m_Item1 === selected || opt.Item1 === selected ? '' : opt.m_Item1 || opt.Item1)
      }
    >
      {opt.m_Item2 || opt.Item2}
    </button>
  ));
};
export default ArticlesFilter;
