import React from 'react';
const TwoColumnDivider = ({ fields, fieldFactory }) => {
  const reshapeFields = (fields) => {
    fields.fields.map((f1) => {
      f1.fields.map((f2) => {
        f2.fields.map((f3) => {
          if (!f3.model.cssClass.includes('col-12 ')) {
            f3.model.cssClass += 'col-12 ';
          }
        });
      });
    });
    return fields;
  };
  return (
    <div className={fields.model.cssClass}>
      <div className="row" id="two-column-divider">
        {fields &&
          fields.fields.map((field, i) => {
            return (
              <div
                key={i}
                className={`${
                  i === 0
                    ? 'form-group col-sm-6 left-divider'
                    : 'form-group col-sm-6 right-divider .d-none .d-sm-block'
                }`}
              >
                {field.fields.map(fieldFactory)}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default TwoColumnDivider;
