import React from 'react';
import SVG from 'react-inlinesvg';

const Logo = ({ url, image, description }) => {
  return (
    <a href={url} className="logo" aria-label={description ?? 'Amica Residence Logo'}>
      <span className="svg-alt">{description ?? 'Amica Residence Logo'}</span>
      <SVG
        src={image}
        title={description ?? 'Amica Residence Logo'}
        uniquifyIDs={true}
        preProcessor={(code) => code.replace(/id=".*?"/g, '')}
      />
    </a>
  );
};

export default Logo;
