import { createDefaultFieldFactory } from '@sitecore-jss/sitecore-jss-react-forms';
import componentsToOveride from './FieldTemplates';
import ExtendedFieldTypes from './ExtendedFieldTypes';
import React from 'react';

const createCustomFieldFactory = function (itemId, allFields) {
  const defaultFieldFactory = createDefaultFieldFactory();
  let globalFormFields = allFields.fields;
  for (let search = 0; search < globalFormFields.length; search++) {
    if (!globalFormFields[search].fields) continue;
    globalFormFields = globalFormFields.concat(globalFormFields[search].fields);
  }
  // rebuild all field to use our custom fields
  // if not specify to use custom field ( on FormFieldFactory ), use sitecore default renderer
  componentsToOveride &&
    componentsToOveride.forEach((OverloadComponent, key, i, e) => {
      const fieldId = ExtendedFieldTypes[key];
      defaultFieldFactory.setComponent(fieldId, (props) => {
        return (
          <OverloadComponent
            isValid={props.isValid}
            fields={props.field}
            itemId={itemId}
            defaultEvent={props.onChange}
            onButtonClick={props.onButtonClick}
            FieldErrorComponent={props.fieldValidationErrorsComponent}
            fieldFactory={props.fieldFactory}
            globalFormFields={globalFormFields}
            {...props}
          />
        );
      });
    });
  return defaultFieldFactory;
};

export default createCustomFieldFactory;
