import React, { useState, useEffect } from 'react';
import {
  Text,
  RichText,
  Placeholder,
  Link,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';
import { withDatasourceCheck } from '../../../Foundation/HOC/withDatasourceCheck';
import { ReactComponent as AddIcon } from '../../../../assets/icons/add.svg';
import { Dropdown } from 'react-bootstrap';
import { graphQLEndpoint } from '../../../../temp/config';
import {
  isMoreThanOneDay,
  GetTimesRendering,
  GetDateRendering,
} from '../../../Foundation/Utils/eventUtils';

const googleUrlTemplateQuery =
  'query={ datasource(value: "{03C8059B-1C05-4A38-A53A-E56904D07ABA}") { script: field(name: "value") { value } } }';

async function getGoogleTemplateURL() {
  const response = await fetch(graphQLEndpoint + '&' + googleUrlTemplateQuery);
  const data = await response.json();
  const googleUrl = data.data.datasource.script.value;
  return googleUrl;
}

const index = ({ fields, rendering, sitecoreContext }) => {
  if (!fields) {
    fields = sitecoreContext.route.fields;
  }
  const isEditing = sitecoreContext && sitecoreContext.pageEditing;
  const [googleTemplateURL, setGoogleTemplateURL] = useState('');
  const StartDate = new Date(fields['Start Date Time']?.value);
  let EndDate = new Date(fields['End Date Time']?.value);
  if (StartDate > EndDate) {
    EndDate = StartDate;
  }
  let MoreThanOneDay = isMoreThanOneDay(EndDate, StartDate);

  function getIsoString(date) {
    let dateString = date.toISOString();
    let transformedString = dateString.replace(/-/g, '');
    transformedString = transformedString.replace(/:/g, '');
    transformedString = transformedString.replace(/\./g, '');
    return transformedString;
  }

  function getCalenderLocationString() {
    let string = '';
    if (fields['Address Line 1']?.value) {
      string += fields['Address Line 1']?.value;
    }
    if (fields['Address Line 2']?.value) {
      string += ', ' + fields['Address Line 2']?.value;
    }
    if (fields.City?.value) {
      string += ', ' + fields.City?.value;
    }
    if (fields.Province?.value) {
      string += ', ' + fields.Province?.value;
    }
    if (fields['Postal Code']?.value) {
      string += ', ' + fields['Postal Code']?.value;
    }
    return string;
  }

  function openGoogleCalendar() {
    const url =
      googleTemplateURL === ''
        ? 'http://www.google.com/calendar/event?action=TEMPLATE'
        : googleTemplateURL;
    const start = getIsoString(StartDate);
    const end = getIsoString(EndDate);
    const title = fields['Page Title']?.value;
    const description = fields.Description?.value;
    const location = getCalenderLocationString();
    var urlContent =
      url +
      '&dates=' +
      start +
      '/' +
      end +
      '&text=' +
      title +
      '&location=' +
      location +
      '&details=' +
      description;
    window.open(urlContent);
  }

  function getICalCalenderUrl() {
    const start = getIsoString(StartDate);
    const end = getIsoString(EndDate);
    const title = fields['Page Title']?.value;
    const description = fields.Description?.value;
    const location = getCalenderLocationString();
    return `BEGIN: VCALENDAR\nVERSION:2.0\nBEGIN:VEVENT\nDTSTAMP:${start}\nDTSTART:${start}\nDTEND:${end}\nSUMMARY:${title}\nDESCRIPTION:${description}\nLOCATION:${location}\nEND:VEVENT\nEND:VCALENDAR`;
  }

  function openICalCalendar() {
    var url = getICalCalenderUrl();
    var urlContent = encodeURIComponent(url);
    window.open('data:text/calendar;charset=utf8,' + urlContent);
  }

  useEffect(() => {
    getGoogleTemplateURL().then((result) => {
      setGoogleTemplateURL(result);
    });
  }, []);

  const eventStyle = isEditing ? { marginTop: 0 } : {};

  return (
    <article className="event" style={eventStyle}>
      <div className="container">
        <div>
          <Placeholder name="phEventsTop" rendering={rendering} />
        </div>
        <div className="event-body" role="main" aria-label="Content">
          <Text tag="h1" field={fields['Page Title']} />
          <RichText className="lead" field={fields.Description} />
          <div>
            <Placeholder name="phCTA" rendering={rendering} />
          </div>
          <Dropdown className="add-calendar">
            <Dropdown.Toggle variant="secondary">
              <span className="hidden">Add Icon</span>
              <AddIcon />
              {fields['Add To Calendar Button']?.value}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => openGoogleCalendar()}>
                {fields['Add To Google Calendar Button']?.value}
              </Dropdown.Item>
              <Dropdown.Item onClick={() => openICalCalendar()}>
                {fields['Add To ICal Button']?.value}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="event-info">
          <div className="row">
            <div className="col-sm-6" role="region" aria-label="Information">
              <Text tag="h2" field={fields['Location Label']} />
              <p>
                {fields['Address Line 1'] && <Text field={fields['Address Line 1']} />}
                <br />
                {fields['Address Line 2'] && <Text field={fields['Address Line 2']} />}
                <br />
                {fields.City?.value && <Text field={fields.City} />},{' '}
                {fields.Province?.value && <Text field={fields.Province} />}
                <br />
                {fields['Postal Code']?.value && <Text field={fields['Postal Code']} />}
              </p>
              {fields['Map Url']?.value && fields['Map Url']?.value.url && (
                <div>
                  <Link field={fields['Map Url']} showLinkTextWithChildrenPresent={false}>
                    <Text field={fields['View Map Label']} />
                  </Link>
                </div>
              )}
            </div>
            <div className="col-sm-6" role="complementary" aria-label="Time and Date Information">
              <Text tag="h2" field={fields['Date Label']} />
              {GetDateRendering(MoreThanOneDay, StartDate, EndDate)}
              <Text tag="h2" field={fields['Time Label']} />
              {GetTimesRendering(StartDate, EndDate)}
            </div>
          </div>
        </div>
      </div>
    </article>
  );
};

export default withSitecoreContext()(index);
