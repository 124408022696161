import React from 'react';
import { isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';
import { ReactComponent as PhoneIcon } from '../../../../assets/icons/phone.svg';
import { ReactComponent as PhoneOutlinedIcon } from '../../../../assets/icons/phone-outlined.svg';

const ResidencePhoneCTA = ({ fields }) => {
  const text = fields?.text;
  const phone = 'tel:' + fields?.phone;

  if (!text) {
    if (isExperienceEditorActive()) {
      return <p>Error displaying ResidencePhoneCTA</p>;
    }
    return null;
  }

  return (
    <a className="call2action btn residencecta contact" href={phone ? phone : '#'}>
      <PhoneOutlinedIcon className="residence-phone-icon-outlined d-block d-sm-none" />
      <PhoneIcon className="d-none d-sm-block" />
      <span className="residence-phone-number">{text}</span>
    </a>
  );
};

export default ResidencePhoneCTA;
