import React from 'react';
import clsx from 'clsx';
import Collapse from 'react-bootstrap/Collapse';
import SubNavLink from '../SubNavLink';

const NavLink = ({ link }) => {
  const [open, setOpen] = React.useState(false);
  let className = '';
  if (link.isActive) {
    className += 'current';
  }
  if (link.includeBorder) {
    className += ' menu-border';
  }
  return (
    <li role="menuitem" className={clsx(link.childNavigation && 'has-submenu')}>
      {!!link.childNavigation.length ? (
        <>
          <a aria-haspopup="true" aria-expanded={open} className={className} href={link.linkUrl}>
            {link.linkText}
          </a>
          <button
            className={clsx('submenu-toggler plus', !open && 'collapsed')}
            onClick={() => setOpen((prev) => !prev)}
            aria-controls="dropdown-menu"
            aria-label="Toggle Sub Menu"
            aria-expanded={open}
          >
            <span className="plus-box">
              <span className="plus-inner" />
            </span>
          </button>
          <Collapse in={open}>
            <ul id="dropdown-menu">
              {link.childNavigation.map((subNavLink) => (
                <SubNavLink
                  key={subNavLink.id}
                  linkUrl={subNavLink.linkUrl}
                  linkText={subNavLink.linkText}
                />
              ))}
            </ul>
          </Collapse>
        </>
      ) : (
        <a
          href={link.linkUrl}
          className={clsx(link.isActive && 'current', link.includeBorder && 'menu-border')}
        >
          {link.linkText}
        </a>
      )}
    </li>
  );
};

export default NavLink;
