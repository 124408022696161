import * as layouts from './layouts';

class LayoutFactory {
  constructor() {
    this.layoutMap = new Map();
    // add layouts in this format:
    // this.layoutMap.set('{3596B70A-8AFC-4E8D-9044-8857401C4374}', layouts.TestLayout);
    this.layoutMap.set('{8575F8E1-136D-47D1-A344-9B981C3181E1}', layouts.MainLayout);
    this.layoutMap.set('default', layouts.DefaultLayout);
  }

  resolveLayout(routeData) {
    const layoutId = `{${routeData.layoutId.toUpperCase()}}`;
    const layout = this.layoutMap.get(layoutId);

    return layout || this.layoutMap.get('default');
  }
}

const layoutFactory = new LayoutFactory();

Object.freeze(layoutFactory);
export default layoutFactory;
