import { fetchData } from '../Utils/fetchData';
import { objToQueryString } from '../Utils';

const getProvinces = () => {
  const url = 'amica/api/bookavisit/GetProvinces';
  return fetchData(url);
};

// Gets all cities
const getCities = (showUnderConstruction) => {
  const url = `amica/api/bookavisit/GetCitiesByProvinceID?sortByDistance=true&showUnderConstruction=${showUnderConstruction}`;
  return fetchData(url);
};

const getResidenceById = (contextItemId) => {
  const url = `amica/api/residenceprepopulate/ResidenceSelection/GetResidencePrePopulated?contextitemId={${contextItemId}}`;
  return fetchData(url);
};
const getResidencesbyCityId = (cityId, showUnderConstruction) => {
  const url = `amica/api/bookavisit/GetResidencesByCity?cityId=${cityId}&showUnderConstruction=${showUnderConstruction}`;
  return fetchData(url);
};

const getResidences = async (searchQuery) => {
  const url = `amica/api/SearchService/GetResidenceResultJson?${objToQueryString(searchQuery)}`;
  const result = await fetchData(url);
  return JSON.parse(result);
};

export default { getProvinces, getCities, getResidenceById, getResidencesbyCityId, getResidences };
