import { getFieldValue } from '@sitecore-jss/sitecore-jss-react';
import Asset from '../../../Foundation/DFS/Asset';
import React from 'react';

const SocialMedia = ({ fields, type, isEditing }) => {
  const FacebookLogoUrl = getFieldValue(fields, 'Facebook logo')?.src;
  const FacebookUrl = getFieldValue(fields, 'Facebook');
  const TwitterLogoUrl = getFieldValue(fields, 'Twitter logo')?.src;
  const TwitterUrl = getFieldValue(fields, 'Twitter');
  const LinkedinLogoUrl = getFieldValue(fields, 'Linkedin logo')?.src;
  const LinkedinUrl = getFieldValue(fields, 'Linkedin');

  const urls = [FacebookUrl, TwitterUrl, LinkedinUrl];
  const reg = /(\w*?)="(.*?)"/g;
  let elements = [];
  for (const extractString of urls) {
    let result;
    let attributes = {};
    while ((result = reg.exec(extractString))) {
      attributes[[result[1]]] = result[2];
    }
    elements.push(attributes);
  }

  return (
    <div className={'social-' + type + ' p-3'}>
      <div className="row">
        <div role="contentinfo" aria-label="Socials" className="col-12 text-center">
          {FacebookLogoUrl && FacebookUrl && (
            <a href={elements[0].url} target={elements[0].target}>
              <Asset editor={isEditing} field={fields['Facebook logo']} height="25" width="25" />
            </a>
          )}
          {TwitterLogoUrl && TwitterUrl && (
            <a href={elements[1].url} target={elements[1].target}>
              <Asset editor={isEditing} field={fields['Twitter logo']} height="25" width="25" />
            </a>
          )}
          {LinkedinLogoUrl && LinkedinUrl && (
            <a href={elements[2].url} target={elements[2].target}>
              <Asset editor={isEditing} field={fields['Linkedin logo']} height="25" width="25" />
            </a>
          )}
        </div>
      </div>
    </div>
  );
};
export default SocialMedia;
