import { graphQLEndpoint } from '../../../temp/config';

const LandingPagesQuery =
  'query={ datasource(value: "{383EEE52-8912-41FE-9F11-369C511FDB31}") { children(includeTemplateIDs: "D59A3AF3793F4A859FB0229F865FC7A2") { name } } }';

export default async function getLandingPages() {
  const response = await fetch(graphQLEndpoint + '&' + LandingPagesQuery);
  const data = await response.json();
  const cities = data.data.datasource.children.filter((city) => city.name !== '*');
  const names = cities.map(function (item) {
    return item.name.trim().replaceAll(' ', '-').toLowerCase();
  });
  return names;
}
