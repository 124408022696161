import React from 'react';
import VideoAsset from '../../../Foundation/DFS/VideoAsset';
import Asset from '../../../Foundation/DFS/Asset';
import { getFieldValue, Text } from '@sitecore-jss/sitecore-jss-react';

export default function Media({ fields, isEditing }) {
  if (isEditing) {
    return (
      <>
        <Text field={fields['Video URL']} tag="p" className="video-url" />
        <VideoAsset field={fields.Video} />
        <Asset editor={isEditing} field={fields.Image} />
      </>
    );
  }
  if (fields['Video URL']?.value) {
    return (
      <div className="diff-iframe-video-container" aria-hidden="true">
        <iframe
          className="diff-iframe-video"
          src={getFieldValue(fields, 'Video URL')
            .replace('watch?v=', 'embed/')
            .replace('.be/', 'be.com/embed/')}
          allowFullScreen
        />
      </div>
    );
  }

  if (fields?.Video?.value?.src) {
    return (
      <video controls id="video-aux" aria-hidden="true">
        <source src={fields?.Video?.value?.src} type="video/mp4" />
      </video>
    );
  }
  if (fields?.Image?.value) {
    return <Asset editor={isEditing} field={fields.Image} />;
  }
  return <></>;
}
