import React from 'react';
import CityList from '../CityList';

const ProvinceList = ({ provinces }) => {
  if (!provinces?.length) {
    return null;
  }
  return provinces.map((province, index) => (
    <React.Fragment key={index}>
      <h2>
        <a href={province.Url}>{province.Name}</a>
      </h2>

      <CityList cities={province?.Cities} />
    </React.Fragment>
  ));
};

export default ProvinceList;
