import React, { useEffect, useState } from 'react';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/pencil-banner-close.svg';
import useWindowSize from '../../../Foundation/Hooks/useWindowSize';
import {
  RichText,
  useSitecoreContext,
  isExperienceEditorActive,
} from '@sitecore-jss/sitecore-jss-react';

const defaultBackgroundColor = '#587a8d';

const PencilBanner = ({ fields }) => {
  const showCloseBtn = fields?.ShowCloseButton?.value;
  const backgroundColor = fields?.BackgroundColor?.fields?.Value?.value || defaultBackgroundColor;
  const showBanner = fields?.ShowBanner?.value;
  const { width } = useWindowSize();

  const { sitecoreContext } = useSitecoreContext();

  const [isBannerVisible, setIsBannerVisible] = useState(showBanner);
  const [ribbonHeight, setRibbonHeight] = useState('');

  const updatePencilPosition = () => {
    const scElement = document.querySelector('#scCrossPiece');

    setTimeout(() => {
      const navigationElement = document.querySelector('.main-nav');
      const scElementHeight = scElement ? scElement?.offsetHeight : 0;
      const height = scElementHeight + navigationElement?.offsetHeight;

      setRibbonHeight(height);
    }, 200);
  };

  useEffect(() => {
    setTimeout(updatePencilPosition, 500);
  }, [width]);

  useEffect(() => {
    setTimeout(updatePencilPosition, 1000);

    const handleScroll = () => {
      clearTimeout(scrollTimer);
      scrollTimer = setTimeout(updatePencilPosition, 300);
    };

    let scrollTimer;
    const storedVisibility = sessionStorage.getItem('bannerVisibility');

    if (storedVisibility !== null) {
      setIsBannerVisible(storedVisibility === 'false' ? false : true);
    }

    if (
      (isExperienceEditorActive() || sitecoreContext?.pageState === 'preview') &&
      typeof document !== 'undefined'
    ) {
      let frameDocument;

      const intervalId = setInterval(() => {
        if (document.querySelector('#scCrossPiece')) {
          frameDocument = document.querySelector('#scWebEditRibbon')?.contentWindow.document;

          updatePencilPosition();
          frameDocument?.addEventListener('click', updatePencilPosition);
          clearInterval(intervalId);
        }
      }, 0);
    }
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      frameDocument?.removeEventListener('click', updatePencilPosition);
    };
  }, []);

  const handleToggleShowPencilBanner = () => {
    setIsBannerVisible(false);
    sessionStorage.setItem('bannerVisibility', false);
  };

  return fields && isBannerVisible ? (
    <div
      id="pencil-banner"
      className="pencil-banner"
      style={{
        backgroundColor,
        top: width > 991 && ribbonHeight,
      }}
    >
      {showCloseBtn && <CloseIcon className="close-btn" onClick={handleToggleShowPencilBanner} />}
      <div className="text-container">
        <RichText className="description" field={fields?.Description} />
      </div>
    </div>
  ) : null;
};

export default PencilBanner;
