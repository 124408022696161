import React from 'react';
import { Image } from '@sitecore-jss/sitecore-jss-react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Asset = ({ editor, field = {}, ...rest }) => {
  const imageFields = field.value || field;
  const image = {
    value: {
      src:
        imageFields.src ||
        imageFields.files?.find((file) => file.name === 'original')?.url ||
        imageFields.files?.[0]?.url,
      alt: imageFields.alt || imageFields?.asset?.description,
      title: imageFields?.asset?.name || null,
    },
    editable: field.editable,
  };
  if (editor === true || !image.value.src) {
    return <Image field={image} {...rest} />;
  } else {
    return (
      <LazyLoadImage
        alt={image.value.alt}
        src={image.value.src}
        title={image.value.title}
        {...rest}
      />
    );
  }
};

export default Asset;
