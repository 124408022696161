import React from 'react';
import { Text, RichText, Placeholder, getFieldValue } from '@sitecore-jss/sitecore-jss-react';
import { withDatasourceCheck } from '../../../Foundation/HOC/withDatasourceCheck';

const index = ({ fields, rendering }) => {
  const isUnderlined = getFieldValue(fields, 'Underline Title');
  let ctaClass = 'cta-panel';

  if (isUnderlined) {
    ctaClass = ctaClass + ' visit-us';
  }

  return (
    <div role="complementary" aria-label="Panel" className={ctaClass}>
      <div className="container">
        {fields?.Title && <Text tag="h2" field={fields.Title} />}
        <WrappedRichText />
        <div className="actions">
          <Placeholder name="phCTA" rendering={rendering} />
        </div>
      </div>
    </div>
  );

  function WrappedRichText() {
    if (fields?.Teaser?.value.startsWith('<p>')) {
      return <RichText field={fields.Teaser} />;
    }
    return <p>{fields?.Teaser && <RichText field={fields.Teaser} />}</p>;
  }
};

export default withDatasourceCheck()(index);
