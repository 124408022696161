import React from 'react';
import { isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';
import { ReactComponent as CalendarIcon } from '../../../../assets/icons/calendar.svg';

const ResidenceBookaVisitCTA = ({ fields }) => {
  let label = fields?.label;
  const url = fields?.url ?? '#';
  const additionalStyles = fields?.additionalStyles ?? '';

  if (!label) {
    if (isExperienceEditorActive()) {
      return <p>No label found</p>;
    }
    return null;
  }

  label = ' ' + fields?.label + ' ';

  return (
    <a className={`call2action btn residencecta book ${additionalStyles}`} href={url}>
      <CalendarIcon />
      {label && label}
    </a>
  );
};

export default ResidenceBookaVisitCTA;
