import React, { useEffect } from 'react';
import useWindowSize from '../../../Foundation/Hooks/useWindowSize';

import {
  Placeholder,
  RichText,
  useSitecoreContext,
  isExperienceEditorActive,
} from '@sitecore-jss/sitecore-jss-react';
import clsx from 'clsx';
const QuoteProof = ({ fields, rendering }) => {
  const { width } = useWindowSize();
  const { sitecoreContext } = useSitecoreContext();
  if (!fields) return <></>;

  const {
    BackgroundColor,
    BackgroundImage,
    BackgroundImageMobile,
    Headline,
    ImageLocation,
    Paragraph,
    RemoveBackgroundColor,
  } = fields;

  const isHerobannerCtas = rendering.placeholders['herobanner-cta'].length || false;
  const backgroundColor = BackgroundColor?.fields?.Value?.value;
  const removeBackgroundColor = RemoveBackgroundColor?.value;
  const backgroundImage = BackgroundImage?.value?.src;
  const backgroundImageMobile = BackgroundImageMobile?.value?.src;
  const imageLocation = ImageLocation?.name || 'Left';

  useEffect(() => {
    const updateHeroImagePosition = () => {
      setTimeout(() => {
        const scElement = document.querySelector('#scCrossPiece');

        const height = scElement?.style.height;
        const quoteProofContainer = document.querySelector('.quote-proof-container');

        if (quoteProofContainer) {
          quoteProofContainer.style.top = height;
        }
      }, 0);
    };

    if (
      (isExperienceEditorActive() || sitecoreContext?.pageState === 'preview') &&
      typeof document !== 'undefined'
    ) {
      let frameDocument;

      const intervalId = setInterval(() => {
        if (document.querySelector('#scCrossPiece')) {
          frameDocument = document.querySelector('#scWebEditRibbon')?.contentWindow.document;

          updateHeroImagePosition();
          frameDocument?.addEventListener('click', updateHeroImagePosition);
          clearInterval(intervalId);
        }
      }, 500);

      return () => frameDocument?.removeEventListener('click', updateHeroImagePosition);
    }
  }, []);
  return (
    <div className="quote-proof-container">
      <div className="content">
        <div
          className="bg-container"
          style={{
            backgroundImage: `url(${width > 991 ? backgroundImage : backgroundImageMobile})`,
          }}
        >
          <div
            className={clsx('content-container', imageLocation === 'Right' && 'content-on-right')}
          >
            <div
              className="text-content-wrapper"
              style={{ backgroundColor: removeBackgroundColor ? 'transparent' : backgroundColor }}
            >
              <div className={clsx('text-content')}>
                {!!Headline && <RichText field={Headline} className="richText-wrapper" tag="h1" />}
                {!!Paragraph && (
                  <RichText field={Paragraph} className="richText-wrapper-paragraph" />
                )}

                {isHerobannerCtas && (
                  <div className="ctas-container">
                    <Placeholder name="herobanner-cta" rendering={rendering} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuoteProof;
