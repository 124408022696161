import React, { useEffect, useState } from 'react';
import { Text, isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';
import Article from './ui/Article';
import LoadingButton from './ui/LoadingButton';
import { ReactComponent as LoadingIcon } from '../../../../assets/icons/loading-indicator.svg';
import { articleSearchService } from '../../../Foundation/services';

const numberOfResultPerPage = 3;

const ArticleSearchResults = ({ fields }) => {
  const searchTerms = fields.data.searchTerms;
  const loadMoreText = fields.item['Load More Text'];
  const queryLabelText = fields.item['Query Label Text'];

  const [showIcon, setShowIcon] = useState(false);
  const [showButton, setShowButton] = useState(true);
  const [items, setItems] = useState([]);
  const [page, setPage] = React.useState(0);

  if (!searchTerms) {
    if (isExperienceEditorActive()) {
      return <p>Article Search Results: No Search Term Given</p>;
    }
    return null;
  }

  useEffect(() => {
    if (searchTerms) runQuery();
  }, []);

  useEffect(() => {
    if (showIcon) setShowButton(false);
  }, [showIcon]);

  const onLoadClick = () => {
    runQuery();
  };

  const runQuery = async () => {
    try {
      setShowIcon(true);
      const { TotalNumberOfResults, Results, Success } = await articleSearchService.getArticles(
        searchTerms,
        page,
        numberOfResultPerPage
      );

      if (!Success) {
        throw 'Something went wrong while searching. Please check your query string';
      }

      if (TotalNumberOfResults <= items.length + Results.length || !Results.length) {
        setShowButton(false);
      } else {
        setShowButton(true);
      }

      setItems([...items, ...Results]);
      setPage((prev) => prev + 1);
    } catch (e) {
      // Handle error here
    } finally {
      setShowIcon(false);
    }
  };

  if (!fields || !items || items.length === 0) {
    if (isExperienceEditorActive()) {
      console.log('Article Search Results: No results for search query');
      return <p>Something went wrong with the Article Search Results</p>;
    }
    return null;
  }
  return (
    <div role="search" className="article-search-panel">
      <div className="container">
        <p>
          <Text
            tag="label"
            aria-label="Label"
            style={{ fontWeight: 'bold', marginBottom: '0 !important', marginRight: 5 }}
            field={queryLabelText}
          />
          <Text
            tag="label"
            aria-label="Terms"
            style={{ fontStyle: 'italic', marginBottom: '0 !important' }}
            field={{ value: searchTerms }}
          />
        </p>
        <div className="article-results-container">
          <ul id="article-list" className="articles-search-list">
            {items.map((article, key) => (
              <Article
                key={key}
                url={article.Url}
                imageUrl={article.ImageUrl}
                imageAlt={article.ImageAlt}
                title={article.Title}
                teaser={article.Teaser}
                primaryCategoryUrl={article.PrimaryCategoryUrl}
                primaryCategoryTitle={article.PrimaryCategoryTitle}
              />
            ))}
          </ul>
          <div className="row article-lm-container">
            {!showIcon && showButton && <LoadingButton text={loadMoreText} onClick={onLoadClick} />}
            {showIcon && (
              <div className="loading-indicator">
                <LoadingIcon />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticleSearchResults;
