import React from 'react';
import GoogleMapReact from 'google-map-react';

const mapSettings = {
  options: {
    streetViewControl: true,
    mapTypeControl: true,
    mapTypeControlOptions: { mapTypeIds: ['roadmap', 'satellite'] },
    labels: true,
  },
};

const GoogleMap = (props) => {
  const defaultProps = {
    center: {
      lat: Number(props.defaultCenter.lat),
      lng: Number(props.defaultCenter.lng),
    },
    zoom: 14,
  };
  return (
    <div style={{ height: '100%', width: '100%' }} aria-hidden="true">
      <GoogleMapReact
        bootstrapURLKeys={{ key: props.apiKey }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        options={mapSettings.options}
        onGoogleApiLoaded={props.onGoogleApiLoaded}
      />
    </div>
  );
};

export default GoogleMap;
