import React from 'react';
import { Link, Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import Asset from '../../../Foundation/DFS/Asset';
import { withDatasourceCheck } from '../../../Foundation/HOC/withDatasourceCheck';
import { isMoreThanOneDay, GetDateRendering } from '../../../Foundation/Utils/eventUtils';

const ArticlesRollup = ({ fields }) => {
  const { datasource, events } = fields;
  const { sitecoreContext } = useSitecoreContext();
  const isEditing = sitecoreContext && sitecoreContext.pageEditing;
  if (!events?.length) {
    return null;
  }

  return (
    <section className="articles articles-rollup">
      <div className="container">
        <div className="articles-rollup-header" role="navigation" aria-label="Header">
          <Text field={datasource['Events Label']} tag="h2" />
          {LinkToAllEvents(datasource, isEditing)}
        </div>
        <ul className="articles-list">
          {events.map((event, index) => EventCard(event, index, isEditing))}
        </ul>
      </div>
    </section>
  );
};

function LinkToAllEvents(datasource, isEditing) {
  if (isEditing) {
    return (
      <>
        <Link className="link-goto" field={datasource['See All Events Link']} />
        &nbsp;
        <Text className="link-goto" field={datasource['See All Events Label']} tag="span" />
      </>
    );
  }
  if (datasource['See All Events Label']?.value && datasource['See All Events Link']?.value?.href) {
    return (
      <Link
        className="link-goto"
        aria-label="See All Events Link"
        field={datasource['See All Events Link']}
        showLinkTextWithChildrenPresent={false}
      >
        <Text field={datasource['See All Events Label']} tag="span" />
        <ChevronRightIcon className="chevron-right" />
      </Link>
    );
  }
  return <></>;
}

function EventCard(event, index, isEditing) {
  const StartDate = new Date(event.fields['Start Date Time']?.value);
  let EndDate = new Date(event.fields['End Date Time']?.value);
  if (StartDate > EndDate) {
    EndDate = StartDate;
  }
  const MoreThanOneDay = isMoreThanOneDay(EndDate, StartDate);
  return (
    <li key={index}>
      <article className="card">
        <a href={event.url}>
          <div className="card-image">
            <Asset editor={isEditing} field={event.fields.Image} />
          </div>
          <div role="region" aria-label="Content" className="card-body">
            <h3>{event.name}</h3>
            {GetDateRendering(MoreThanOneDay, StartDate, EndDate)}
          </div>
        </a>
        <div className="card-footer">
          <a href={event.url}>View Event</a>
        </div>
      </article>
    </li>
  );
}

const ChevronRightIcon = (props) => (
  <svg width="6" height="11" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M0 .906L4.222 5.5 0 10.094.889 11 6 5.5.889 0z" fill="#767679" fillRule="evenodd" />
  </svg>
);
export default withDatasourceCheck()(ArticlesRollup);
