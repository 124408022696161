import React, { Fragment } from 'react';
import { ReactComponent as ChevronRight } from '../../../../assets/icons/chevron-right.svg';
import { Link } from 'react-router-dom';
const Breadcrumbs = ({ fields }) => (
  <div className="breadcrumb">
    <div className="container" role="navigation" aria-label="Breadcrumbs">
      {Object.keys(fields).map(function (index) {
        const item = fields[index];
        if (!item.includeInBreadcrumb) {
          return;
        } else if (!item.isEnd && !item.isEllipse) {
          return (
            <Fragment key={index}>
              <a aria-label={item.linkText} href={item.linkUrl}>
                {item.linkText}
              </a>
              <span className="separator">
                <ChevronRight />
              </span>
            </Fragment>
          );
        } else {
          if (!item.isCurrentPage) {
            return (
              <Fragment key={index}>
                <a aria-label={item.linkText} href={item.linkUrl}>
                  {item.linkText}
                </a>
                {!item.isEnd && (
                  <span className="separator">
                    <ChevronRight />
                  </span>
                )}
              </Fragment>
            );
          } else {
            return (
              <Fragment key={index}>
                <span>{item.linkText}</span>
                {!item.isEnd && (
                  <span className="separator">
                    <ChevronRight />
                  </span>
                )}
              </Fragment>
            );
          }
        }
      })}
    </div>
  </div>
);

export default Breadcrumbs;
