import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';

const LoadingButton = ({ text, onClick }) => (
  <div className="col-12 text-center">
    <button id="article-load-more" onClick={onClick} className="btn btn-outline-primary">
      <Text tag="span" field={text} />
    </button>
  </div>
);

export default LoadingButton;
