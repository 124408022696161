import React from 'react';
import { Text, RichText, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { ReactComponent as LocationSvg } from '../../../../assets/icons/location.svg';
import { withDatasourceCheck } from '../../../Foundation/HOC/withDatasourceCheck';

const IntroOneColumn = ({ fields, rendering }) => (
  <div className="intro">
    <div className="container">
      <div className="row justify-content-center">
        <div
          role="main"
          aria-label="Content"
          className="col-sm-12 col-md-9 residence-corporate-width"
        >
          {fields.Title.value !== '' && (
            <Text tag="h1" className="one-column" field={fields.Title} />
          )}
          {fields['Label 1'].value !== '' && fields['Address 1'].value !== '' && (
            <p className="address address-direction">
              <Text tag="span" field={fields['Label 1']} />{' '}
              <a
                href={'https://maps.google.ca/maps?q=' + fields['Address 1'].value}
                rel="noreferrer"
                target="_blank"
              >
                <Text field={fields['Address 1']} />
              </a>
            </p>
          )}
          {fields['Label 2'].value !== '' && fields['Address 2'].value !== '' && (
            <p className="address">
              <Text field={fields['Label 2']} />{' '}
              <a
                href={'https://maps.google.ca/maps?q=' + fields['Address 2'].value}
                rel="noreferrer"
                target="_blank"
              >
                <Text field={fields['Address 2']} />
              </a>
            </p>
          )}
          <div className="content-one-column">
            <RichText field={fields.Copy} />
            <div className="cta">
              <Placeholder name="phCTA" rendering={rendering} />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default withDatasourceCheck()(IntroOneColumn);
