import { Text, RichText, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { withDatasourceCheck } from '../../../Foundation/HOC/withDatasourceCheck';
import React, { useContext } from 'react';
import clsx from 'clsx';
import { FAQContext } from '../FAQ Container';

const FAQAccordion = ({ fields, rendering }) => {
  const FAQContextData = useContext(FAQContext);

  if (!FAQContextData) {
    return <div>FAQ Context is not available.</div>;
  }

  const { expandedAccordionId, setExpandedAccordionId } = FAQContextData;
  const { sitecoreContext } = useSitecoreContext();
  const isEditing = sitecoreContext?.pageEditing;

  const getUniqueId = (accordionId) => `${accordionId}-${rendering?.uid || ''}`;

  const handleClick = (e, accordionId) => {
    const id = getUniqueId(accordionId);
    if (isEditing) return;

    if (id === expandedAccordionId) {
      setExpandedAccordionId('');
    } else {
      setExpandedAccordionId(id);

      setTimeout(() => {
        e.target.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 200);
    }
  };

  if (!fields) return <></>;

  return (
    <div className="text-left">
      {fields.SubHeading && <Text tag="h3" className="mb-4" field={fields.SubHeading} />}
      <ul className="accordions">
        {fields.FAQItems?.map((accordion) => (
          <li
            key={accordion.id}
            className={clsx(
              'accordion-item',
              (getUniqueId(accordion.id) === expandedAccordionId || isEditing) && 'expanded'
            )}
          >
            <Text
              tag="span"
              className="accordion-question"
              onClick={(e) => handleClick(e, accordion.id)}
              field={accordion.fields?.Question}
            />

            <RichText className="description accordion-answer" field={accordion.fields?.Answer} />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default withDatasourceCheck()(FAQAccordion);
