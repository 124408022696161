import React from 'react';
import Link from '../Link';

const Policies = ({ policies }) => {
  if (!Array.isArray(policies) || !(policies?.length > 0)) {
    return null;
  }

  return (
    <ul className="links">
      {policies?.map((policy, key) => (
        <React.Fragment key={key}>
          <li>
            <Link item={policy} key={key} />
          </li>{' '}
        </React.Fragment>
      ))}
    </ul>
  );
};

export default Policies;
