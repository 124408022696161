import React from 'react';
import Asset from '../../../../../Foundation/DFS/Asset';

const NavLink = ({ item }) => {
  const { Icon, href, text, target } = checkItem(item);
  const id = text.toLowerCase();
  return Icon?.value?.src && Icon?.value?.class !== 'scEmptyImage' ? (
    <a id={id} href={href} target={target} aria-label={id}>
      <Asset
        editor={false}
        field={Icon}
        width={Icon.value?.width ?? 32}
        height={Icon.value?.height ?? 32}
      />
    </a>
  ) : (
    <a href={href} target={target}>
      {text}
    </a>
  );
};

const checkItem = (item) => {
  try {
    const {
      Icon,
      Link: {
        value: { href, text, target },
      },
      Style,
    } = item;
    return { Icon, href, text, Style, target };
  } catch (err) {
    return { Icon: {}, href: '', text: '', Style: {}, target: '' };
  }
};

export default NavLink;
