export const generateLocationInnerHTML = ({ name, address, phone, website, bookavisit, image }) => {
  const LinkIcon =
    '<svg aria-hidden="true" data-prefix="fas" data-icon="link" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-link fa-w-16 fa-2x"><path fill="currentColor" d="M326.612 185.391c59.747 59.809 58.927 155.698.36 214.59-.11.12-.24.25-.36.37l-67.2 67.2c-59.27 59.27-155.699 59.262-214.96 0-59.27-59.26-59.27-155.7 0-214.96l37.106-37.106c9.84-9.84 26.786-3.3 27.294 10.606.648 17.722 3.826 35.527 9.69 52.721 1.986 5.822.567 12.262-3.783 16.612l-13.087 13.087c-28.026 28.026-28.905 73.66-1.155 101.96 28.024 28.579 74.086 28.749 102.325.51l67.2-67.19c28.191-28.191 28.073-73.757 0-101.83-3.701-3.694-7.429-6.564-10.341-8.569a16.037 16.037 0 0 1-6.947-12.606c-.396-10.567 3.348-21.456 11.698-29.806l21.054-21.055c5.521-5.521 14.182-6.199 20.584-1.731a152.482 152.482 0 0 1 20.522 17.197zM467.547 44.449c-59.261-59.262-155.69-59.27-214.96 0l-67.2 67.2c-.12.12-.25.25-.36.37-58.566 58.892-59.387 154.781.36 214.59a152.454 152.454 0 0 0 20.521 17.196c6.402 4.468 15.064 3.789 20.584-1.731l21.054-21.055c8.35-8.35 12.094-19.239 11.698-29.806a16.037 16.037 0 0 0-6.947-12.606c-2.912-2.005-6.64-4.875-10.341-8.569-28.073-28.073-28.191-73.639 0-101.83l67.2-67.19c28.239-28.239 74.3-28.069 102.325.51 27.75 28.3 26.872 73.934-1.155 101.96l-13.087 13.087c-4.35 4.35-5.769 10.79-3.783 16.612 5.864 17.194 9.042 34.999 9.69 52.721.509 13.906 17.454 20.446 27.294 10.606l37.106-37.106c59.271-59.259 59.271-155.699.001-214.959z" class=""></path></svg>';
  const CalenderIcon =
    '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18px" height="18px" viewBox="0 0 18 18" version="1.1"> <g id="Symbols" stroke="none" stroke-width="1"><g id="Footer_New" transform="translate(-474.000000, -137.000000)"><g id="Group-6" transform="translate(428.000000, 121.000000)"><g id="Group-4" transform="translate(46.000000, 10.000000)"><g id="Group-2"><path d="M14.8247283,8.17038644 C14.8247283,7.72750435 14.6485547,7.30257892 14.3353468,6.98938147 C14.0221389,6.67618401 13.597203,6.5 13.1543418,6.5 L12.597268,6.5 C11.6739827,6.5 10.9268815,7.24791553 10.9268815,8.17038644 L6.47029051,8.17038644 C6.47029051,7.72750435 6.29493334,7.30257892 5.98174424,6.98938147 C5.66855513,6.67618401 5.24360047,6.5 4.79990407,6.5 L4.24529402,6.5 C3.32200879,6.5 2.57490758,7.24791553 2.57490758,8.17038644 L2.50558028,8.17038644 C1.1214772,8.17038644 0,9.29267732 0,10.6759661 L0,20.8761809 C0,22.2602839 1.1214772,23.3817605 2.50558028,23.3817605 L14.8940013,23.3817605 C16.27729,23.3817605 17.3995809,22.2602839 17.3995809,20.8761809 L17.3995809,10.6759661 C17.3995809,9.29267732 16.27729,8.17038644 14.8940013,8.17038644 L14.8247283,8.17038644 Z M12.3734131,7.80673805 L13.3755493,7.80673805 L13.3755493,9.84077287 L12.3734131,9.84077287 L12.3734131,7.80673805 Z M4.03466797,7.84317604 L5.02807617,7.84317604 L5.02807617,9.84077287 L4.03466797,9.84077287 L4.03466797,7.84317604 Z M15.7292488,20.8776424 L15.7292488,20.8784588 C15.7292488,21.0994927 15.641161,21.3123626 15.4845581,21.4689613 C15.3279551,21.6255601 15.1150895,21.7136521 14.8940556,21.7136521 L2.50312899,21.7136521 C2.04231113,21.7136521 1.66793577,21.3392767 1.66793577,20.8784588 L1.66793577,14.0403144 L15.7292488,14.0403144 L15.7292488,20.8776424 Z" id="Fill-1"></path></g></g></g></g></g></svg>';
  const Image = image
    ? `<div class="mr-5 ml-4 d-none d-md-flex align-items-center"><img src=${image} class="map-image" /></div>`
    : '';
  const Website = website
    ? `<a href=${website} class="call2action btn btn-secondary mb-1"> ${LinkIcon}Visit residence website </a><br />`
    : '';
  const Phone = phone ? `<p><strong>Phone: </strong>${phone}</p>` : '';
  const BookAVisit = bookavisit
    ? `<a href=${bookavisit} class="call2action btn btn-secondary mb-1">${CalenderIcon}Book a Visit</a><br />`
    : '';

  return `
            <div class="d-flex">
              ${Image}
              <div class="innerContent">
                <h3> ${name}</h3>
                <p> ${address}</p>
                ${Phone}
                ${Website}
                ${BookAVisit}
              </div>
            </div>
          `;
};
