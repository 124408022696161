import React from 'react';

const residencesHidden = ({ fields, defaultEvent, contextitemId, selected }) => {
  const value = fields.model.prepopulateResidence === '1' ? contextitemId : selected;
  React.useEffect(() => {
    defaultEvent(fields.valueField.name, value, true, [], {
      required: 'Please select at least 1 residence.',
    });
  }, [selected, contextitemId]);
  return (
    <input
      jsid="residences-hidden"
      id={fields.valueField.id}
      name={fields.valueField.name}
      type="hidden"
      value={value}
      data-sc-tracking={fields.model.isTrackingEnabled}
      data-sc-field-name={fields.model.name}
    />
  );
};
export default residencesHidden;
