import React from 'react';
import { getFieldValue, Placeholder, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { withDatasourceCheck } from '../../../Foundation/HOC/withDatasourceCheck';

import { DownButton, HatTitle, Header, HeadLine, HeroMedia, SubTitle } from './ui';

const HeroBanner = ({ fields, rendering }) => {
  const styleCode = fields.Type?.fields?.Value?.value;
  const scrollDownText = fields['Scroll Down Text']?.value;
  const hasCTA = rendering?.placeholders['herobanner-cta'].length ? true : false;
  const { sitecoreContext } = useSitecoreContext();
  const isEditing = sitecoreContext && sitecoreContext.pageEditing;

  const HeadlineField = fields.Headline.value ? fields.Headline : fields.HeadlineP;
  const HeadlineTag = fields.Headline.value ? 'h1' : 'p';
  const SubtitleField = fields.Subtitle.value ? fields.Subtitle : fields.SubtitleP;
  const SubtitleTag = fields.Subtitle.value ? 'h2' : 'p';
  return (
    <Header
      styleCode={styleCode}
      hideOnMobile={getFieldValue(fields, 'Hide on Mobile')}
      id="hero-banner"
    >
      <HeroMedia
        video={getFieldValue(fields, 'Video')}
        videoMobile={getFieldValue(fields, 'Video Mobile')}
        videoTablet={getFieldValue(fields, 'Video Tablet')}
        image={getFieldValue(fields, 'Image')}
        imageMobile={getFieldValue(fields, 'Image Mobile')}
        imageTablet={getFieldValue(fields, 'Image Tablet')}
        hideOnMobile={getFieldValue(fields, 'Hide on Mobile')}
      />

      <div role="banner" aria-label="Content" className="container">
        <HatTitle hatTitle={fields['Hat Title']} />
        {isEditing ? (
          <>
            <HeadLine
              headLine={fields.Headline}
              hasHat={getFieldValue(fields, 'Hat Title') || getFieldValue(fields, 'Subtitle')}
              tag={'h1'}
            />
            <HeadLine
              headLine={fields.HeadlineP}
              hasHat={getFieldValue(fields, 'Hat Title') || getFieldValue(fields, 'Subtitle')}
              tag={'p'}
            />
            <SubTitle subTitle={fields.Subtitle} isHidden={styleCode !== 'medium'} tag={'h2'} />
            <SubTitle subTitle={fields.SubtitleP} isHidden={styleCode !== 'medium'} tag={'p'} />
          </>
        ) : (
          <>
            <HeadLine
              headLine={HeadlineField}
              aria-label="Headline"
              hasHat={getFieldValue(fields, 'Hat Title') || getFieldValue(fields, 'Subtitle')}
              tag={HeadlineTag}
            />
            <SubTitle
              subTitle={SubtitleField}
              aria-label="SubTitle"
              isHidden={styleCode !== 'medium'}
              tag={SubtitleTag}
            />
          </>
        )}
      </div>
      {styleCode === 'big' && (hasCTA || isEditing) ? (
        <>
          <div className="hero-ctas">
            <Placeholder name="herobanner-cta" rendering={rendering} />
          </div>
          <DownButton scrollDownText={scrollDownText} />
        </>
      ) : (
        isEditing && (
          <div className="hero-ctas" style={{ fontSize: '16px', bottom: '-10px' }}>
            <p>The Hero Banner must be set to the big size to display the placeholder</p>
          </div>
        )
      )}
    </Header>
  );
};

export default withDatasourceCheck()(HeroBanner);
