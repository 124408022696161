import React from 'react';
import {
  Text,
  getFieldValue,
  useSitecoreContext,
  isExperienceEditorActive,
} from '@sitecore-jss/sitecore-jss-react';
import { withDatasourceCheck } from '../../../Foundation/HOC/withDatasourceCheck';
import { residenceService } from '../../../Foundation/services';
import { Style } from '../../../Foundation/Utils/stringUtils';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const numberOfResultPerPage = 3;

const ResidenceSearchResults = ({ fields }) => {
  const { sitecoreContext } = useSitecoreContext();

  const [residences, setResidences] = React.useState([]);

  const [page, setPage] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [hasMore, setHasMore] = React.useState(true);

  const handleLoadResidences = async () => {
    try {
      setIsLoading(true);
      const searchParams = {
        numberOfResults: numberOfResultPerPage,
        page: page,
        query: fields.data.searchTerms,
        relatedResidence: sitecoreContext.featuredResidence || '',
        coordinates: fields.data.userCoordinates,
      };
      const { TotalNumberOfResults, Results, Success } = await residenceService.getResidences(
        searchParams
      );

      if (!Success) {
        throw 'Something went wrong while searching. Please check your query string';
      }

      if (TotalNumberOfResults <= residences.length + Results.length || !Results.length) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }

      setResidences([...residences, ...Results]);
      setPage((prev) => prev + 1);
    } catch (e) {
      // Handle error here
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    handleLoadResidences();
  }, []);

  if (!fields || !residences || residences.length === 0) {
    if (isExperienceEditorActive()) {
      console.log('Residence Search Results: No results for search query');
      return <p>Something went wrong with the Residence Search Results</p>;
    }
    return null;
  }
  return (
    <div className="residence-search-panel">
      <div className="container">
        <p>
          <label style={Style('font-weight:bold; margin-bottom:0 !important')}>
            {getFieldValue(fields?.item, 'Query Label Text')}
          </label>
          <label style={Style('font-style:italic; margin-bottom:0 !important')}>
            &nbsp;{fields.data.searchTerms}
          </label>
        </p>
        <div className="residence-results-container">
          <ul id="residence-list" className="residences-list">
            {residences.map((residence) => (
              <li className="residence-list-li" key={residence.ResidenceID}>
                <article className="card">
                  <a href={residence.Url}>
                    <div className="card-image">
                      <LazyLoadImage
                        src={residence.ResidenceImageUrl}
                        alt={residence.ResidenceImageAlt ?? residence.ResidenceTitle}
                      />
                    </div>
                    <div className="card-body">
                      <h2 className="card-residence-name">{residence.ResidenceTitle}</h2>
                      <p
                        className="card-residence-desc"
                        dangerouslySetInnerHTML={{
                          __html: residence.ResidenceDescription,
                        }}
                      ></p>
                    </div>
                  </a>
                  <div className="card-footer border-0 text-center">
                    <a
                      className="call2action btn cta-default card-residence-btn"
                      href={residence.Url}
                    >
                      {getFieldValue(fields?.item, 'CTA Text')}
                    </a>
                  </div>
                </article>
              </li>
            ))}
          </ul>
          <div className="row residence-lm-container">
            <div className="col-12 text-center">
              {!isLoading && hasMore && (
                <button className="btn btn-outline-primary" onClick={handleLoadResidences}>
                  {getFieldValue(fields?.item, 'Load More Text')}
                </button>
              )}
              {isLoading && <LoadingAnimation />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const LoadingAnimation = () => (
  <svg
    width="100%"
    height="100%"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
    className="loading-indicator"
  >
    <circle cx="16" cy="50" r="10" fill="#587A8D">
      <animate
        attributeName="r"
        values="10;0;0;0;0"
        keyTimes="0;0.25;0.5;0.75;1"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
        calcMode="spline"
        dur="1.8s"
        repeatCount="indefinite"
        begin="0s"
      ></animate>
      <animate
        attributeName="cx"
        values="84;84;84;84;84"
        keyTimes="0;0.25;0.5;0.75;1"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
        calcMode="spline"
        dur="1.8s"
        repeatCount="indefinite"
        begin="0s"
      ></animate>
    </circle>
    <circle cx="16" cy="50" r="10" fill="#587A8D">
      <animate
        attributeName="r"
        values="0;10;10;10;0"
        keyTimes="0;0.25;0.5;0.75;1"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
        calcMode="spline"
        dur="1.8s"
        repeatCount="indefinite"
        begin="-0.9s"
      ></animate>
      <animate
        attributeName="cx"
        values="16;16;50;84;84"
        keyTimes="0;0.25;0.5;0.75;1"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
        calcMode="spline"
        dur="1.8s"
        repeatCount="indefinite"
        begin="-0.9s"
      ></animate>
    </circle>
    <circle cx="16" cy="50" r="10" fill="#587A8D">
      <animate
        attributeName="r"
        values="0;10;10;10;0"
        keyTimes="0;0.25;0.5;0.75;1"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
        calcMode="spline"
        dur="1.8s"
        repeatCount="indefinite"
        begin="-0.45s"
      ></animate>
      <animate
        attributeName="cx"
        values="16;16;50;84;84"
        keyTimes="0;0.25;0.5;0.75;1"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
        calcMode="spline"
        dur="1.8s"
        repeatCount="indefinite"
        begin="-0.45s"
      ></animate>
    </circle>
    <circle cx="16" cy="50" r="10" fill="#587a8d">
      <animate
        attributeName="r"
        values="0;10;10;10;0"
        keyTimes="0;0.25;0.5;0.75;1"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
        calcMode="spline"
        dur="1.8s"
        repeatCount="indefinite"
        begin="0s"
      ></animate>
      <animate
        attributeName="cx"
        values="16;16;50;84;84"
        keyTimes="0;0.25;0.5;0.75;1"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
        calcMode="spline"
        dur="1.8s"
        repeatCount="indefinite"
        begin="0s"
      ></animate>
    </circle>
    <circle cx="16" cy="50" r="10" fill="#587A8D">
      <animate
        attributeName="r"
        values="0;0;10;10;10"
        keyTimes="0;0.25;0.5;0.75;1"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
        calcMode="spline"
        dur="1.8s"
        repeatCount="indefinite"
        begin="0s"
      ></animate>
      <animate
        attributeName="cx"
        values="16;16;16;50;84"
        keyTimes="0;0.25;0.5;0.75;1"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
        calcMode="spline"
        dur="1.8s"
        repeatCount="indefinite"
        begin="0s"
      ></animate>
    </circle>
  </svg>
);

export default withDatasourceCheck()(ResidenceSearchResults);
