import React from 'react';
import { withSitecoreContext, Text } from '@sitecore-jss/sitecore-jss-react';

const PageTitle = ({
  sitecoreContext: {
    route: { fields },
  },
}) => (
  <div role="complementary">
    <Text tag="h1" className="h1-bav-forms" field={fields['Page Title']} />
  </div>
);

export default withSitecoreContext()(PageTitle);
