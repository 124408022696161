import React, { Fragment } from 'react';
import { FieldValidationErrors } from '@sitecore-jss/sitecore-jss-react-forms/dist/components/field-templates/field-validation-errors';
import { Label } from '@sitecore-jss/sitecore-jss-react-forms/dist/components/field-templates/label';
import { FieldChangeCallback } from '@sitecore-jss/sitecore-jss-react-forms';
import { ValueFormField } from '@sitecore-jss/sitecore-jss-forms';

const CheckboxList = (props) => {
  const {
    field,
    field: {
      model: { items },
    },
    value,
    onChange,
    errors,
    tracker,
  } = props;
  return (
    <Fragment>
      <label
        key={props.field.fieldIdField.id}
        className={`hidden ${props.field.model.labelCssClass}`}
        htmlFor={props.field.valueField.id}
      >
        {props.field.model.title}
      </label>
      <fieldset aria-label={props.field.model.title} className="no-space">
        <legend className="hidden">{props.field.model.title}</legend>
        {items.map((item, index) => (
          <React.Fragment key={index}>
            <label
              key={item.itemId}
              className={`  ${
                value.some((v) => v === item.value) ? 'button-corp-radio-active' : ''
              }`}
            >
              <input
                type="checkbox"
                className={field.model.cssClass}
                id={field.valueField.id + index}
                name={field.valueField.name}
                value={item.value}
                checked={value.some((v) => v === item.value)}
                onChange={(e) =>
                  handleOnChange(field, value, e.target.value, e.target.checked, onChange)
                }
                onFocus={() => tracker.onFocusField(field, value)}
                onBlur={() => tracker.onBlurField(field, value, errors)}
              />
              {item.text}
            </label>
            {items.length !== index + 1 && <> </>}
          </React.Fragment>
        ))}
      </fieldset>

      <FieldValidationErrors {...props} />
    </Fragment>
  );
};

/**
 * @param {ValueFormField} field
 * @param {string[]} originalFieldValue
 * @param {string} changedElement
 * @param {boolean} checked
 * @param {FieldChangeCallback} callback
 */
function handleOnChange(field, originalFieldValue, changedElement, checked, callback) {
  // we can have multiple selected values. So we need to push ALL the selected
  // values back up to the root form. This is done using an array, which the form
  // serializer knows how to expand into multiple values on post

  let value = originalFieldValue;

  if (checked) {
    value.push(changedElement);
  } else {
    value = value.filter((v) => v !== changedElement);
  }
  callback(field.valueField.name, value, true, []);
}

export default CheckboxList;
