import React from 'react';
import { SRLWrapper, useLightbox } from 'simple-react-lightbox';
import { ReactComponent as PhotoIcon } from '../../../../assets/icons/photo.svg';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { withDatasourceCheck } from '../../../Foundation/HOC/withDatasourceCheck';
import Asset from '../../../Foundation/DFS/Asset';

const MosaicGallery = ({ fields }) => {
  const { openLightbox } = useLightbox();
  const title = fields?.Title;
  const buttonText = fields?.['Button Text'];
  const images = fields?.Gallery?.value?.assets?.map((image) => ({
    ...image,
    url:
      image.files.find((file) => file.name === 'original')?.url ||
      image.files?.[0]?.url ||
      image.url,
  }));
  const options = {
    caption: {
      showCaption: false,
    },
    thumbnails: {
      showThumbnails: false,
    },
    buttons: {
      showAutoplayButton: false,
      showCloseButton: false,
      showDownloadButton: false,
      showFullscreenButton: false,
      showNextButton: true,
      showPrevButton: true,
    },
  };

  if (!images) {
    return <></>;
  }

  return (
    <section role="region" aria-label="Gallery" className="mosaic-gallery">
      <div className="mosaic-gallery-header">
        <Text tag="h2" aria-label={title.value} field={title} />
        <button onClick={openLightbox} className="btn btn-secondary">
          <PhotoIcon />
          <Text tag="span" aria-label={buttonText.value} field={buttonText} />
        </button>
      </div>
      <SRLWrapper options={options}>
        <div className="mosaic-gallery-grid">
          <div className="row no-gutters">
            <div className="col-sm-6 d-none d-sm-block">
              <div className="row no-gutters">
                <div className="col-6">
                  <Image image={images[0]} index={0} />
                </div>
                <div className="col-6">
                  <Image image={images[1]} index={1} />
                </div>
              </div>
              <Image image={images[2]} index={2} />
            </div>

            <div className="col-sm-6">
              <div className="row no-gutters">
                <div className="col-8 square-big">
                  <Image image={images[3]} index={3} />
                </div>
                <div className="col-4">
                  <Image image={images[4]} index={4} />
                  <Image image={images[5]} index={5} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row no-gutters"></div>
      </SRLWrapper>
    </section>
  );
};

const Image = ({ image, index }) => (
  <a href={image?.url} aria-label={`Gallery-Image-${index}`}>
    <Asset editor={false} field={image} />
  </a>
);

export default withDatasourceCheck()(MosaicGallery);
