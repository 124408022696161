import React from 'react';
import useWindowSize from '../../../../Foundation/Hooks/useWindowSize';
import { Style } from '../../../../Foundation/Utils/stringUtils';

const SelectYears = (data) => {
  const { option, handler, selected } = data;
  const { width } = useWindowSize();
  return (
    <select
      id="slcYearFilter"
      className={`${width > 992 ? 'year-select form-control float-right' : 'custom-select'}`}
      onChange={(e) => handler(e.target.value)}
      selected={selected}
      style={Style(`${width < 992 ? 'margin-top:10px;' : ''}`)}
    >
      <option value="0">ALL YEARS</option>
      {option.map((o, i) => {
        return (
          <option value={o} key={i}>
            {o}
          </option>
        );
      })}
    </select>
  );
};
export default SelectYears;
