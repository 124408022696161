import React from 'react';
import ResidenceItem from '../ResidenceItem';

const ResidenceList = ({ residences }) => {
  if (!residences?.length) {
    return null;
  }

  return (
    <ul>
      {residences.map((residence, index) => (
        <ResidenceItem key={index} residence={residence} />
      ))}
    </ul>
  );
};

export default ResidenceList;
