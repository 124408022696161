import config from '../../../temp/config.js';

export const fetchData = async (path, options) => {
  const baseUrl = config.sitecoreApiHost;
  const result = await fetch(baseUrl + path, {
    method: 'GET',
    redirect: 'follow',
    ...options,
  });

  if (result.status !== 200) {
    // throw Error(JSON.stringify(result));
  }
  const data = await result.json();

  return data;
};
export const encodeQueryData = (data) => {
  return Object.keys(data)
    .map((key) => [key, data[key]].map(encodeURIComponent).join('='))
    .join('&');
};
