import React from 'react';
import { getString } from '../index';

const guestsHidden = ({ fields, defaultEvent, guestList }) => {
  const [value, setValue] = React.useState(getString(guestList));
  React.useEffect(() => {
    setValue(getString(guestList));
    defaultEvent(fields.valueField.name, getString(guestList), true, [], {
      required: 'Please specify at least 1 guest.',
    });
  }, [guestList]);
  return (
    <input
      jsid="guests-hidden"
      id={fields.valueField.id}
      name={fields.valueField.name}
      type="hidden"
      value={value}
      data-sc-tracking={fields.model.isTrackingEnabled}
      data-sc-field-name={fields.model.name}
    />
  );
};
export default guestsHidden;
