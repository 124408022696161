import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';

const Address = ({
  title,
  name,
  address,
  city,
  province,
  postalCode,
  phoneNumber,
  isResidence,
}) => {
  return (
    <div className="address">
      <Text tag="h2" field={title} />
      <p>
        {isResidence ? (
          <>
            {name}
            <br />
            {address}
            <br />
            {`${city} ${province} ${postalCode}`}
            <br />
            <br />
            <span className="phone">{phoneNumber}</span>
          </>
        ) : (
          <>
            <Text tag="span" field={address} />
            <br />
            <Text tag="span" className="phone" field={phoneNumber} />
          </>
        )}
      </p>
    </div>
  );
};

export default Address;
