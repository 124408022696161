import React, { Fragment } from 'react';
import { FieldValidationErrors } from '@sitecore-jss/sitecore-jss-react-forms/dist/components/field-templates/field-validation-errors';
import { FieldChangeCallback } from '@sitecore-jss/sitecore-jss-react-forms';
import { ValueFormField } from '@sitecore-jss/sitecore-jss-forms';

const RadioButtonList = (props) => {
  const {
    field,
    field: {
      model: { items },
    },
    defaultEvent,
    value,
    onChange,
    tracker,
    errors,
  } = props;
  return (
    <Fragment>
      <label
        key={props.field.fieldIdField.id}
        className={`hidden ${props.field.model.labelCssClass}`}
        htmlFor={props.field.valueField.id}
      >
        {props.field.model.title}
      </label>
      <fieldset aria-label={props.field.model.title} className="no-space">
        <legend className="hidden">{props.field.model.title}</legend>
        {items.map((item, index) => (
          <React.Fragment key={index}>
            <label
              key={item.itemId}
              className={`${value.some((v) => v === item.value) ? 'button-corp-radio-active' : ''}`}
              htmlFor={field.valueField.id + index}
            >
              <input
                type="radio"
                className={field.model.cssClass}
                id={field.valueField.id + index}
                name={field.valueField.name}
                value={item.value}
                checked={value.some((v) => v === item.value)}
                onChange={(e) => {
                  return handleOnChange(field, e.target.value, onChange);
                }}
                onFocus={() => tracker.onFocusField(field, value)}
                onBlur={() => tracker.onBlurField(field, value, errors)}
              />
              {item.text}
            </label>
            {items.length !== index + 1 && <> </>}
          </React.Fragment>
        ))}
      </fieldset>

      <FieldValidationErrors {...props} />
    </Fragment>
  );
};

/**
 * @param {ValueFormField} field
 * @param {string} newValue
 * @param {FieldChangeCallback} callback
 */
function handleOnChange(field, newValue, callback) {
  let valid = true;
  const errorMessages = [];

  // custom client validation logic here
  if (field.model.required && !newValue) {
    valid = false;
    errorMessages.push(`${field.model.title} is required`);
  }
  field.model.value = [newValue];
  callback(field.valueField.name, [newValue], valid, errorMessages, true);
}

export default RadioButtonList;
