import React, { useEffect, useState } from 'react';
import { Text, isExperienceEditorActive, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { withDatasourceCheck } from '../../../Foundation/HOC/withDatasourceCheck';
import { ReactComponent as SearchIcon } from '../../../../assets/icons/search.svg';
import { withRouter } from 'react-router-dom';
import getLandingPages from '../../../Foundation/Utils/getLandingPages';

function getCounterHeroBannerButtons() {
  var heroctachildren = document.getElementsByClassName('hero-ctas')[0].children;
  var countbuttons = 0;
  var children = heroctachildren;
  for (var i = 0; i < children.length; i++) {
    var tableChild = children[i];
    if (tableChild.nodeName === 'BUTTON') {
      countbuttons = countbuttons + 1;
    }
  }
  return countbuttons;
}

const HeroBannerResidenceSearchBox = ({ fields, rendering, history }) => {
  const [textboxValue, setTextboxValue] = useState('');
  const [formAction, setFormAction] = useState('');
  const [landingPages, setLandingPages] = React.useState([]);

  useEffect(() => {
    if (getCounterHeroBannerButtons() > 1) {
      document.getElementById('herobanner-searchbox').style.width = '100%';
      document.getElementById('herobanner-searchbox').style.marginLeft = '10px';
      document.getElementById('herobanner-searchbox').style.marginRight = '10px';
    }

    function onResize() {
      if (window.innerWidth < 992) {
        document.getElementById('herobanner-searchbox').style.marginLeft = '0px';
        document.getElementById('herobanner-searchbox').style.marginRight = '0px';
      } else {
        document.getElementById('herobanner-searchbox').style.marginLeft = '10px';
        document.getElementById('herobanner-searchbox').style.marginRight = '10px';
      }
    }

    window.addEventListener('resize', onResize);

    setFormAction(window.location.pathname);

    getLandingPages().then((result) => {
      setLandingPages(result);
    });
  }, []);

  const onChange = (event) => {
    setTextboxValue(event.target.value);
  };

  const handleSubmit = (e) => {
    const searchTerm = e.target[1]?.value;
    e.preventDefault();
    if (searchTerm) {
      const resultPage = fields['Results Page']?.url || '';
      const encodedSearchTerm = encodeURIComponent(searchTerm.trim().replaceAll(' ', '-'));
      if (!landingPages.includes(encodedSearchTerm.toLowerCase())) {
        window.location.href = `${resultPage}/search?llquery=${encodedSearchTerm}&mc=false`;
      } else {
        window.location.href = `${resultPage}/${encodedSearchTerm.toLowerCase()}`;
      }
    }
  };

  return (
    <div
      id="herobanner-searchbox"
      style={{
        maxHeight: '47px',
        marginTop: '20px',
        backgroundColor: 'rgba(248,249,250)',
        borderColor: 'transparent',
        minWidth: '280px',
      }}
    >
      <form action={formAction} onSubmit={handleSubmit} id="herobannerResidenceSearchBox">
        <input name="uid" type="hidden" value={rendering.uid} />
        <div style={{ display: 'flex' }}>
          <div style={{ position: 'relative', width: '80%' }}>
            <SearchIcon className="hidden split-hero-search-icon" />
            <input
              id="llquery"
              name="llquery"
              className="form-control search-input"
              value={textboxValue}
              onChange={onChange}
              placeholder={fields['Placeholder Text']?.value}
              aria-label={fields['Accessibility Label Text']?.value}
              style={{ border: 'none', paddingLeft: '20px' }}
            />
          </div>
          <div className="search-button-container" style={{ width: '20%', color: '#000' }}>
            <button type="submit" aria-label="search" tabIndex={-1} style={{ marginTop: '10px' }}>
              <Text
                tag="span"
                className="hidden search-button-label"
                field={fields?.['Search Button Title']}
              />
              <SearchIcon />
            </button>
          </div>
          <input id="url" name="url" type="hidden" value={getResultsPageValue()} />
        </div>
      </form>
    </div>
  );

  function getResultsPageValue() {
    if (fields['Results Page']) {
      return fields['Results Page'].url;
    }
    return '';
  }
};
export default withDatasourceCheck()(withRouter(HeroBannerResidenceSearchBox));
