import React, { Fragment, useState, useEffect } from 'react';
import { Placeholder, RichText, Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { graphQLEndpoint } from '../../../../temp/config';
import { Style } from '../../../Foundation/Utils/stringUtils';
import Asset from '../../../Foundation/DFS/Asset';
import ShareButtons from '../../../Foundation/components/ShareButtons';

const shareThisQuery =
  'query={ datasource(value: "{27B984CA-F5BA-41DC-9687-7C2FAC3DEDB1}") { script: field(name: "value") { value } } }';

const useScript = (url) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = url;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [url]);
};

async function getShareThisSource() {
  const response = await fetch(graphQLEndpoint + '&' + shareThisQuery);
  const data = await response.json();
  const scriptTag = data.data.datasource.script.value;
  var src = scriptTag.substring(scriptTag.indexOf('src') + 5, scriptTag.indexOf('>') - 1);
  return src;
}

const MediaHitsArticle = (props) => {
  const { sitecoreContext, rendering } = props;
  const routeFields = sitecoreContext.route.fields;
  const [size, setSize] = useState(1);
  const [shareThisTag, setShareThisTag] = useState('');
  const [classExperienceEditor, setExperienceEditorClass] = useState('article');
  const isEditing = sitecoreContext && sitecoreContext.pageEditing;

  useEffect(() => {
    getShareThisSource().then((result) => {
      setShareThisTag(result);
    });
    if (isEditing) {
      setExperienceEditorClass('article articleExperienceEditor');
    }
  }, []);

  useScript(shareThisTag);

  return (
    <Fragment>
      <article className={classExperienceEditor} style={Style(`font-size: ${size}em`)}>
        <div className="container">
          <div role="complementary" aria-label="Hits" className="article-tools">
            <Placeholder name="phArticleTop" rendering={rendering} />
            <ShareButtons />
            <div className="text-resize" aria-hidden="true">
              <button
                tabIndex={-1}
                className="text-resize-normal"
                onClick={(evt) => {
                  evt.preventDefault();
                  setSize(1);
                }}
              >
                A
              </button>
              <button
                tabIndex={-1}
                className="text-resize-big"
                onClick={(evt) => {
                  evt.preventDefault();
                  setSize(1.25);
                }}
              >
                A
              </button>
              <button
                tabIndex={-1}
                className="text-resize-bigger"
                onClick={(evt) => {
                  evt.preventDefault();
                  setSize(1.5);
                }}
              >
                A
              </button>
            </div>
          </div>
          <header className="article-header">
            <Text tag="h1" field={routeFields['Page Title']} />
          </header>
          <RichText field={routeFields.Body} className="article-body" />
          <div role="contentinfo" aria-label="Article" className="row article-author ">
            {routeFields.Author?.fields?.Photo && (
              <div className="col-sm-12 col-md-2 author-img-panel">
                <div className="author-image-cropper">
                  <a href={routeFields.Author.Url}>
                    <Asset
                      editor={isEditing}
                      className="author-img"
                      field={routeFields.Author.fields.Photo}
                    />
                  </a>
                </div>
              </div>
            )}
            {routeFields.Author?.fields?.Byline && (
              <div className="col-sm-12 col-md-10 bio-panel">
                <RichText field={routeFields.Author.fields.Byline} />
              </div>
            )}
          </div>
          <footer role="contentinfo" aria-label="Footer" className="article-footer">
            <div className="d-flex flex-column justify-content-end flex-grow-1">
              <Placeholder name="phCTA" rendering={rendering} />
            </div>
            <ShareButtons />
          </footer>
        </div>
      </article>
      <div>
        <Placeholder name="phArticleBottom" rendering={rendering} />
      </div>
      <div>
        <Placeholder name="phBlogRollup" rendering={rendering} />
      </div>
    </Fragment>
  );
};

export default withSitecoreContext()(MediaHitsArticle);
