import { fetchData, encodeQueryData } from '../Utils/fetchData';

const getArticles = async (str, page, numberOfResults) => {
  const result = await fetchData(
    `amica/api/searchservice/getarticleresultjson?${encodeQueryData({
      numberOfResults: numberOfResults,
      page: page,
      query: str,
    })}`
  );
  return JSON.parse(result);
};

export default { getArticles };
