import React, { useState, useEffect } from 'react';
import { isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';
import Link from '../Link';

const FollowUs = ({ title, links }) => {
  const [renderHtml, setRenderHtml] = useState(false);

  useEffect(() => {
    if (isExperienceEditorActive()) {
      setRenderHtml(true);
    }
  }, []);

  if (!Array.isArray(links) || !(links?.length > 0) || !title) {
    return null;
  }

  return (
    <>
      {renderHtml ? (
        <span className="footer-span-ee" dangerouslySetInnerHTML={{ __html: title }} />
      ) : (
        <h2>{title}</h2>
      )}
      <div className="row">
        <div className="col-12 text-center">
          {links?.map((link, key) => (
            <React.Fragment key={key}>
              <Link item={link} />{' '}
            </React.Fragment>
          ))}
        </div>
      </div>
    </>
  );
};

export default FollowUs;
