import React from 'react';
import clsx from 'clsx';
import { useSitecoreContext, isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const FeaturedSearch = ({ fields }) => {
  const { updateSitecoreContext, sitecoreContext } = useSitecoreContext({ updatable: true });
  React.useEffect(() => {
    if (fields) {
      updateSitecoreContext({
        ...sitecoreContext,
        featuredResidence: fields?.featuredResidenceLink,
      });
    }
  }, []);

  if (!fields) {
    if (isExperienceEditorActive()) {
      console.log('Featured Search: No results for search query');
      return <p>Something went wrong with the Featured Search</p>;
    }
    return null;
  }
  return (
    <div className="row featured-container">
      <input id="featuredResidence" type="hidden" value={fields.featuredResidenceLink} />
      <div
        className={clsx(
          fields.imageUrl
            ? 'col-sm-9 content featured-content'
            : 'col-sm-12 content featured-content full'
        )}
      >
        <h2 aria-label="Result Title">{fields.resultTitle}</h2>
        <div aria-label="Description" className="description">
          {fields.description}
        </div>
        {fields.featuredResultLink && (
          <a
            aria-label={fields.ctaLabel}
            className="call2action btn"
            href={fields.featuredResultLink}
          >
            {fields.ctaLabel}
          </a>
        )}
      </div>

      {fields.imageUrl && (
        <div className="col-sm-3 featured-image">
          <LazyLoadImage src={fields.imageUrl} alt={fields.imageAlt} />
        </div>
      )}
    </div>
  );
};

export default FeaturedSearch;
