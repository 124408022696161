import React from 'react';
import { isExperienceEditorActive, getFieldValue, Link } from '@sitecore-jss/sitecore-jss-react';
import Asset from '../../../Foundation/DFS/Asset';
import { withDatasourceCheck } from '../../../Foundation/HOC/withDatasourceCheck';

const ArticlesRollup = ({ fields = {} }) => {
  if (!fields.ArticleRollupItems?.length) {
    return null;
  }

  return (
    <section className="articles articles-rollup">
      <div role="complementary" aria-label="Articles Rollup" className="container">
        <div className="articles-rollup-header">
          <h2 aria-label="Rollup Text">{getFieldValue(fields, 'RollupHeaderText')}</h2>
          <Link className="link-goto" field={{ href: fields.RollupHeaderLinkUrl?.value }}>
            {getFieldValue(fields, 'TaxonomyHeaderText')}
            <ChevronRightIcon className="chevron-right" />
          </Link>
        </div>
        <ul className="articles-list">
          {fields.ArticleRollupItems.map((article, index) => (
            <li key={index}>
              <article className="card">
                <Link field={{ href: article.Url }}>
                  <div className="card-image">
                    <Asset
                      editor={isExperienceEditorActive()}
                      field={article.Image}
                      imageParams={{ mw: 460 }}
                    />
                  </div>
                  <div className="card-body">
                    <h2 aria-label="Page Title">{getFieldValue(article, 'PageTitle')} </h2>
                    <p aria-label="Teaser">{getFieldValue(article, 'Teaser')}</p>
                  </div>
                </Link>
                {article.CategoryLandingPageUrl && (
                  <div className="card-footer">
                    <Link field={{ href: article.CategoryLandingPageUrl }}>
                      {article.CategoryTitle}
                    </Link>
                  </div>
                )}
              </article>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

const ChevronRightIcon = (props) => (
  <svg width="6" height="11" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M0 .906L4.222 5.5 0 10.094.889 11 6 5.5.889 0z" fill="#767679" fillRule="evenodd" />
  </svg>
);
export default withDatasourceCheck()(ArticlesRollup);
