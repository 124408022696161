import React from 'react';
import clsx from 'clsx';
import { getFieldValue, isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';
import { withDatasourceCheck } from '../../../Foundation/HOC/withDatasourceCheck';

const GoogleMap = ({ fields }) => {
  const [isOverlayActive, setIsOverlayActive] = React.useState(false);

  const toggleOverlay = () => setIsOverlayActive((prev) => !prev);

  if (!getFieldValue(fields, 'Google Map') && !isExperienceEditorActive()) {
    return null;
  }

  var googleMapLink = '';
  var reg = new RegExp('<iframe.+?src=["\'](.+?)["\'].*?>', 'ig');

  const regexResults = reg.exec(fields['Google Map'].value);
  if (Array.isArray(regexResults) && regexResults.length === 2) {
    googleMapLink = regexResults[1];
  }

  if (googleMapLink === '') {
    if (isExperienceEditorActive()) {
      return <p>Please make sure the Google Map field is correct</p>;
    }
    return null;
  }

  return (
    <section className="fullwidth-map" aria-hidden="true">
      <div className={clsx('overlay', isOverlayActive && 'active')} onClick={toggleOverlay}>
        <button className="btn btn-secondary" tabIndex={-1}>
          <span className="text-locked">{getFieldValue(fields, 'Locked Map Text')}</span>
          <span className="text-unlocked">{getFieldValue(fields, 'Unlocked Map Text')}</span>
        </button>
      </div>
      <iframe src={googleMapLink} className="map-iframe" frameBorder="0" allowFullScreen></iframe>
    </section>
  );
};

export default withDatasourceCheck()(GoogleMap);
