import React from 'react';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import ReactPlayer from 'react-player';
import { LazyLoadImage } from 'react-lazy-load-image-component';
const ImageVideo = ({ fields }) => {
  const { model } = fields;
  const width = model.width === 0 ? '100%' : model.width + 'px';
  const height = model.height === 0 ? '100%' : model.height + 'px';
  if (!fields) {
    return <></>;
  }

  return (
    <div className={`${model.cssClass} image-video-field`} style={{ width: width, height: height }}>
      {model.embedLink ? (
        <div style={{ position: 'relative', paddingTop: '56.25%' }} className="div-iframe-video">
          <RichText field={{ value: model.embedLink }} />
        </div>
      ) : model.videoUrl ? (
        <div style={{ position: 'relative', paddingTop: '56.25%' }} className="div-iframe-video">
          <ReactPlayer
            url={model.videoUrl}
            playing={false}
            loop={false}
            muted={false}
            controls={true}
            width={'100%'}
            height={'100%'}
          />
        </div>
      ) : (
        <LazyLoadImage src={model.imageUrl} style={{ width: '100%', height: '100%' }} />
      )}
    </div>
  );
};

export default ImageVideo;
