import React from 'react';
import Link from '../Link';

const Menu = ({ links }) => {
  if (!Array.isArray(links) || !(links?.length > 0)) {
    return null;
  }

  return (
    <>
      {links?.map((link, key) => (
        <React.Fragment key={key}>
          <ul className="menu" key={key}>
            {Array.isArray(link?.links) &&
              link?.links?.map((subLink, k) => (
                <React.Fragment key={k}>
                  {subLink.Link.value.href && (
                    <li key={k}>
                      <Link item={subLink} />
                    </li>
                  )}
                </React.Fragment>
              ))}
          </ul>
          <hr />
        </React.Fragment>
      ))}
    </>
  );
};

export default Menu;
