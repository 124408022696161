import React from 'react';
const Section = (props) => {
  const { fields, fieldFactory, model } = props;
  if (!fields) {
    return <></>;
  }
  return <div className={fields.model.cssClass}>{fields.fields.map(fieldFactory)}</div>;
};

export default Section;
