import React from 'react';
import {
  RichText,
  Placeholder,
  getFieldValue,
  Link,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';
import { useLocation } from 'react-router-dom';
import { cludoSearchService } from '../../../Foundation/services';
import useScript from '../../../Foundation/Hooks/useScript';

const numberOfResults = 3;
const cludoScriptUrl =
  'https://customer.cludo.com/scripts/bundles/analytics/1.0.3/cludo-statistics.app.js';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const CludoSearchResults = ({ fields, rendering, sitecoreContext }) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [hasMore, setHasMore] = React.useState(true);
  const [currentPage, setCurrentPage] = React.useState(1);

  const [searchResult, setSearchResult] = React.useState([]);
  const [totalSearchResult, setTotalSearchResult] = React.useState(0);
  const search = useLocation().search;
  const [queryId, setQueryId] = React.useState('');
  const [bannerId, setBannerId] = React.useState('');

  let queryParams = useQuery();
  const scriptStatus = useScript(cludoScriptUrl);

  const queryLabelText = getFieldValue(fields, 'Query Label Text')?.split('$query');
  const resultsLabelText = queryLabelText[1]?.split('$results');

  React.useEffect(() => {
    handleSeacrh();
  }, []);

  React.useEffect(() => {
    if (scriptStatus === 'ready') {
      CludoSearch.initialize(sitecoreContext.cludo.customerId, sitecoreContext.cludo.engineId);
    }
  }, [scriptStatus]);

  const handleSeacrh = async () => {
    setIsLoading(true);
    try {
      const {
        Results,
        TotalNumberOfResults,
        QueryId,
        Banners,
        Success,
      } = await cludoSearchService.getCludoResults({
        query: queryParams.get('srquery'),
        page: currentPage,
        numberOfResults,
      });

      if (!Success) {
        throw 'Something went wrong while searching. Please check your query string';
      }

      if (TotalNumberOfResults <= searchResult.length + Results.length || !Results.length) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }

      if (Banners.length) {
        setBannerId(QueryId);
      }

      setQueryId(QueryId);
      setTotalSearchResult(TotalNumberOfResults);
      setSearchResult([...searchResult, ...Results]);
      setCurrentPage((prev) => prev + 1);
    } catch (e) {
      console.warn(e);
      setHasMore(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div
        id="analyticsdetails"
        className="search-results"
        data-cludo-query={queryParams.get('srquery')}
        data-cludo-query-id={queryId}
        data-cludo-cludo-page={currentPage}
        data-cludo-total-results={totalSearchResult}
        data-cludo-banner-ids={bannerId}
        data-cludo-referral-url={search}
        data-cludo-referral-title={typeof document !== 'undefined' ? document?.title : ''}
      />

      <div role="search" className="main-container">
        <div id="cludo-list" className="container search-results">
          <div className="your-search">
            <p id="query-label">
              <label className="label search-label highlight-result">
                {queryLabelText[0] || ''}
              </label>
              <label className="terms search-label">{'\u00A0' + queryParams.get('srquery')}</label>
              {` ${resultsLabelText[0] || ''} ${totalSearchResult} ${resultsLabelText[1] || ''}`}
            </p>
          </div>
          <Placeholder name="featuredSearch" rendering={rendering} />
          {searchResult.map((el, index) => (
            <div className="result" key={index}>
              <h4>
                <label className="search-label highlight-result">{el.Title}</label>
              </h4>

              <p>
                <RichText tag="span" field={{ value: el.Snipped }} />
                <Link field={{ href: el.Url }}>
                  <label className="search-label highlight-result">
                    {getFieldValue(fields, 'Learn More Text')}
                  </label>
                </Link>
              </p>
            </div>
          ))}
          <div id="btn-container" className="result text-center">
            {!isLoading && hasMore && (
              <button
                id="cludo-load-more"
                className="btn btn-outline-primary"
                onClick={handleSeacrh}
              >
                {getFieldValue(fields, 'Load More Text')}
              </button>
            )}
            {isLoading && <LoadingAnimation className="loading-indicator" />}
          </div>
        </div>
      </div>
    </>
  );
};

export default withSitecoreContext()(CludoSearchResults);

const LoadingAnimation = (props) => (
  <svg
    width="100%"
    height="100%"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
    {...props}
  >
    <circle cx="16" cy="50" r="10" fill="#587A8D">
      <animate
        attributeName="r"
        values="10;0;0;0;0"
        keyTimes="0;0.25;0.5;0.75;1"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
        calcMode="spline"
        dur="1.8s"
        repeatCount="indefinite"
        begin="0s"
      ></animate>
      <animate
        attributeName="cx"
        values="84;84;84;84;84"
        keyTimes="0;0.25;0.5;0.75;1"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
        calcMode="spline"
        dur="1.8s"
        repeatCount="indefinite"
        begin="0s"
      ></animate>
    </circle>
    <circle cx="16" cy="50" r="10" fill="#587A8D">
      <animate
        attributeName="r"
        values="0;10;10;10;0"
        keyTimes="0;0.25;0.5;0.75;1"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
        calcMode="spline"
        dur="1.8s"
        repeatCount="indefinite"
        begin="-0.9s"
      ></animate>
      <animate
        attributeName="cx"
        values="16;16;50;84;84"
        keyTimes="0;0.25;0.5;0.75;1"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
        calcMode="spline"
        dur="1.8s"
        repeatCount="indefinite"
        begin="-0.9s"
      ></animate>
    </circle>
    <circle cx="16" cy="50" r="10" fill="#587A8D">
      <animate
        attributeName="r"
        values="0;10;10;10;0"
        keyTimes="0;0.25;0.5;0.75;1"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
        calcMode="spline"
        dur="1.8s"
        repeatCount="indefinite"
        begin="-0.45s"
      ></animate>
      <animate
        attributeName="cx"
        values="16;16;50;84;84"
        keyTimes="0;0.25;0.5;0.75;1"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
        calcMode="spline"
        dur="1.8s"
        repeatCount="indefinite"
        begin="-0.45s"
      ></animate>
    </circle>
    <circle cx="16" cy="50" r="10" fill="#587a8d">
      <animate
        attributeName="r"
        values="0;10;10;10;0"
        keyTimes="0;0.25;0.5;0.75;1"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
        calcMode="spline"
        dur="1.8s"
        repeatCount="indefinite"
        begin="0s"
      ></animate>
      <animate
        attributeName="cx"
        values="16;16;50;84;84"
        keyTimes="0;0.25;0.5;0.75;1"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
        calcMode="spline"
        dur="1.8s"
        repeatCount="indefinite"
        begin="0s"
      ></animate>
    </circle>
    <circle cx="16" cy="50" r="10" fill="#587A8D">
      <animate
        attributeName="r"
        values="0;0;10;10;10"
        keyTimes="0;0.25;0.5;0.75;1"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
        calcMode="spline"
        dur="1.8s"
        repeatCount="indefinite"
        begin="0s"
      ></animate>
      <animate
        attributeName="cx"
        values="16;16;16;50;84"
        keyTimes="0;0.25;0.5;0.75;1"
        keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1"
        calcMode="spline"
        dur="1.8s"
        repeatCount="indefinite"
        begin="0s"
      ></animate>
    </circle>
  </svg>
);
