import React, { useState, useEffect } from 'react';
import { isExperienceEditorActive, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { ReactComponent as LoadingIndicator } from '../../../../assets/icons/loading-indicator.svg';
import { useHistory, useLocation } from 'react-router-dom';

import mediaHitsService from '../../../Foundation/services/mediaHitsService';
import { getPropertyByPath, objToQueryString, qsToObj } from '../../../Foundation/Utils';
import { Style } from '../../../Foundation/Utils/stringUtils';

// components
import SelectYears from './components/SelectYears';
import HitsList from './components/HitsList';
import HitsFilter from './components/HitsFilter';

import useWindowSize from '../../../Foundation/Hooks/useWindowSize';
import { withDatasourceCheck } from '../../../Foundation/HOC/withDatasourceCheck';

const MediaHits = (props) => {
  let history = useHistory();
  const { fields, rendering } = props;
  const id = getPropertyByPath(fields, 'Id.value');
  const LoadMoreText = getPropertyByPath(fields, 'LoadMoreText.value');
  const NoResultsText = getPropertyByPath(fields, 'NoResultsText.value');
  const QueryLabelText = getPropertyByPath(fields, 'QueryLabelText.value');
  const { width } = useWindowSize();
  const [params, setParams] = useState({ categoryId: '', pageNumber: 1, year: 0, cardListId: id });
  const search = useLocation().search;
  const [categories, setCategories] = useState([]);
  const [filteredCategory, setFilteredCategory] = useState([]);
  const [years, setYears] = useState([]);
  const [articles, setArticles] = useState([]);
  const [articlesCount, setArticlesCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [categoryWarning, setCategoryWarning] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [hideFilter, setHideFilter] = useState(
    getPropertyByPath(fields, 'HideFilter.value') || false
  );

  useEffect(() => {
    loadArticles();
    setHistory();
  }, [params]);

  useEffect(() => {
    setDefaultParams();
    getCategories();
  }, []);
  const setHistory = () => {
    let searchObj = {};

    if (params.categoryId) {
      Object.assign(searchObj, {
        category: params.categoryId,
      });
    }
    if (params.pageNumber > 1) {
      Object.assign(searchObj, {
        page: params.pageNumber,
      });
    }
    if (params.year > 0) {
      Object.assign(searchObj, {
        year: params.year,
      });
    }

    const queryString = objToQueryString(searchObj);
    history.push({
      search: `?${queryString}`,
    });
  };
  const setDefaultParams = () => {
    const searchObjs = qsToObj(search);
    if (searchObjs && Object.keys(searchObjs).length !== 0 && searchObjs.constructor === Object) {
      setParams({
        ...params,
        ...{
          categoryId: searchObjs.category || '',
          pageNumber: searchObjs.page || 1,
          year: searchObjs.year || 0,
        },
      });
    }
  };
  const getCategories = () => {
    if (params) {
      setLoading(true);
      mediaHitsService.getCategories(params).then((data) => {
        setLoading(false);
        if (data) {
          const parseData = JSON.parse(data);
          if (parseData.Categories?.length > 6) {
            setCategories(parseData.Categories.slice(0, 6));
            setCategoryWarning(true);
          } else {
            setCategories(parseData.Categories);
            setCategoryWarning(false);
          }
          setFilteredCategory(parseData.Categories.find((x) => x.Id === params.categoryId));

          setYears(parseData.Years);
        }
      });
    }
  };
  const loadArticles = () => {
    if (params) {
      if (params.pageNumber === 1) {
        setArticlesCount(0);
        setArticles([]);
        setTotalCount(0);
      }
      setLoading(true);
      mediaHitsService.getMediaHits(params).then((data) => {
        setLoading(false);
        const parseData = JSON.parse(data);
        if (parseData?.Cards) {
          const count = parseData.Cards.length || 0;
          setArticlesCount(count);
          setArticles(parseData.Cards);
          setTotalCount(parseData.TotalCount);
          setHideFilter(parseData.HideFilter);
        }
      });
    }
  };
  const loadMore = () => {
    setParams({
      ...params,
      ...{
        pageNumber: params.pageNumber + 1,
        loadAll: 0,
      },
    });
  };
  return (
    <section id="media-hits" className={'articles articles-page'}>
      <Placeholder name="phArticleTop" rendering={rendering} />
      <div role="navigation" aria-label="Media" className="container">
        {!fields.HideFilter.value && (
          <nav id="media-hits-filter" className="articles-filters">
            {categories && params && (
              <HitsFilter
                option={categories}
                handler={(v) => {
                  setParams({
                    ...params,
                    ...{
                      categoryId: v,
                      pageNumber: 1,
                    },
                  });
                }}
                categoryId={params.categoryId}
                selected={params.categoryId}
              />
            )}

            {years && params && width <= 992 && (
              <div className="row">
                <div className="col-12">
                  <SelectYears
                    option={years}
                    selected={params.year}
                    handler={(v) => {
                      setParams({
                        ...params,
                        ...{
                          year: v,
                          pageNumber: 1,
                        },
                      });
                    }}
                  />
                </div>
              </div>
            )}
          </nav>
        )}
        {categoryWarning && isExperienceEditorActive() && (
          <div id="category-warning" className="alert alert-danger" role="alert">
            Articles filter is limited to show 6 category only. Selected cards belong more than 6
            article category. So only 6 category of them are being displayed.
          </div>
        )}
        {years && params && width > 992 && (
          <div className="row">
            <div className="col-12">
              <SelectYears
                option={years}
                selected={params.year}
                handler={(v) => {
                  setParams({
                    ...params,
                    ...{
                      year: v,
                      pageNumber: 1,
                    },
                  });
                }}
              />
            </div>
          </div>
        )}
        {articlesCount ? (
          <HitsList
            hideFilter={hideFilter}
            articles={articles}
            filteredCategory={filteredCategory}
          />
        ) : (
          !loading && (
            <p id="media-hits-empty" className="articles-empty">
              {NoResultsText}
            </p>
          )
        )}
        {loading && <LoadingIndicator />}
        {!loading && articlesCount !== 0 && articlesCount < totalCount && (
          <div className="articles-pagination" style={!loading ? Style('display: block') : ''}>
            <button
              id="media-hits-load-more"
              className="btn btn-outline-primary"
              onClick={loadMore}
            >
              {LoadMoreText}
            </button>
          </div>
        )}
      </div>
    </section>
  );
};
export default withDatasourceCheck()(MediaHits);
