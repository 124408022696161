import React, { useEffect } from 'react';
import Link from '../Link';

const TopRow = ({ links, teaser }) => {
  if (!(links?.length > 0) || !Array.isArray(links)) {
    return null;
  }

  return (
    <div className="menu-toprow">
      <div className="container">
        <ul className="menu-toprow-list">
          {links.map((item, key) => (
            <li key={key}>
              <Link item={item} />
            </li>
          ))}
          {teaser && <li className="teaser">{teaser}</li>}
        </ul>
      </div>
    </div>
  );
};

export default TopRow;
