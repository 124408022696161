import $ from 'jquery';
export default function navJquery() {
  $(function ($) {
    (function ($) {
      'use strict';
      var scrollPos;
      $('.menu-toggler').on('click', function () {
        var isMenuOpen = $('.menu-toggler').hasClass('collapsed');
        if (isMenuOpen) {
          scrollPos = $(window).scrollTop();
          $(window).scrollTop(0);
          $('.main-nav').addClass('menuOpen');
        } else {
          $('.main-nav').removeClass('menuOpen');
          $(window).scrollTop(scrollPos);
        }
      });
    })($);
  });
}
