import React from 'react';
import { Image } from '@sitecore-jss/sitecore-jss-react';

const VideoAsset = ({ field = {}, ...rest }) => {
  const imageFields = field.value || field;

  let editableFix = field.editable;
  if (field.editable) {
    editableFix = field.editable.replace(
      /<iframe [^>]*><\/iframe>/,
      '<img src="/sitecore/shell/Themes/Standard/Images/WebEdit/default_image.png" alt="Default Image" />'
    );
  }
  const image = {
    value: {
      src: '/sitecore/shell/Themes/Standard/Images/WebEdit/default_image.png',
      alt: imageFields?.alt || imageFields?.asset?.description,
      title: imageFields?.name || null,
    },
    editable: editableFix,
  };
  return <Image field={image} {...rest} />;
};

export default VideoAsset;
