import React from 'react';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

export const DefaultEditingError = () => (
  <div className="sc-jss-editing-error" role="alert">
    Datasource is required. Please choose a content item for this component.
  </div>
);

/**
 * Checks whether a Sitecore datasource is present and renders appropriately depending on page mode (normal vs editing).
 * @param  [options]
 * @returns
 *  The wrapped component, if a datasource is present.
 *  A null component (in normal mode) or an error component (in editing mode), if a datasource is not present.
 */
export function withDatasourceCheck(options) {
  return function withDatasourceCheckHoc(Component) {
    return function WithDatasourceCheck(props) {
      const { sitecoreContext } = useSitecoreContext();
      const EditingError = options?.editingErrorComponent ?? DefaultEditingError;

      return props.rendering?.dataSource && props.fields ? (
        <Component {...props} />
      ) : sitecoreContext.pageEditing ? (
        <EditingError />
      ) : null;
    };
  };
}
