import React, { useEffect } from 'react';
import { Placeholder, VisitorIdentification } from '@sitecore-jss/sitecore-jss-react';
import { withTranslation } from 'react-i18next';
import Helmet from 'react-helmet';
import config from '../temp/config';
import smoothScrolling from '../components/Foundation/Utils/anchors';

let Navigation = ({ t, i18n }) => null;

Navigation = withTranslation()(Navigation);

const MainLayout = ({ route, context }) => {
  let title = '';
  const imageFields = route.fields.ImageOG?.value;

  const lang = context.language,
    pageTitle = route.fields['Page Title']?.value,
    browserTitle = route.fields['Browser Title']?.value,
    googleScheme = route.fields['Google Schema Markup Code']?.value,
    canonical =
      route.name === '*'
        ? config.sitecoreApiHost +
          context.itemPath.replace(/^\/+/g, '').replace(/\s+/g, '-').toLowerCase()
        : route.fields.Canonical?.value ||
          config.sitecoreApiHost +
            context.itemPath.replace(/^\/+/g, '').replace(/\s+/g, '-').toLowerCase(),
    metaDescription = route.fields['Meta Description']?.value,
    metaKeywords = route.fields['Meta Keywords']?.value,
    noFollow = route.databaseName === 'web' ? route.fields['No Follow']?.value : true,
    noIndex = route.databaseName === 'web' ? route.fields['No Index']?.value : true,
    titleOG = route.fields.TitleOG?.value,
    typeOG = route.fields.TypeOG?.value,
    urlOG = route.fields.UrlOG?.value,
    imageOG =
      imageFields?.src ||
      imageFields?.files?.find((file) => file.name === 'original')?.url ||
      imageFields?.files?.[0]?.url;

  title = browserTitle || pageTitle || route.name;

  const pelmorexEngageFrontScript = (
    <script
      type="text/javascript"
      src="https://dashboard.engagefront.com/objects/conversion.min.js"
      className="ENGAGE_FRONT"
      data-pixeldomain-value="https://engagefront.theweathernetwork.com"
      data-efdomain-value="EngageFront"
      data-conversion-id="66a92d1cc37e676c3eaf109f"
      data-conversion-type="advertiser"
      data-conversion-event-name="94e1a283_df99_4554_9d44_86e09e6059cd"
    />
  );

  const renderListTags = (context) => {
    let tags = context.map((tag, i) =>
      React.createElement(
        tag.type, // type
        { ...tag.props, ...{ key: i } }, // props
        tag.content // content
      )
    );
    return tags;
  };

  useEffect(() => {
    smoothScrolling();
  }, []);

  return (
    <React.Fragment>
      {/* react-helmet enables setting <head> contents, like title and OG meta tags */}
      <Helmet htmlAttributes={{ lang: lang }} title={title}>
        {metaDescription && <meta name="description" content={metaDescription} />}
        {metaDescription && <meta name="og:description" content={metaDescription} />}
        {metaKeywords && <meta name="keywords" content={metaKeywords} />}
        <link rel="canonical" href={canonical.endsWith('/') ? canonical.slice(0, -1) : canonical} />
        <link rel="icon" type="image/x-icon" href={context.favicon} />
        {(noIndex || noFollow) && (
          <meta
            name="robots"
            content={
              (noIndex ? 'NOINDEX' : '') +
              (noFollow && noIndex ? ', ' : '') +
              (noFollow ? 'NOFOLLOW' : '')
            }
          />
        )}
        {titleOG && <meta property="og:title" content={titleOG} />}
        {typeOG && <meta property="og:type" content={typeOG} />}
        {urlOG && <meta property="og:url" content={urlOG} />}
        {imageOG && <meta property="og:image" content={imageOG} />}
        {lang && <meta property="og:locale" content={lang === 'en' ? 'en_US' : lang} />}
        {context.datalayer && renderListTags(context.datalayer)}
        {googleScheme && <script type="application/ld+json">{googleScheme}</script>}
        {pelmorexEngageFrontScript}
      </Helmet>

      {/*
      VisitorIdentification is necessary for Sitecore Analytics to determine if the visitor is a robot.
      If Sitecore XP (with xConnect/xDB) is used, this is required or else analytics will not be collected for the JSS app.
      For XM (CMS-only) apps, this should be removed.

      VI detection only runs once for a given analytics ID, so this is not a recurring operation once cookies are established.
    */}
      <VisitorIdentification />
      {/* root placeholder for the app, which we add components to using route data */}
      <div id="maincontent" className="wrapper">
        <Navigation />
        <div>
          <Placeholder name="lockedtop" rendering={route} />
        </div>
        <div>
          <Placeholder name="lockedcontent" rendering={route} itemId={route.itemId} />
        </div>
        <div>
          <Placeholder name="lockedbottom" rendering={route} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default MainLayout;
