import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import useWindowSize from '../../../Foundation/Hooks/useWindowSize';
import { HeroMedia } from '../Hero Banner/ui';
import { withDatasourceCheck } from '../../../Foundation/HOC/withDatasourceCheck';
import { Text, Placeholder, getFieldValue } from '@sitecore-jss/sitecore-jss-react';

const SplitScreenBanner = ({ fields, rendering }) => {
  const { width } = useWindowSize();
  const imageOnTheLeft = !!fields?.ImageAlignment?.value;
  const contentColorVariantClass = fields?.ColorVariants?.fields?.Value?.value || 'hero-white';
  const isCorporatePlaceholders = rendering?.placeholders?.['herobanner-cta']?.some(
    (placeholder) => placeholder?.componentName === 'Hero Banner Find Residence Search Bar'
  );

  const [paddingTop, setPaddingTop] = useState(0);

  useEffect(() => {
    const navbar = document.querySelector('.main-nav');

    const handleResize = (entries) => {
      for (let entry of entries) {
        const { blockSize } = entry.borderBoxSize[0];
        setPaddingTop(blockSize);
      }
    };

    const observers = [];

    if (navbar) {
      const navbarObserver = new ResizeObserver((entries) => handleResize(entries));
      navbarObserver.observe(navbar);
      observers.push(navbarObserver);
    }

    return () => navbarObserver.disconnect();
  }, []);

  return (
    // adding padding-top to the container to avoid the content being hidden behind the fixed navbar
    <div style={{ paddingTop }} className="split-hero">
      <header
        className={clsx(
          'split-hero-container',
          width >= 1200
            ? isCorporatePlaceholders
              ? 'split-hero-corporate-height'
              : 'split-hero-residence-height'
            : 'fit-content',
          imageOnTheLeft && 'split-hero-reversed'
        )}
      >
        <div className={clsx('split-hero-content container', contentColorVariantClass)}>
          <div className="split-hero-content-wrapper">
            {fields?.EyebrowText && (
              <Text tag="h3" className="split-hero-eyebrow-text" field={fields.EyebrowText} />
            )}
            {fields?.Title && <Text tag="h2" className="split-hero-title" field={fields.Title} />}
            {fields?.Description && (
              <Text tag="p" className="split-hero-description" field={fields.Description} />
            )}
            <div
              className={clsx(
                'hero-ctas',
                isCorporatePlaceholders
                  ? 'split-hero-ctas-container-corporate'
                  : 'split-hero-ctas-container-residence'
              )}
            >
              <Placeholder name="herobanner-cta" rendering={rendering} />
            </div>
          </div>
        </div>
        <div className="split-hero-image">
          <HeroMedia
            image={getFieldValue(fields, 'Image')}
            imageMobile={getFieldValue(fields, 'ImageMobile')}
            imageTablet={getFieldValue(fields, 'ImageTablet')}
          />
        </div>
      </header>
    </div>
  );
};

export default withDatasourceCheck()(SplitScreenBanner);
