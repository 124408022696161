import { Form } from '@sitecore-jss/sitecore-jss-react-forms';
import { instanceOfValueFormField, getFieldValueFromModel } from '@sitecore-jss/sitecore-jss-forms';
import React from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { sitecoreApiHost, sitecoreApiKey } from '../../../../temp/config';
import { validateField } from './FieldValidator';
import { get } from '../../../Foundation/Utils';
import Cookies from 'js-cookie';
import createCustomFieldFactory from './customFieldFactory';
import { ReactComponent as LoadingIndicator } from '../../../../assets/icons/loading-indicator.svg';

export function blurHandler(field, fieldValue, callback) {
  if (fieldValue.length) {
    const validity = validateField(field, { value: fieldValue });
    callback(field.valueField.name, fieldValue, validity.valid, validity.error);
  }
}
const JssForm = ({ fields, itemId }) => {
  let history = useHistory();
  const { metadata } = fields;
  const [isLoading, setIsLoading] = React.useState(false);

  // override sitecore default submit handler has we need it to send some custom value
  class CustomSitecoreForm extends Form {
    getCurrentFieldState(field) {
      // non-valued fields, i.e. text, section, do not have a value or validity state
      if (!instanceOfValueFormField(field)) {
        return null;
      }

      const fieldName = field.valueField.name || null;

      if (!fieldName) {
        return null;
      }

      const fieldState = this.state[fieldName];

      // field has a value in react state i.e. due to user change
      if (fieldState) {
        const result = {
          isValid: fieldState.isValid,
          errors: fieldState.errors || [],
          defaultErrors: fieldState.defaultErrors || {},
        };

        if (typeof fieldState.value !== 'undefined') {
          // field state from changed field value (in this.state)
          result.value = fieldState.value;
        } else {
          result.value = getFieldValueFromModel(field);
        }

        return result;
      }

      // default state from form API model
      return {
        isValid: true,
        errors: [],
        value: getFieldValueFromModel(field),
        defaultErrors: {},
      };
    }
    onFieldChange(key, value, isValid, errors, defaultErrors) {
      const inputString = key;
      const regex = /Fields\[(.*?)\]/; // Regular expression to capture the value inside brackets
      const match = inputString.match(regex);
      let extractedValue = null;
      if (match && match[1]) {
        extractedValue = match[1];
        console.log('extractedValue', extractedValue); // Output: 51cc0cab-bdeb-41a9-bef8-9c4033b61f66
      }

      let fields = this.props.form.fields;
      for (let search = 0; search < fields.length; search++) {
        if (!fields[search].fields) continue;
        fields = fields.concat(fields[search].fields);
      }
      for (let index = 0; index < fields.length; index++) {
        const currentField = fields[index];
        if (!isValid && currentField.model.itemId === extractedValue) {
          console.log('currentField-isValid', currentField);
          currentField.model.cssClass += ' input-error';
        } else if (currentField.model.itemId === extractedValue) {
          currentField.model.cssClass = currentField.model.cssClass.replace('input-error', '');
        }
      }
      // console.log('field', field);
      this.setState({ [key]: { value, isValid, errors, defaultErrors } });
    }
    onButtonClick(buttonName) {
      const { antiForgeryToken } = this.props.form;
      const antiForgeryTokenField = {
        [antiForgeryToken.name]: antiForgeryToken.value,
      };
      let fields = this.props.form.fields;
      let isAllFieldValid = true;
      const currentState = { ...this.state };
      for (let search = 0; search < fields.length; search++) {
        if (!fields[search].fields) continue;
        fields = fields.concat(fields[search].fields);
      }
      this.setState({ submitButton: buttonName });

      for (let index = 0; index < fields.length; index++) {
        const currentField = fields[index];
        const currentFieldState = this.getCurrentFieldState(currentField);
        const fieldKey = get(currentField, 'valueField.name');
        if (!currentFieldState) continue;
        const validity = validateField(currentField, currentFieldState);
        if (!validity.isValid && isAllFieldValid) isAllFieldValid = false;
        currentState.formData = {
          ...currentState.formData,
          [currentField.model.name]: {
            isValid: validity.isValid,
            value: currentFieldState.value,
            errors: validity.error,
          },
        };
        currentState[fieldKey] = {
          value: currentFieldState.value,
          isValid: validity.isValid,
          errors: validity.error,
        };
        this.setState(currentState);
      }
      if (isAllFieldValid) {
        super.onButtonClick(buttonName);
      } else {
        const event = arguments[1];
        event.preventDefault();
      }
    }
  }
  const LabelComponent = (props) => {
    return (
      <label className={props.field.model.labelCssClass} htmlFor={props.field.valueField.id}>
        {props.children}
        {props.field.model.title}
      </label>
    );
  };
  const FieldErrorComponent = (props) => {
    return props.errors.map((error, index) => (
      <div className={`${props.className} field-validation-error`} key={index}>
        {error}
      </div>
    ));
  };

  const customFormFetcher = (options) => {
    return async (formData, endpoint) => {
      try {
        setIsLoading(true);
        const res = await fetch(endpoint, {
          body: formData.toMultipartFormData(),
          method: 'post',
          // IMPORTANT: Sitecore forms relies on cookies for some state management, so credentials must be included.
          credentials: 'include',
          // Browser set 'Content-Type' automatically with multipart/form-data; boundary
          ...options,
        });

        return await res.json();
      } catch (e) {
        setIsLoading(false);
        console.error(e);
      }
    };
  };

  const ErrorComponent = () => {
    return null;
  };

  let FormClassName = metadata.cssClass;
  if (isLoading) {
    FormClassName += ' form--loading';
  }
  return (
    <div className={FormClassName}>
      <div role="form" aria-label="Form" className={'form '}>
        <CustomSitecoreForm
          errorComponent={ErrorComponent}
          fieldValidationErrorsComponent={FieldErrorComponent}
          labelComponent={LabelComponent}
          fieldFactory={createCustomFieldFactory(itemId, fields)}
          form={fields}
          sitecoreApiHost={''}
          sitecoreApiKey={sitecoreApiKey}
          formFetcher={customFormFetcher({})}
          onRedirect={(url) => {
            if (url) window.location.href = url;
          }}
        />
        {isLoading && <LoadingIndicator className={'loading-indicator'} />}
      </div>
    </div>
  );
};
export default withRouter(JssForm);
