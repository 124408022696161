import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';

const HashTag = ({ link, text, target = '' }) => {
  if (!text || !link) {
    return null;
  }

  return (
    <div className="row">
      <div className="col-12 text-center">
        <a href={link} target={target}>
          {text}
        </a>
      </div>
    </div>
  );
};

export default HashTag;
