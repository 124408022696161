import React from 'react';
import { Style } from '../../../../../Foundation/Utils/stringUtils';

const tbResidenceValidator = ({ defaultEvent, contextitemId }) => {
  React.useEffect(() => {
    defaultEvent('TbResidenceValidator', contextitemId, true);
  }, [contextitemId]);
  return (
    <>
      <label htmlFor="TbResidenceValidator" className="hidden">
        Validator Hidden Field
      </label>
      <input
        id="TbResidenceValidator"
        name="TbResidenceValidator"
        className="input-validation-error"
        type="hidden"
        value={contextitemId}
        maxLength="255"
        placeholder=""
        data-sc-tracking="True"
        data-sc-field-name="Residences"
        data-val-required="Please select at least 1 residence."
        data-val="true"
        data-gaconnector-tracked="true"
        aria-required="true"
        aria-describedby="TbResidenceValidator-error"
        aria-invalid="true"
        style={Style('height: 1px !important; width: 0.10em !important; border: none;')}
      />
    </>
  );
};
export default tbResidenceValidator;
