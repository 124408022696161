import React from 'react';
import { isExperienceEditorActive, Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import { withDatasourceCheck } from '../../../Foundation/HOC/withDatasourceCheck';

const EmbeddedMedia = ({ fields }) => {
  if (isExperienceEditorActive()) {
    return (
      <div className="EmbeddedContainer">
        <p>In Experience Editor Editing mode it will only show the script for editing purposes</p>
        <Text tag="p" field={fields['Embedded Code']} />
      </div>
    );
  }

  return <RichText className="EmbeddedContainer" field={fields['Embedded Code']} />;
};

export default withDatasourceCheck()(EmbeddedMedia);
