import React, { useEffect } from 'react';
import {
  Placeholder,
  RichText,
  useSitecoreContext,
  isExperienceEditorActive,
} from '@sitecore-jss/sitecore-jss-react';
import { withDatasourceCheck } from '../../../Foundation/HOC/withDatasourceCheck';
import clsx from 'clsx';
import useWindowSize from '../../../Foundation/Hooks/useWindowSize';
import Asset from '../../../Foundation/DFS/Asset';

const overlayStyleClasses = {
  Faded: 'overlay-faded',
  Solid: 'overlay-solid',
};

const NewHeroBanner = ({ fields, rendering }) => {
  const { width } = useWindowSize();
  const { sitecoreContext } = useSitecoreContext();

  if (!fields) return;

  const {
    BackgroundImage,
    BackgroundImageMobile,
    Headline,
    ImageLocation,
    ImageOverlayStyle,
    Paragraph,
    BackgroundColor,
  } = fields;

  const overlayStyle = ImageOverlayStyle?.fields?.Value?.value || 'Solid';
  const imageLocation = ImageLocation?.fields?.Value?.value || 'Left';
  const bgColor = BackgroundColor?.fields?.Value?.value || '#F2F1ED';
  const gradientDirection = width <= 768 ? 'top' : imageLocation === 'Left' ? 'left' : 'right';

  useEffect(() => {
    const updateHeroImagePosition = () => {
      setTimeout(() => {
        const scElement = document.querySelector('#scCrossPiece');

        const height = scElement?.style.height;
        const imageEl = document.querySelector('.new-hero-container .bg-image');
        const blackOverlay = document.querySelector('.new-hero-container .black-overlay');

        if (imageEl) {
          imageEl.style.top = height;
        }

        if (blackOverlay) {
          blackOverlay.style.top = height;
        }
      }, 0);
    };

    if (
      (isExperienceEditorActive() || sitecoreContext?.pageState === 'preview') &&
      typeof document !== 'undefined'
    ) {
      let frameDocument;

      //  update hero position in the first render before clicking anything
      const intervalId = setInterval(() => {
        if (document.querySelector('#scCrossPiece')) {
          frameDocument = document.querySelector('#scWebEditRibbon')?.contentWindow.document;

          updateHeroImagePosition();
          frameDocument?.addEventListener('click', updateHeroImagePosition);
          clearInterval(intervalId);
        }
      }, 500);

      return () => frameDocument?.removeEventListener('click', updateHeroImagePosition);
    }
  }, []);

  return (
    <div
      className={clsx(
        'new-hero-container',
        overlayStyleClasses[overlayStyle],
        imageLocation === 'Right' && 'image-on-right'
      )}
    >
      <div className="black-overlay"></div>
      <div className="content" style={{ backgroundColor: bgColor }}>
        <div className="img-container">
          <Asset
            editor={true}
            field={width > 768 ? BackgroundImage : BackgroundImageMobile}
            aria-hidden="true"
            className="bg-image"
          />
          <div
            className={clsx('gradient-overlay', imageLocation === 'Right' && 'gradient-to-right')}
            style={
              overlayStyle === 'Solid'
                ? {}
                : {
                    background: `linear-gradient(to ${gradientDirection}, ${bgColor} 0%, rgba(242, 241, 237, 0.00) ${
                      width > 768 ? '40%' : '30%'
                    })`,
                  }
            }
          ></div>
        </div>
      </div>
      <div
        className="text-content-wrapper"
        style={{ backgroundColor: width > 768 ? 'transparent' : bgColor }}
      >
        <div
          className={clsx(
            'text-content',
            overlayStyle === 'Faded' && width > 768 && (imageLocation === 'Right' ? 'pr-0' : 'pl-0')
          )}
        >
          {!!Headline && <RichText field={Headline} className="richText-wrapper" />}
          {!!Paragraph && <RichText field={Paragraph} className="richText-wrapper paragraph" />}
          <div className="ctas-container">
            <Placeholder name="herobanner-cta" rendering={rendering} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withDatasourceCheck()(NewHeroBanner);
