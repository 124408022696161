import React from 'react';

const Button = ({ field, onButtonClick }) => {
  const clickHandler = (name, e) => {
    onButtonClick(name, e);
  };
  if (!field) {
    return <></>;
  }
  return (
    <button
      type="submit"
      value={field.model.title}
      name={field.buttonField.name}
      id={field.buttonField.id}
      className={`${field.model?.cssClassSettings?.cssClass} form--button`}
      onClick={(e) => clickHandler(field.buttonField.name, e)}
    >
      {field.model.title}
    </button>
  );
};

export default Button;
