import React from 'react';
import SVG from 'react-inlinesvg';
import { Link, Text } from '@sitecore-jss/sitecore-jss-react';
import Asset from '../../../../../Foundation/DFS/Asset';

const SocialLink = ({ icon = {}, link }) => (
  <Link aria-label={icon.description} field={link}>
    <Asset
      editor={false}
      field={icon}
      width={icon.value?.width ?? 32}
      height={icon.value?.height ?? 32}
    />
  </Link>
);

const FollowUsList = ({ title, links }) => {
  if (!links?.length) {
    return null;
  }
  return (
    <>
      <Text tag="div" field={{ value: title }} className="div-as-h2-nav" />
      <div className="social-links" title="social-links">
        {links.map(({ Link, Icon }, index) => (
          <React.Fragment key={index}>
            <SocialLink key={index} icon={Icon.value} link={Link} />{' '}
          </React.Fragment>
        ))}
      </div>
    </>
  );
};

export default FollowUsList;
