import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const ContactResidenceListing = ({ fields }) => {
  const data = Object.entries(fields);
  if (data.length > 0) {
    return (
      <div className="container-fluid navigation-panel p-0">
        <div className="container py-5">
          <div className="row justify-content-center text-center">
            {data.map(([i, item]) => (
              <div id="" className="col-lg-4 col-md-7 p-3" key={i}>
                <div role="main" aria-label="Content" className="main-content d-flex flex-column">
                  <div className="media">
                    <LazyLoadImage src={item.imageURL} />
                  </div>
                  <div className="content">
                    <Text tag="h2" field={{ value: item.name }} />
                    <p aria-label="Address">
                      {item.address}
                      {item.city || item.postalCode ? ',' : ''} {item.city} {item.postalCode}
                    </p>
                  </div>
                  <div className="d-flex flex-column justify-content-end flex-grow-1">
                    {item.residenceURL && (
                      <a
                        aria-label="Visit Site"
                        className="call2action btn"
                        href={item.residenceURL}
                      >
                        Visit Residence Site
                      </a>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default ContactResidenceListing;
