import React from 'react';
import { isExperienceEditorActive, Text, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { withDatasourceCheck } from '../../../Foundation/HOC/withDatasourceCheck';
import Asset from '../../../Foundation/DFS/Asset';

const NavigationPanel = ({ fields, rendering }) => (
  <div id={fields['Anchor ID'].value} className="col-lg-4 col-md-7 p-3">
    <div className="main-content d-flex flex-column @Model.StyleCode">
      <div className="media">
        <Asset editor={false} field={fields.Image} />
      </div>
      <div className="content">
        <Text tag="h2" field={fields.Title} />
        <Text tag="p" field={fields.Description} />
      </div>
      <div className="d-flex flex-column justify-content-end flex-grow-1">
        <Placeholder editor={isExperienceEditorActive()} name="phCTA" rendering={rendering} />
      </div>
    </div>
  </div>
);

export default withDatasourceCheck()(NavigationPanel);
