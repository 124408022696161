import React from 'react';
import SVG from 'react-inlinesvg';
import { ReactComponent as RightArrowIcon } from '../../../../assets/icons/right-arrow.svg';
import { trackingApi } from '@sitecore-jss/sitecore-jss-tracking';
import { sitecoreApiHost, sitecoreApiKey } from '../../../../temp/config';
import { withDatasourceCheck } from '../../../Foundation/HOC/withDatasourceCheck';
import {
  Text,
  withSitecoreContext,
  isExperienceEditorActive,
} from '@sitecore-jss/sitecore-jss-react';

async function dataFetcher(url, data) {
  return await fetch(url, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
}

const trackingApiOptions = {
  host: sitecoreApiHost,
  querystringParams: {
    sc_apikey: sitecoreApiKey,
  },
  fetcher: dataFetcher,
};

function registerTracking(name, type) {
  switch (type) {
    case 0:
      trackingApi
        .trackEvent([{ campaignId: name }], trackingApiOptions)
        .then(() => {
          console.log('Page event pushed');
        })
        .catch((error) => console.error(error));
      break;
    case 1:
      trackingApi
        .trackEvent([{ goalId: name }], trackingApiOptions)
        .then(() => console.log('Page event pushed'))
        .catch((error) => console.error(error));
      break;
    case 2:
      trackingApi
        .trackEvent([{ eventId: name }], trackingApiOptions)
        .then(() => console.log('Page event pushed'))
        .catch((error) => console.error(error));
      break;
    default:
      console.log('error registering tracking event/goal/campaign');
  }
}

function CTAClick(fields) {
  if (fields) {
    var fieldNames = ['Campaign', 'Goals', 'Events'];
    fieldNames.map((fieldName, index) => {
      var trackingDataArray = fields[fieldName];
      if (trackingDataArray !== null && trackingDataArray.length > 0) {
        trackingDataArray.map((trackingData) => {
          registerTracking(trackingData.displayName, index);
        });
      }
    });
  }

  if (fields.Link.value.href) {
    return 'location.href=' + fields.Link.value.href;
  }
  return '';
}

function getModalAttributeObject(fields) {
  var attributes = {};
  if (fields && fields['Is Calling Modal Form']?.value && fields['Modal Name']?.value) {
    attributes['data-target'] = '#' + fields['Modal Name']?.value;
    attributes['data-toggle'] = 'modal';
  }

  const assetField = fields.Asset?.value || fields.Asset;
  const assetLink =
    assetField.src ||
    assetField.asset?.files?.find((file) => file.name === 'original')?.url ||
    assetField.asset?.files?.[0]?.url;

  if (!fields.Link.value.href && assetLink) {
    attributes.download = '';
    attributes.target = '_blank';
  }
  return attributes;
}

function getAdditionalStyles(fields) {
  var extraClassNames = '';
  if (fields && fields['Additional Styles'] && fields['Additional Styles'].value !== '') {
    extraClassNames += fields['Additional Styles'].value + ' ';
  }
  if (fields && fields.Style?.fields?.Value?.value) {
    extraClassNames += fields.Style?.fields?.Value?.value;
  }
  return extraClassNames;
}

function BasicCTA(props) {
  var fields = props.fields;
  var extraClassNames = getAdditionalStyles(fields);
  var htmlAttributes = getModalAttributeObject(fields);
  const assetField = fields.Asset?.value || fields.Asset;
  const assetLink =
    assetField.src ||
    assetField.asset?.files?.find((file) => file.name === 'original')?.url ||
    assetField.asset?.files?.[0]?.url;

  if (
    fields &&
    (fields.Link.value.href ||
      assetLink ||
      (fields['Is Calling Modal Form']?.value && fields['Modal Name']?.value))
  ) {
    let clickUrl = fields.Link?.value?.href;
    let linkTarget = fields.Link?.value?.target;
    let arialabel = fields.AriaLabel?.value;
    if (fields['Is Calling Modal Form']?.value && fields['Modal Name']?.value) {
      clickUrl = 'javascript:;';
      linkTarget = '';
    } else if (clickUrl && fields['Is RSVP']?.value) {
      clickUrl += '?eventId=' + props?.sitecoreContext.itemId;
    } else if (!clickUrl) {
      clickUrl = assetLink;
    }

    return (
      <a
        className={'call2action btn ' + extraClassNames}
        aria-label={arialabel}
        href={clickUrl}
        target={linkTarget}
        onClick={() => {
          CTAClick(fields);
        }}
        {...htmlAttributes}
      >
        {fields.ICON && (
          <SVG
            src={fields?.ICON?.url}
            title={fields?.ICON?.fields?.Alt?.value}
            uniquifyIDs={true}
            preProcessor={(code) => code.replace(/id=".*?"/g, '')}
          />
        )}
        <Text tag="span" field={fields.Text} />
        <RightArrowIcon className="hidden split-hero-find-residences-cta-icon" />
      </a>
    );
  } else if (isExperienceEditorActive()) {
    return <h3 style={{ color: 'red', backgroundColor: 'yellow' }}>No Link</h3>;
  } else {
    return null;
  }
}

export default withSitecoreContext()(withDatasourceCheck()(BasicCTA));
