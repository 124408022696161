import FieldTypes from './ExtendedFieldTypes';

/* All fields to be customized (not using default Sitecore Field React Renderer) should be referenced here */
/* The elements will receive default props from sitecore form */
const componentsToOveride = new Map();
Object.keys(FieldTypes) &&
  Object.keys(FieldTypes).forEach((fieldType) => {
    try {
      const ExisistingComponent = require(`./${fieldType}`);
      componentsToOveride.set(fieldType, ExisistingComponent.default);
    } catch (err) {}
  });

export default componentsToOveride;
