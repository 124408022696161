import React from 'react';
import { Style } from '../../../../../Foundation/Utils/stringUtils';
import { getString } from '../index';

const tbGuestValidator = ({ defaultEvent, guestList }) => {
  const [value, setValue] = React.useState(getString(guestList));
  React.useEffect(() => {
    setValue(getString(guestList));
    defaultEvent(
      'tbGuestValidator',
      getString(guestList),
      true,
      [],
      [{ required: 'Please specify at least 1 guest.' }]
    );
  }, [guestList]);
  return (
    <input
      id="TbGuestValidator"
      readOnly="readonly"
      name="TbGuestValidator"
      className="input-validation-error"
      type="text"
      value={value}
      maxLength="255"
      placeholder=""
      data-sc-tracking="True"
      data-sc-field-name="Guest"
      data-val-required="Please specify at least 1 guest."
      data-val="true"
      data-gaconnector-tracked="true"
      aria-required="true"
      aria-describedby="TbGuestValidator-error"
      aria-invalid="true"
      style={Style('height: 1px !important; width: 0.10em !important; border:none')}
    />
  );
};
export default tbGuestValidator;
