import React, { useState, useEffect } from 'react';
import { Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { isMoreThanOneDay, GetDateRendering } from '../../../Foundation/Utils/eventUtils';
import { canUseDOM } from '../../../Foundation/Utils';
import Asset from '../../../Foundation/DFS/Asset';

function EventCard(event, index, CardWidthPercentage, isEditing) {
  const StartDate = new Date(event.fields['Start Date Time']?.value);
  let EndDate = new Date(event.fields['End Date Time']?.value);
  if (StartDate > EndDate) {
    EndDate = StartDate;
  }
  const MoreThanOneDay = isMoreThanOneDay(EndDate, StartDate);
  return (
    <li key={index} style={{ width: CardWidthPercentage }}>
      <article className="card">
        <a href={event.url}>
          <div className="card-image">
            <Asset editor={isEditing} field={event.fields.Image} />
          </div>
          <div className="card-body">
            <h3>{event.name}</h3>
            {GetDateRendering(MoreThanOneDay, StartDate, EndDate)}
          </div>
        </a>
        <div className="card-footer">
          <a href={event.url}>View Event</a>
        </div>
      </article>
    </li>
  );
}

const index = ({ fields, sitecoreContext }) => {
  const { datasource, events } = fields;
  const [pageNumber, setPageNumber] = useState(1);
  const [renderedEvents, setRenderedEvents] = useState([]);
  const [displayLoadMore, setDisplayLoadMore] = useState(true);
  const PageSize = datasource['Page Size']?.value !== '' ? datasource['Page Size'].value : 12;
  const isMobile = canUseDOM ? window.matchMedia('(max-width: 768px)').matches : false;
  const CardWidthPercentage = isMobile ? '100%' : 100 / datasource['Number In Row'].value + '%';
  const total = events.length;
  const isEditing = sitecoreContext && sitecoreContext.pageEditing;

  useEffect(() => {
    setRenderedEvents(events.slice(0, PageSize));
    setDisplayLoadMore(events.length > PageSize);
  }, []);

  function loadMore() {
    if ((pageNumber + 1) * PageSize >= total) {
      setDisplayLoadMore(false);
    }
    setPageNumber(pageNumber + 1);
    setRenderedEvents(events.slice(0, (pageNumber + 1) * PageSize));
  }

  if (fields && events && events.length > 0) {
    return (
      <section className="articles articles-page">
        <div className="container" role="main" aria-label="Cards">
          <ul id="div-events" className="articles-list">
            {renderedEvents.map((event, index) => {
              return EventCard(event, index, CardWidthPercentage, isEditing);
            })}
          </ul>
          {displayLoadMore && (
            <div className="articles-pagination" style={{ display: 'block' }}>
              <button
                id="media-hits-load-more"
                className="btn btn-outline-primary"
                aria-label="Content"
                onClick={() => loadMore()}
              >
                <Text field={datasource['View More Button']} />
              </button>
            </div>
          )}
        </div>
      </section>
    );
  }
  return (
    <section className="articles articles-page">
      <div className="container">
        <h3 style={{ textAlign: 'center' }}>No Ongoing Events Found</h3>
      </div>
    </section>
  );
};

export default withSitecoreContext()(index);
