import React from 'react';
import { checkSecondaryCategory, getSecondaryCategory } from '../../../../Foundation/Utils';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const ArticlesList = ({ articles, filterCategory, filterCategoryObject }) => {
  for (const [i, article] of articles.entries()) {
    if (article.IsFeaturedArticle === '1') {
      articles.splice(i, 1);
      articles.unshift(article);
      break;
    }
  }
  return (
    <ul id="blog-list" className="articles-list">
      {articles.map((article, i) => (
        <React.Fragment key={i}>
          {(filterCategory === '' ||
            article.PrimaryCategoryId === filterCategory ||
            checkSecondaryCategory(filterCategory, JSON.parse(article.SecondaryCategories))) && (
            <li
              className={`${article.CardType ? article.CardType : ''}${
                article.IsFeaturedArticle === '1' ? 'featured' : ''
              }`}
              key={i}
            >
              <article className="card">
                <a href={article.Url}>
                  <div role="complementary" aria-label="Article Card Image" className="card-image">
                    {article.ImageUrl && (
                      <LazyLoadImage src={`${article.ImageUrl}`} alt={article.ImageAlt} />
                    )}
                  </div>
                  <div role="complementary" aria-label="Article Card Content" className="card-body">
                    <h2 aria-label="Title">{article.Title}</h2>
                    <p aria-label="Teaser">{article.Teaser}</p>
                  </div>
                </a>
                <div className="card-footer">
                  <a
                    href={
                      filterCategoryObject[0] && filterCategoryObject[0].m_Item3
                        ? filterCategoryObject[0].m_Item3
                        : article.PrimaryCategoryUrl
                    }
                  >
                    {filterCategoryObject[0] && filterCategoryObject[0].m_Item2
                      ? filterCategoryObject[0].m_Item2
                      : article.PrimaryCategoryTitle}
                  </a>
                </div>
              </article>
            </li>
          )}
        </React.Fragment>
      ))}
    </ul>
  );
};
export default ArticlesList;
