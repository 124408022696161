import React from 'react';
import { Navbar as BootstapNavbar } from 'react-bootstrap';

const ariaId = 'mainMenu';

export const NavBar = ({ children }) => (
  <BootstapNavbar
    id="residence-navbar-nav"
    expand="lg"
    aria-label="Main Navigation"
    bsPrefix="residence-nav thank-you-nav main-nav"
  >
    {children}
  </BootstapNavbar>
);

export const Collapse = ({ children }) => (
  <BootstapNavbar.Collapse id={ariaId}>
    <ul className="menu-list" role="menu">
      {children}
    </ul>
  </BootstapNavbar.Collapse>
);

export const Toggle = () => (
  <BootstapNavbar.Toggle className="menu-toggler hamburger" aria-controls={ariaId}>
    <span className="hamburger-box">
      <span className="hamburger-inner" />
    </span>
  </BootstapNavbar.Toggle>
);

export default NavBar;
