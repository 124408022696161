import './stringUtils';

export function get(obj, propPath, defaultValue) {
  return getPropertyByPath(obj, propPath, defaultValue);
}

export function getPropertyByPath(obj, propPath, defaultValue) {
  return propPath.split('.').reduce((o, p) => (o && o[p]) || defaultValue, obj);
}

export function getCookie(cname) {
  let name = cname + '=';
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

export function checkSecondaryCategory(category, categoryArray) {
  if (category !== null && categoryArray !== null && categoryArray.length > 0) {
    for (let i = 0; i < categoryArray.length; i++) {
      if (categoryArray[i]?.ID === category) {
        return true;
      }
    }
  }

  return false;
}

export function getSecondaryCategory(category, categoryArray) {
  if (category !== null && categoryArray !== null && categoryArray.length > 0) {
    for (let i = 0; i < categoryArray.length; i++) {
      if (categoryArray[i]?.ID === category) {
        return categoryArray[i];
      }
    }
  }

  return null;
}

export const canUseDOM = !!(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
);

export const objToQueryString = (obj) => {
  let str = [];
  for (let p in obj) {
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
    }
  }
  return str.join('&');
};

export const isEmptyObject = (value) => {
  return Object.keys(value).length === 0 && value.constructor === Object;
};
export function qsToObj(qs) {
  if (!qs) {
    return {};
  }
  const hashes = qs.slice(qs.indexOf('?') + 1).split('&');
  const params = {};
  hashes.map((hash) => {
    const param = hash.split('=');
    const key = param.shift();
    const val = param.join('=');
    params[key] = decodeURIComponent(val);
  });
  return params;
}
export function addEventListenerList(list, event, fn) {
  for (var i = 0, len = list.length; i < len; i++) {
    list[i].addEventListener(event, fn, false);
  }
}
