import React, { useEffect, useState } from 'react';
import navJquery from '../../../Foundation/Utils/navJquery';
import { withDatasourceCheck } from '../../../Foundation/HOC/withDatasourceCheck';
import { Placeholder, isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';
import {
  Menu,
  Logo,
  Link,
  TopRow,
  NavBar,
  Toggle,
  Collapse,
  FollowUs,
  ContactUs,
  MainNavigation,
} from './ui';

const SolidHeader = ({ fields, rendering }) => {
  const {
    residencePhone,
    residenceAddress,
    residenceName,
    residenceCampaign,
    logoImage,
    logoUrl,
    logoDescription,
    topRowNavigation,
    mainNavigation,
    contactUs,
    followUs,
  } = fields;

  const [classNameRelative, setClassNameRelative] = useState('');

  useEffect(() => {
    if (isExperienceEditorActive()) {
      setClassNameRelative('not-absolute');
    } else {
      navJquery();
    }
  }, []);

  const showMenu = topRowNavigation?.length > 0 && mainNavigation?.length > 0;

  return (
    <div className={classNameRelative}>
      <NavBar>
        <TopRow links={topRowNavigation} teaser={residenceCampaign} />
        <div className="container">
          <Logo url={logoUrl} image={logoImage} description={logoDescription} />
          <Placeholder name="navcta" rendering={rendering} />
          <Menu show={showMenu}>
            <Toggle />
            <Collapse>
              <li role="menuitem" className="residence-phone">
                <a href={residencePhone ? 'tel:' + residencePhone : '#'}>{' ' + residencePhone}</a>
              </li>
              {Array.isArray(mainNavigation) &&
                mainNavigation?.map((item, key) => <MainNavigation item={item} key={key} />)}
              {Array.isArray(topRowNavigation) &&
                topRowNavigation?.map((item, key) => (
                  <li role="menuitem" key={key} className="d-sm-none">
                    <Link item={item} />
                  </li>
                ))}
              <li role="menuitem" className="d-lg-none text-center footer-content">
                <ContactUs
                  title={contactUs?.Title}
                  name={residenceName}
                  address={residenceAddress}
                />
                <FollowUs links={followUs?.links} title={followUs?.title} />
              </li>
            </Collapse>
          </Menu>
          <Placeholder name="navsearchbox" rendering={rendering} />
        </div>
      </NavBar>
      <div>
        <Placeholder name="navcomponents" rendering={rendering} />
      </div>
    </div>
  );
};

export default withDatasourceCheck()(SolidHeader);
