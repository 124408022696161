import React from 'react';
import clsx from 'clsx';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { ReactComponent as PhotoIcon } from '../../../../assets/icons/photo.svg';
import { SRLWrapper, useLightbox } from 'simple-react-lightbox';
import { withDatasourceCheck } from '../../../Foundation/HOC/withDatasourceCheck';

function getAdditionalStyles(fields) {
  var extraClassNames = '';
  if (fields['Additional Styles'] && fields['Additional Styles'].value !== '') {
    extraClassNames += fields['Additional Styles'].value + ' ';
  }
  if (fields.Style?.fields?.Value?.value) {
    extraClassNames += fields.Style?.fields?.Value?.value;
  }
  return extraClassNames;
}

const GalleryCTA = ({ fields }) => {
  const { openLightbox } = useLightbox();
  var extraClassNames = getAdditionalStyles(fields);
  const images = fields['Gallery Images']?.value?.assets?.map((img) => {
    return {
      src: img?.files?.[0]?.url,
      caption: img?.description,
    };
  });
  const hideInMobile = fields['Hide In Mobile'];
  const label = fields.Label;
  const style = getAdditionalStyles(fields);
  const options = {
    caption: {
      showCaption: false,
    },
    thumbnails: {
      showThumbnails: false,
    },
    buttons: {
      showAutoplayButton: false,
      showCloseButton: false,
      showDownloadButton: false,
      showFullscreenButton: false,
      showNextButton: true,
      showPrevButton: true,
    },
  };

  return (
    <div className={clsx(hideInMobile?.value && 'hide-cta-in-mobile')}>
      <button
        className={clsx('call2action', 'btn', 'btn-light', style)}
        id="openGallery"
        aria-label={label?.value && label.value}
        onClick={() => openLightbox()}
      >
        <PhotoIcon /> {label?.value && label.value}
      </button>
      <SRLWrapper elements={images} options={options}></SRLWrapper>
    </div>
  );
};

export default withDatasourceCheck()(GalleryCTA);
