import React from 'react';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import thankyoucheckmark from '../../../../assets/icons/thank-you-checkmark.png';
import { withDatasourceCheck } from '../../../Foundation/HOC/withDatasourceCheck';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const BookaVisit = ({ fields }) => (
  <div className="thank-you-container">
    <div role="main" aria-label="Content" className="container">
      <div className="thank-you top-spacing-for-thank-you-message">
        <LazyLoadImage
          className="thank-you-checkmark"
          src={thankyoucheckmark}
          alt="Thank you Checkmark Icon"
        />
        &emsp;&emsp;
        <p id="thankyoumessage" style={{ margin: '0em !important' }}>
          <RichText field={fields['Thank you message']} />
        </p>
      </div>
      <div className="bottom-spacing-for-thank-you-message">
        <hr className="thank-you-hr" />
      </div>
    </div>
  </div>
);

export default withDatasourceCheck()(BookaVisit);
