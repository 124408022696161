import React, { useState, useEffect } from 'react';
import { RichText, isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/notification-banner-close.svg';

// Utility functions to handle cookies
const setCookie = (name, value, hours) => {
  const date = new Date();
  date.setTime(date.getTime() + hours * 60 * 60 * 1000); // Set expiration time
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${name}=${value};${expires};path=/`;
};

const getCookie = (name) => {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i].trim();
    if (c.indexOf(nameEQ) === 0) {
      return c.substring(nameEQ.length, c.length);
    }
  }
  return null;
};

const NotificationBanner = ({ fields }) => {
  const isEditing = isExperienceEditorActive();
  const bannerId = fields?.BannerId?.value || '';
  const cookieName = 'bannerClosed' + (bannerId ? `_${bannerId}` : '');
  const [isBannerVisible, setIsBannerVisible] = useState(false);

  const addSpacings = () => {
    document.body.classList.add('notification-banner-open');
    document.body.style.marginTop = isEditing ? '80px' : '0px';
  };

  const removeSpacings = () => {
    document.body.classList.remove('notification-banner-open');
    document.body.style.marginTop = '0px';
  };

  const handleCloseBanner = () => {
    setIsBannerVisible(false);
    setCookie(cookieName, 'true', 12); // Set cookie with 12-hour expiration
    removeSpacings();
  };

  const handleContentClick = (event) => {
    const link = event.currentTarget.querySelector('a');
    if (link) window.location.href = link.href;
  };

  useEffect(() => {
    const isBannerClosed = getCookie(cookieName);
    setIsBannerVisible(bannerId && isBannerClosed !== 'true');
  }, [cookieName]);

  useEffect(() => {
    if (isBannerVisible) addSpacings();
    else removeSpacings();

    return () => removeSpacings();
  }, [isBannerVisible, isEditing]);

  if (!isBannerVisible || !fields?.Text) return null;

  return (
    <div id="notificationBanner" className="notification-banner">
      <div className="notification-content" onClick={handleContentClick}>
        <RichText tag="p" field={fields?.Text} />
      </div>
      <CloseIcon className="close-btn" onClick={handleCloseBanner} />
    </div>
  );
};

export default NotificationBanner;
