import React from 'react';
import { ReactComponent as ChevronDownIcon } from '../../../../../../assets/icons/chevron-down.svg';

const DownButton = ({ scrollDownText }) => {
  const handleClick = (e) => {
    const elementToScroll = document.getElementById('hero-banner').nextElementSibling;

    if (elementToScroll) {
      elementToScroll.scrollIntoView({ behavior: 'smooth' });
    } else {
      const heightOfHero = document.getElementById('hero-banner').clientHeight || 0;
      window.scrollTo({
        top: heightOfHero,
        left: 0,
        behavior: 'smooth',
      });
    }
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <a className="hero-scroll-down" href="#" aria-label={scrollDownText} onClick={handleClick}>
      <span className="hidden">{scrollDownText}</span>
      <ChevronDownIcon />
    </a>
  );
};

export default DownButton;
