import React from 'react';
import Asset from '../../../../../Foundation/DFS/Asset';

const NavLink = ({ item }) => {
  const { Icon, href, text } = checkItem(item);

  return Icon?.value?.url ? (
    <a href={href}>
      <Asset
        editor={false}
        field={Icon}
        width={Icon.value?.width ?? 25}
        height={Icon.value?.height ?? 25}
      />
    </a>
  ) : (
    <a href={href}>{text}</a>
  );
};

const checkItem = (item) => {
  try {
    const {
      Icon,
      Link: {
        value: { href, text },
      },
      Style,
    } = item;
    return { Icon, href, text, Style };
  } catch (err) {
    return { Icon: {}, href: '', text: '', Style: {} };
  }
};

export default NavLink;
