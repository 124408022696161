import React, { useEffect, useState } from 'react';
import { isExperienceEditorActive, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { ContactUs, FollowUsList, Logo, Navbar, NavLink, Menu } from './ui';
import { withDatasourceCheck } from '../../../Foundation/HOC/withDatasourceCheck';
import navJquery from '../../../Foundation/Utils/navJquery';

const MainHeader = ({ fields, rendering }) => {
  useEffect(() => {
    if (!isExperienceEditorActive()) {
      navJquery();
    }
  }, []);

  return (
    <div>
      <Navbar>
        <Logo
          linkUrl={fields?.logoUrl}
          imageUrl={fields?.logoImage}
          description={fields?.logoDescription}
        />
        <Menu>
          <Navbar.Toggle />
          <Navbar.Collapse>
            {fields.mainNavigation?.map((el) => (
              <NavLink key={el.id} link={el} />
            ))}
            <Navbar.Footer>
              <ContactUs
                title={fields?.contactUs?.Title}
                officeAddress={fields?.contactUs?.['Office Address']}
                phoneNumber={fields?.contactUs?.['Phone Number']}
              />
              <FollowUsList title={fields.followUs?.title} links={fields.followUs?.links} />
            </Navbar.Footer>
          </Navbar.Collapse>
        </Menu>
        <Placeholder name="navcta" rendering={rendering} />
        <Placeholder name="navsearchbox" rendering={rendering} />
      </Navbar>
      <Placeholder name="navcomponents" rendering={rendering} />
    </div>
  );
};

export default withDatasourceCheck()(MainHeader);
