import { objToQueryString } from '../Utils';
import { fetchData } from '../Utils/fetchData';

const getCludoResults = async ({ query, numberOfResults, page }) => {
  const queryString = objToQueryString({ numberOfResults, page, query });
  const urlString = `amica/api/searchservice/getcludosearchjson?${queryString}`;
  const result = await fetchData(urlString);
  return JSON.parse(result);
};

export default { getCludoResults };
