import React, { useState } from 'react';
import { ReactComponent as SearchIcon } from '../../../../assets/icons/search.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg';
import { getPropertyByPath } from '../../../Foundation/Utils';
import { useHistory } from 'react-router-dom';
import { withDatasourceCheck } from '../../../Foundation/HOC/withDatasourceCheck';

const NavigationSearchBox = ({ fields }) => {
  let history = useHistory();
  const [expanded, setExpanded] = useState(false);
  const placeholderText = getPropertyByPath(fields, 'Placeholder Text.value');
  const searchButtonTitle = getPropertyByPath(fields, 'Search Button Title.value');
  const teaser = getPropertyByPath(fields, 'Teaser.value');
  const title = getPropertyByPath(fields, 'Title.value');
  const accessibilityLabel = getPropertyByPath(fields, 'Accessibility Label.value');
  const resultsPage = getPropertyByPath(fields, 'Results Page.url');
  const name = 'srquery';
  const [value, setValue] = useState('');
  const onSubmit = (e) => {
    e.preventDefault();
    setExpanded(false);
    window.location.href = `${resultsPage}?${name}=${value}`;
  };
  return (
    <form onSubmit={onSubmit} method="post" id={'NavigationSearchBox'}>
      <div className={'search'}>
        <button
          className="search-open"
          aria-label="Open search form"
          type="button"
          aria-controls="headerSearch"
          aria-expanded={expanded}
          onClick={() => setExpanded(!expanded)}
        >
          <SearchIcon />
        </button>
        <div id="headerSearch" className={`search-box  ${expanded ? 'show' : ''}`}>
          <button
            className="search-close"
            aria-label="Close search form"
            type="button"
            aria-controls="headerSearch"
            onClick={() => setExpanded(!expanded)}
            aria-expanded="false"
          >
            <CloseIcon />
          </button>
          <p className="search-title">{title}</p>
          {teaser}
          <div className="input-group">
            <label id="lblSearch" htmlFor="sbquery" className="sr-only">
              {accessibilityLabel}
            </label>
            <input
              aria-labelledby="lblSearch"
              className="form-control"
              id="sbquery"
              name="sbquery"
              placeholder={placeholderText}
              type="text"
              value={value}
              onChange={(e) => setValue(e.target.value)}
              data-gaconnector-tracked="true"
            />
            <br />

            <div className="input-group-append">
              <button type="submit" className="btn" aria-label="Submit">
                <SearchIcon />
              </button>
            </div>
          </div>
          <button type="submit" className="btn btn-outline-primary">
            {searchButtonTitle}
          </button>
        </div>
      </div>
    </form>
  );
};

export default withDatasourceCheck()(NavigationSearchBox);
