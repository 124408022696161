import React, { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import grayIcon from '../../../../../../assets/icons/gray-marker.png';
import highligtedGrayIcon from '../../../../../../assets/icons/highlighted-marker.png';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const styles = [
  {
    elementType: 'geometry',
    stylers: [
      {
        color: '#f5f5f5',
      },
    ],
  },
  {
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#616161',
      },
    ],
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#f5f5f5',
      },
    ],
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#bdbdbd',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [
      {
        color: '#eeeeee',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [
      {
        color: '#e5e5e5',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [
      {
        color: '#ffffff',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [
      {
        color: '#dadada',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#616161',
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
  {
    featureType: 'transit.line',
    elementType: 'geometry',
    stylers: [
      {
        color: '#e5e5e5',
      },
    ],
  },
  {
    featureType: 'transit.station',
    elementType: 'geometry',
    stylers: [
      {
        color: '#eeeeee',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: '#c9c9c9',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
];
const icon = (maps) => ({
  url: grayIcon,
  scaledSize: new maps.Size(70, 50),
  origin: new maps.Point(0, 0),
  anchor: new maps.Point(0, 0),
});
const highligtedIcon = (maps) => ({
  url: highligtedGrayIcon,
  scaledSize: new maps.Size(70, 50),
  origin: new maps.Point(0, 0),
  anchor: new maps.Point(0, 0),
});
const map = ({
  markersData,
  setMarkersHandler,
  setMarkerIconHandler,
  setHighligtedMarkerIconHandler,
  markerIcon,
  highligtedMarkerIcon,
}) => {
  const { sitecoreContext } = useSitecoreContext();
  const geocodingKey = sitecoreContext.geocodingKey;
  const [mapObject, setMapobject] = useState('');
  const [mapsObject, setMapsobject] = useState('');
  const defaultCenter = {
    lat: parseFloat(markersData[0].Coordinates.lat),
    lng: parseFloat(markersData[0].Coordinates.lng),
  };
  const getMapBounds = (map, maps, locations) => {
    const bounds = new maps.LatLngBounds();

    locations.forEach((location) => {
      bounds.extend(new maps.LatLng(location.Coordinates.lat, location.Coordinates.lng));
    });
    return bounds;
  };
  const mapHandler = (map, maps) => {
    setMapobject(map);
    setMapsobject(maps);
    maps.event.addListener(map, 'zoom_changed', function () {
      var zoomChangeBoundsListener = maps.event.addListener(map, 'bounds_changed', function () {
        if (this.getZoom() > 14) {
          this.setZoom(14);
        }
        maps.event.removeListener(zoomChangeBoundsListener);
      });
    });
    renderMarkers(map, maps);
  };
  const renderMarkers = (map, maps) => {
    const defaultIcon = icon(maps);
    const highligted = highligtedIcon(maps);
    if (!markerIcon) {
      setMarkerIconHandler(defaultIcon);
    }
    if (!highligtedMarkerIcon) {
      setHighligtedMarkerIconHandler(highligted);
    }
    let markersList = [];
    markersData.forEach((location, i) => {
      let marker = new maps.Marker({
        id: location.Id,
        position: {
          lat: parseFloat(location.Coordinates.lat),
          lng: parseFloat(location.Coordinates.lng),
        },
        title: location.Name,
        isActive: false,
        isHover: false,
        icon: defaultIcon,
        map,
      });
      marker.addListener('click', () => {
        marker.setIcon(marker.isActive ? defaultIcon : highligted);
        marker.isActive = !marker.isActive;
        marker.isHover = true;
        markersList.some((m) => {
          if (m.id === location.Id) {
            m.isActive = marker.isActive;
          }
        });
        setMarkersHandler([...markersList]);
      });
      marker.addListener('mouseover', () => {
        marker.setIcon(highligted);

        markersList.some((m) => {
          if (m.id === location.Id) {
            m.isHover = true;
          }
        });
        setMarkersHandler([...markersList]);
      });
      marker.addListener('mouseout', () => {
        marker.setIcon(marker.isActive ? highligted : defaultIcon);

        markersList.some((m) => {
          if (m.id === location.Id) {
            m.isHover = false;
          }
        });
        setMarkersHandler([...markersList]);
      });
      markersList.push(marker);
    });
    const bounds = getMapBounds(map, maps, markersData);

    map.fitBounds(bounds);
    setMarkersHandler(markersList);
  };
  useEffect(() => {
    if (mapObject && mapsObject) {
      renderMarkers(mapObject, mapsObject);
    }
  }, [markersData]);

  return (
    <div id="residences-map" className="res-select-map map map-small">
      <div style={{ height: '100%', width: '100%' }} aria-hidden="true">
        <GoogleMapReact
          bootstrapURLKeys={{ key: geocodingKey }}
          defaultZoom={14}
          options={{
            styles: styles,
            zoomControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
          }}
          center={defaultCenter}
          onGoogleApiLoaded={({ map, maps }) => mapHandler(map, maps)}
          yesIWantToUseGoogleMapApiInternals={true}
        />
      </div>
    </div>
  );
};
export default map;
