import React, { useEffect, useLayoutEffect } from 'react';
import { Style } from '../../../../Foundation/Utils/stringUtils';
import {
  FieldProps,
  ValueFieldProps,
  ListFieldProps,
} from '@sitecore-jss/sitecore-jss-react-forms';
// elements
import GuestsHidden from './components/guestsHidden';
import TbGuestValidator from './components/tbGuestValidator';
import ListElement from './components/listElement';

// icons
import AddIcon from '../../../../../assets/icons/Add.ico';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// helps functions
export const getString = (guestList = []) => {
  return guestList.reduce((total, g, i) => {
    if (g.firstName.length && g.lastName.length) {
      return total + g.firstName + '|' + g.lastName + (guestList.length > i + 1 ? '|' : '');
    }
    return total;
  }, '');
};
const GuestList = (props) => {
  const { fields, errors, defaultEvent, FieldErrorComponent } = props;
  const [guestList, setGuestList] = React.useState([{ id: 0, firstName: '', lastName: '' }]);
  const [currentId, setCurrentId] = React.useState(1);
  const [show, setShow] = React.useState(false);
  if (!fields) {
    return <></>;
  }
  useEffect(() => {
    if (!show) {
      defaultEvent(fields.valueField.name, '', true);
    }
  }, [show]);
  useEffect(() => {
    return props.globalFormFields.some((i, index) => {
      if (i.model.name === 'will-anyone-joining-you' && i.model.value) {
        if (i.model.value[0] === 'Yes') {
          setShow(true);
        } else {
          setShow(false);
        }
      }
    });
  }, [props]);
  return (
    show && (
      <React.Fragment>
        <GuestsHidden guestList={guestList} fields={fields} defaultEvent={defaultEvent} />
        <div className="container">
          <div className="row">
            <div className="col-sm-12 text-center">
              <h4>Who will be joining you?</h4>
              <div className="wrapper" style={Style('position: relative;')}>
                <div className="navi"></div>
                <div
                  id="infoi"
                  style={Style('position: absolute; top: 0;  right: 0; display: none;')}
                >
                  <TbGuestValidator guestList={guestList} defaultEvent={defaultEvent} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="guest-list-element" className="container">
          <ListElement
            defaultEvent={defaultEvent}
            setGuestList={setGuestList}
            guestList={guestList}
          />
        </div>
        <div className="container">
          <div className="guest-footer">
            <div className="guest-footer-button">
              <a
                id="add-button"
                className={'guest-footer-link'}
                onClick={() => {
                  const newGuest = {
                    id: currentId,
                    firstName: '',
                    lastName: '',
                  };
                  setGuestList([...guestList, newGuest]);
                  setCurrentId(currentId + 1);
                }}
              >
                <LazyLoadImage src={AddIcon} alt="" />
                <> </>
                <span className="guest-footer-text">Add a companion</span>
              </a>
            </div>
          </div>
          <FieldErrorComponent errors={errors} className={'validation-text'} />
        </div>
      </React.Fragment>
    )
  );
};

export default GuestList;
