import { fetchData, encodeQueryData } from '../Utils/fetchData';

const getCategories = (params) => {
  const url = `amica/api/mediahitssearch/getfilters/?${encodeQueryData(params)}`;
  return fetchData(url);
};
const getMediaHits = (params) => {
  const url = `amica/api/mediahitssearch/getmediahits/?${encodeQueryData(params)}`;
  return fetchData(url, { data: params });
};

export default { getCategories, getMediaHits };
