import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';

const ContactUs = ({ title, name, address }) => {
  if (!title || !name || !address) {
    return null;
  }

  return (
    <>
      <Text tag="div" className="div-as-h2-nav" field={title} />
      <p>
        {name}
        <br />
        <span dangerouslySetInnerHTML={{ __html: address }} />
        <br />
      </p>
    </>
  );
};

export default ContactUs;
