import React from 'react';

const SelectCities = (data) => {
  const {
    ids,
    value = null,
    option,
    handler = () => {},
    selected = false,
    reload = false,
    values,
    labelText,
  } = data;
  React.useEffect(() => {
    if (reload && (selected.length || option[0].Value)) {
      handler(getFirstOption().Value);
    }
  }, [value, option]);

  const getFirstOption = () => {
    const items = option.find((item) => {
      return value === item.ParentId && item.Value === selected;
    });

    return items;
  };
  return (
    <>
      <label htmlFor={ids} className="sr-only">
        {labelText}
      </label>
      <select
        id={ids}
        className="custom-select form-control"
        onChange={(e) => {
          handler(e.target.value);
          values('');
        }}
        defaultValue={selected}
      >
        {option.map((item, index) => {
          if (value && value !== item.ParentId) {
            return null;
          } else if ((value && value === item.ParentId) || !value) {
            return (
              <option value={item.Value} key={index}>
                {item.Text}
              </option>
            );
          }
        })}
      </select>
    </>
  );
};
export default SelectCities;
