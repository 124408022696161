import React from 'react';
import RemoveIcon from '../../../../../../assets/icons/Remove.ico';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const TemplateElement = ({ guest, guestList, setGuestList }) => {
  return (
    <div name="guest-row" className="guest-row row">
      <div className="guest-text-field">
        <input
          name="name"
          className="form-control"
          type="text"
          placeholder="First name"
          value={guest.firstName}
          onChange={(e) => {
            guestList.some((g) => {
              if (g.id === guest.id) {
                g.firstName = e.target.value;
              }
            });
            setGuestList([...guestList]);
          }}
        />
      </div>
      <div className="guest-text-field">
        <input
          name="last-name"
          className="form-control"
          type="text"
          placeholder="Last name"
          value={guest.lastName}
          onChange={(e) => {
            guestList.some((g) => {
              if (g.id === guest.id) {
                g.lastName = e.target.value;
              }
            });
            setGuestList([...guestList]);
          }}
        />
      </div>
      {guestList.length === 1 ? null : (
        <div name="remove-button-field" className="remove-button-field">
          <a
            className={'guest-footer-link'}
            name="remove-button"
            onClick={() => {
              const newList = guestList;
              newList.splice(
                newList.findIndex(function (i) {
                  return i.id === guest.id;
                }),
                1
              );
              setGuestList([...newList]);
            }}
          >
            <LazyLoadImage
              className="remove-button-img"
              id="image-remove-button"
              src={RemoveIcon}
            />
            <> </>
            <span className="remove-companion-div">Remove companion</span>
          </a>
        </div>
      )}
    </div>
  );
};
const listElement = ({ defaultEvent, guestList, setGuestList }) => {
  return guestList.map((e, i) => {
    return <TemplateElement key={i} guest={e} guestList={guestList} setGuestList={setGuestList} />;
  });
};
export default listElement;
