import React, { useEffect } from 'react';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import Carousel from 'react-bootstrap/Carousel';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { FieldEditFrame } from './FieldEditFrame';
import { withDatasourceCheck } from '../../../Foundation/HOC/withDatasourceCheck';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const sanitizeTextSpace = (string) => string.replace(/(&nbsp;)/g, ' ');

const TestimonialPanel = ({ fields }) => {
  const [width, setWidth] = React.useState(769);
  const [index, setIndex] = React.useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    setWidth(window.innerWidth);
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);
  const isMobile = width <= 768;
  const slides = fields['Testimonial Slides'].slice(0, 3); // code from testimonal.cshtml only ever displays 3 testimonials

  if (fields && slides.length > 0) {
    return (
      <Carousel
        role="complementary"
        aria-label="Panel"
        id="carousel-full"
        activeIndex={index}
        onSelect={handleSelect}
        controls={false}
      >
        {slides.map((slide, index) => {
          slide.fields.Description.value = sanitizeTextSpace(slide.fields.Description.value);
          const ColorBackgroundImage = slide.fields['Color Background Image'];
          return (
            <Carousel.Item key={slide.id} className="background-carousel">
              <FieldEditFrame itemId={slide.id} fields={slides.fields}>
                <section
                  id=""
                  className="carouselTestimonal small-diff"
                  style={{
                    height: '450px',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundColor: '#e6ebef',
                    backgroundImage: `url(${ColorBackgroundImage?.value?.url})`,
                    backgroundPosition: 'inherit',
                  }}
                >
                  <Row>
                    <Col
                      style={{
                        position: 'relative',
                        width: '50%',
                        height: '450px',
                      }}
                    >
                      <Carousel.Caption style={{ height: '450px' }}>
                        {slide.fields.Title.value !== '' && (
                          <Text tag="h2" field={slide.fields.Title} />
                        )}
                        <RichText
                          className="description"
                          style={{ marginBottom: '20px' }}
                          field={slide.fields.Description}
                        />
                        <RichText field={slide.fields.Byline} />

                        {slide.fields['CTA Text'].value !== '' && (
                          <Button
                            className="ctaButtonCarousel"
                            href={slide.fields['CTA Link'].value.href}
                            variant="outline-primary"
                          >
                            <Text field={slide.fields['CTA Text']} />
                          </Button>
                        )}
                      </Carousel.Caption>
                    </Col>
                    {!isMobile && (
                      <Col
                        style={{
                          position: 'relative',
                          maxHeight: '450px',
                          width: '50%',
                          WebkitMaskImage:
                            '-webkit-linear-gradient(right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0) 100%)',
                          textAlign: 'right',
                        }}
                      >
                        {slide?.fields?.['Background Image']?.value?.url && (
                          <LazyLoadImage
                            className="carousel-backgroundimage"
                            src={slide?.fields?.['Background Image']?.value?.url ?? ''}
                            alt={slide?.fields?.['Background Image']?.value?.description ?? ''}
                          />
                        )}
                      </Col>
                    )}
                  </Row>
                </section>
              </FieldEditFrame>
            </Carousel.Item>
          );
        })}
      </Carousel>
    );
  } else {
    return null;
  }
};

export default withDatasourceCheck()(TestimonialPanel);
