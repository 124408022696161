import React from 'react';
import { withDatasourceCheck } from '../../../Foundation/HOC/withDatasourceCheck';
import { getFieldValue, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import VideoAsset from '../../../Foundation/DFS/VideoAsset';

const FullPanelVideo = ({ fields, sitecoreContext }) => {
  const isEditing = sitecoreContext && sitecoreContext.pageEditing;
  const AnchorID = getFieldValue(fields, 'Anchor ID');
  const AutoPlay = getFieldValue(fields, 'Auto Play') === true ? 'autoplay' : '';
  const Mute = getFieldValue(fields, 'Mute') === true ? 'muted' : '';
  const Loop = getFieldValue(fields, 'Auto Loop') === true ? 'loop' : '';
  const AddSubTitle = getFieldValue(fields, 'Display Subtitles') === true ? 'subtitles' : '';
  const Video = getFieldValue(fields, 'BFS Asset');
  const VideoUrl = Video?.src;
  const VideoType = 'video/mp4';

  if (isEditing) {
    return (
      <div id={AnchorID} className="video-panel">
        <div className="video-panel-experience-editor">
          {VideoUrl ? (
            <p>Full Panel Video: selected video: {Video?.name}</p>
          ) : (
            <p>Full Panel Video: please select a video:</p>
          )}
          <VideoAsset field={fields['BFS Asset']} />
        </div>
      </div>
    );
  } else {
    if (VideoUrl) {
      return (
        <div
          id={AnchorID}
          role="complementary"
          aria-label="Video"
          className="video-panel"
          dangerouslySetInnerHTML={{
            __html: `
              <video
              ${Loop}
              ${Mute}
              ${AutoPlay}
              ${AddSubTitle}
              controls><source src="${VideoUrl}" type="${VideoType}" /></video>
            `,
          }}
        ></div>
      );
    } else {
      return null;
    }
  }
};

export default withSitecoreContext()(withDatasourceCheck()(FullPanelVideo));
