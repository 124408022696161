import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const HitsList = (props) => {
  const { articles, filteredCategory, hideFilter } = props;

  const favoriteCard = (i) => {
    switch (i) {
      case 0:
        return 'first-media-card';
      case 1:
        return 'second-media-card';
      case 2:
        return 'third-media-card';
      default:
        return '';
    }
  };
  return (
    <ul id="media-hits-list" className="articles-list">
      {articles.map((article, i) => (
        <li key={i} className={favoriteCard(i)}>
          <article className="card">
            <a target={article.CTALinkTarget} href={article.CTALink}>
              {article.BackgroundImageURL?.length && (
                <div className="card-image">
                  <LazyLoadImage
                    src={article.BackgroundImageURL}
                    alt={article.BackgroundImageAlt}
                  />
                </div>
              )}
              <div className="card-body">
                <h2
                  name="card-title"
                  className={`${
                    !article.BackgroundImageURL && (i === 0 || i === 1 || i === 2)
                      ? 'media-card-title'
                      : ''
                  }`}
                >
                  {article.Title}
                </h2>
                <p
                  dangerouslySetInnerHTML={{
                    __html: article.Description,
                  }}
                ></p>
              </div>
            </a>
            <div className="card-footer">
              <div className="row">
                <div className="col-6">
                  {!hideFilter && filteredCategory && (
                    <a href={filteredCategory.Url}>{filteredCategory.Name}</a>
                  )}
                </div>
                <div className="col-6 text-right">
                  <a>{article.PublishDate.replaceAll('-', '/')}</a>
                </div>
              </div>
            </div>
          </article>
        </li>
      ))}
    </ul>
  );
};
export default HitsList;
