import React from 'react';
import GoogleMap from '../../../../../Foundation/components/GoogleMap';

import { generateLocationInnerHTML } from '../../helpers';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const Map = ({ locations }) => {
  const { sitecoreContext } = useSitecoreContext();
  const geocodingKey = sitecoreContext.geocodingKey;
  const defaultCenter = {
    lat: parseFloat(locations[0].coordinates.lat),
    lng: parseFloat(locations[0].coordinates.lng),
  };
  const getMapBounds = (map, maps, locations) => {
    const bounds = new maps.LatLngBounds();

    locations.forEach((location) => {
      bounds.extend(new maps.LatLng(location.coordinates.lat, location.coordinates.lng));
    });
    return bounds;
  };
  const bindResizeListener = (map, maps, bounds) => {
    maps.event.addDomListenerOnce(map, 'idle', () => {
      maps.event.addDomListener(window, 'resize', () => {
        map.fitBounds(bounds);
      });
    });
  };
  const handleApiLoaded = ({ map, maps }) => {
    const markers = [];
    const infowindows = [];
    locations.forEach((location) => {
      markers.push(
        new maps.Marker({
          position: {
            lat: parseFloat(location.coordinates.lat),
            lng: parseFloat(location.coordinates.lng),
          },
          map,
        })
      );

      infowindows.push(
        new maps.InfoWindow({
          content: generateLocationInnerHTML(location),
        })
      );
    });
    const bounds = getMapBounds(map, maps, locations);
    map.fitBounds(bounds);
    bindResizeListener(map, maps, bounds);
    markers.forEach((marker, i) => {
      marker.addListener('click', () => {
        infowindows[i].open(map, marker);
      });
    });
  };

  return (
    <div id="residences-map" className="map map-small">
      <GoogleMap
        apiKey={geocodingKey}
        defaultCenter={defaultCenter}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={handleApiLoaded}
      />
    </div>
  );
};

export default Map;
