import React from 'react';
import { ReactComponent as PrintIcon } from '../../../../assets/icons/Social Media Icons/Print.svg';
import { ReactComponent as EmailIcon } from '../../../../assets/icons/Social Media Icons/Email.svg';
import { ReactComponent as FacebookIcon } from '../../../../assets/icons/Social Media Icons/Facebook.svg';
import { ReactComponent as TwitterIcon } from '../../../../assets/icons/Social Media Icons/Twitter.svg';
import { ReactComponent as LinkedInIcon } from '../../../../assets/icons/Social Media Icons/LinkedIn.svg';
export default function ShareButtons() {
  return (
    <div className="article-share">
      <div className="st-custom-button" data-network="print">
        <span className="hidden">Print</span>
        <PrintIcon className="st-print" />
      </div>
      <div className="st-custom-button" data-network="email">
        <span className="hidden">Email</span>
        <EmailIcon className="st-email" />
      </div>
      <div className="st-custom-button" data-network="facebook">
        <span className="hidden">Share to Facebook</span>
        <FacebookIcon className="st-fb" />
      </div>
      <div className="st-custom-button" data-network="twitter">
        <span className="hidden">Share to Twitter</span>
        <TwitterIcon className="st-twitter" />
      </div>
      <div className="st-custom-button" data-network="linkedin">
        <span className="hidden">Share to LinkedIn</span>
        <LinkedInIcon className="st-li" />
      </div>
    </div>
  );
}
