import React from 'react';
import { withDatasourceCheck } from '../../../Foundation/HOC/withDatasourceCheck';
import { ProvinceList, Map } from './ui';

const ResidenceList = ({ fields }) => {
  // fields.ApiKey is the google maps api key

  const showMap = Boolean(
    !fields.RightColumn?.length && fields.LeftColumn?.length && fields.LocationsForMap?.length
  );
  return (
    <section className="find-residence" role="region" aria-label="Find Residence List">
      <div className="container">
        <div className="row pb-5">
          <div className="col-sm-6">
            <ProvinceList provinces={fields.LeftColumn} />
          </div>
          <div className="col-sm-6">
            <ProvinceList provinces={fields.RightColumn} />
            {showMap && <Map locations={fields.LocationsForMap} />}
          </div>
        </div>
      </div>
    </section>
  );
};

export default withDatasourceCheck()(ResidenceList);
