import React from 'react';
const ResidenceItem = ({ residence }) => {
  if (residence.show !== '1') {
    return null;
  }
  return (
    <li>
      <a href={residence.url}>
        {residence.residenceName ?? residence.residenceTitle}
        {residence.campaign && <span className="highlight">{residence.campaign}</span>}
      </a>
    </li>
  );
};

export default ResidenceItem;
