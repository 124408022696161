import React from 'react';
import useWindowSize from '../../../../Foundation/Hooks/useWindowSize';

const HitsFilter = (data) => {
  const { option, handler, selected } = data;
  const { width } = useWindowSize();

  if (width < 992) {
    return (
      <select
        id="blog-filter-categories"
        className="custom-select"
        onChange={(e) => handler(e.target.value)}
        selected={selected}
      >
        <option value="">All Categories</option>
        {option.map((opt, i) => (
          <option value={opt.Id} key={i}>
            {opt.Name}
          </option>
        ))}
      </select>
    );
  }
  return option.map((opt, i) => (
    <button
      className={`btn btn-outline-secondary ${selected === opt.Id ? 'active' : ''}`}
      data-category={opt.Id}
      key={i}
      onClick={() => handler(opt.Id === selected ? '' : opt.Id)}
    >
      {opt.Name}
    </button>
  ));
};
export default HitsFilter;
