import React from 'react';
import { getFieldValue } from '@sitecore-jss/sitecore-jss-react';
import { withDatasourceCheck } from '../../../Foundation/HOC/withDatasourceCheck';
import { ReactComponent as SearchIcon } from '../../../../assets/icons/search.svg';
import getLandingPages from '../../../Foundation/Utils/getLandingPages';

const ResidenceSearchBox = ({ fields }) => {
  const [searchTerm, setSearchTerm] = React.useState('');
  const [isMcChecked, setIsMcChecked] = React.useState(false);
  const [showError, setShowError] = React.useState(false);
  const [landingPages, setLandingPages] = React.useState([]);
  const memoryCareOptionLabel = getFieldValue(fields, 'Memory Care Option Label');
  const handleSubmit = (e) => {
    e.preventDefault();
    if (searchTerm) {
      const resultPage = fields['Results Page']?.url || '';
      const encodedSearchTerm = encodeURIComponent(searchTerm.trim().replaceAll(' ', '-'));
      if (!landingPages.includes(encodedSearchTerm.toLowerCase())) {
        window.location.href =
          `${resultPage}/search?llquery=${encodedSearchTerm}&mc=` + isMcChecked;
      } else {
        window.location.href = `${resultPage}/${encodedSearchTerm.toLowerCase()}`;
      }
    } else {
      setShowError(true);
    }
  };

  React.useEffect(() => {
    getLandingPages().then((result) => {
      setLandingPages(result);
    });
  }, []);

  React.useEffect(() => {
    if (showError && searchTerm) {
      setShowError(false);
    }
  }, [searchTerm]);

  return (
    <section className="find-residence">
      <div role="search" className="container">
        <div className="row align-items-center py-4">
          <div className="col-md-6 col-sm-5">
            <div className="search-form">
              <form onSubmit={handleSubmit} id="residenceSearchBox">
                <div className="input-group">
                  <input
                    className="form-control search-input"
                    id="llquery"
                    name="llquery"
                    aria-label={getFieldValue(fields, 'Accessibility Label Text')}
                    placeholder={getFieldValue(fields, 'Placeholder Text')}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    data-gaconnector-tracked="true"
                  />
                  {showError && (
                    <span className="field-validation-error">
                      Please enter a city or postal code
                    </span>
                  )}
                  <div className="input-group-append">
                    <button type="submit" className="btn" aria-label="search" tabIndex={-1}>
                      <SearchIcon />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-md-6 col-sm-7 d-flex flex-column flex-sm-row justify-content-around align-items-center">
            <label
              title={getFieldValue(fields, 'Memory Care Option Tooltip')}
              className="checkbox my-4 mr-auto"
              htmlFor="memorycare"
            >
              {memoryCareOptionLabel && (
                <>
                  <input
                    title={getFieldValue(fields, 'Memory Care Option Tooltip')}
                    aria-label={getFieldValue(fields, 'Memory Care Option Tooltip')}
                    type="checkbox"
                    id="memorycare"
                    name="memorycare"
                    checked={isMcChecked}
                    onChange={(e) => setIsMcChecked(e.target.checked)}
                  />
                  {memoryCareOptionLabel}
                </>
              )}
            </label>

            <button type="submit" form="residenceSearchBox" className="btn btn-secondary my-2">
              {getFieldValue(fields, 'Search Button Title')}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default withDatasourceCheck()(ResidenceSearchBox);
