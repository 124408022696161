import React from 'react';

export function isMoreThanOneDay(EndDate, StartDate) {
  let MoreThanOneDay = false;
  if (EndDate.getDate() !== StartDate.getDate()) {
    MoreThanOneDay = true;
  }
  if (EndDate.getMonth() !== StartDate.getMonth()) {
    MoreThanOneDay = true;
  }
  if (EndDate.getFullYear() !== StartDate.getFullYear()) {
    MoreThanOneDay = true;
  }
  return MoreThanOneDay;
}

export function GetTimesRendering(StartDate, EndDate) {
  const options = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: 'true',
  };
  return (
    <p>
      {StartDate.toLocaleString('en-us', options)} - {EndDate.toLocaleString('en-us', options)}
    </p>
  );
}

export function GetDateRendering(MoreThanOneDay, Start, End) {
  if (MoreThanOneDay) {
    if (End.getFullYear() !== Start.getFullYear()) {
      let options = { year: 'numeric', month: 'long', day: 'numeric' };
      return (
        <p>
          {Start.toLocaleDateString('en-US', options)} - {End.toLocaleDateString('en-US', options)}
        </p>
      );
    } else {
      let options1 = { day: 'numeric', month: 'long' };
      let options2 = { year: 'numeric', month: 'long', day: 'numeric' };
      return (
        <p>
          {Start.toLocaleDateString('en-US', options1)} -{' '}
          {End.toLocaleDateString('en-US', options2)}
        </p>
      );
    }
  } else {
    let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    // Wednesday, 09 March 2022
    return <p>{Start.toLocaleDateString('en-US', options)}</p>;
  }
}
