import React from 'react';

const Menu = ({ show = true, children }) => {
  if (!show) {
    return null;
  }

  return <div className="menu">{children}</div>;
};

export default Menu;
