import React from 'react';
import clsx from 'clsx';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { withDatasourceCheck } from '../../../Foundation/HOC/withDatasourceCheck';
import { Address, Menu, FollowUs, HashTag, Policies } from './ui';

const Footer = ({ fields, rendering }) => {
  const {
    isResidence,
    residenceName,
    address1,
    phoneNumber,
    city,
    province,
    postalCode,
    residence,
    contactUs,
    links,
    followUs,
    hashtagLink,
    hashtagTarget,
    hashtagText,
    policies,
  } = fields;
  return (
    <footer
      className={clsx('footer', isResidence && 'residence-footer')}
      data-residence-id={residence?.ID}
    >
      <div className="footer-main">
        <div className="wrapper">
          {contactUs && (
            <Address
              title={contactUs?.Title}
              isResidence={isResidence}
              name={residenceName}
              city={city}
              province={province}
              postalCode={postalCode}
              phoneNumber={isResidence ? phoneNumber : contactUs?.['Phone Number']}
              address={isResidence ? address1 : contactUs?.['Office Address']}
            />
          )}
          <div className="ctas">
            <Placeholder name="phCTA" rendering={rendering} />
          </div>
          <hr />
          <Menu links={links} />
          <div className="social">
            <FollowUs title={followUs?.title} links={followUs?.links} />
            <HashTag link={hashtagLink} target={hashtagTarget} text={hashtagText} />
          </div>
        </div>
      </div>
      <div className="footer-legal">
        <div className="wrapper">
          <Policies policies={policies} />
          <p className="copyright">© Amica Senior Lifestyles {new Date().getFullYear()}</p>
        </div>
      </div>
    </footer>
  );
};

export default withDatasourceCheck()(Footer);
