import React from 'react';
import { useState, useEffect } from 'react';
import { Text, Placeholder, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Modal } from 'react-bootstrap';
import $ from 'jquery';
import { withDatasourceCheck } from '../../../Foundation/HOC/withDatasourceCheck';

const ModalContainer = ({ fields, rendering, sitecoreContext }) => {
  const [show, setShow] = useState(false);
  const isEditing = sitecoreContext && sitecoreContext.pageEditing;

  const handleModal = () => {
    if (show) {
      setShow(false);
    } else {
      setShow(true);
    }
  };

  function detectModalClick() {
    if (isEditing) {
      return;
    }
    $(function () {
      $('.call2action').on('click', function () {
        let target = this.getAttribute('data-target');
        if ('#' + fields['Modal Name']?.value === target) {
          setShow(true);
        }
      });
    });
  }

  useEffect(() => {
    if (!isEditing) {
      detectModalClick();
    }
  }, []);

  if (isEditing) {
    return (
      <div show={show} id={fields['Modal Name']?.value} name={fields['Modal Name']?.value}>
        <div className="modal-dialog" role="document">
          <div id="subscribe" className="modal-content">
            <div className="modal-header">
              <Text field={fields['Modal Title']} tag="h2" className="modal-title" />
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div>
                <Placeholder name="phModals" rendering={rendering} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <Modal
          show={show}
          onHide={handleModal}
          id={fields['Modal Name']?.value}
          name={fields['Modal Name']?.value}
        >
          <Modal.Dialog role="document" className="inner-dialog">
            <Modal.Header>
              <Text field={fields['Modal Title']} tag="h2" className="modal-title" />
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={handleModal}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </Modal.Header>
            <Modal.Body>
              <div>
                <Placeholder name="phModals" rendering={rendering} />
              </div>
            </Modal.Body>
          </Modal.Dialog>
        </Modal>
      </>
    );
  }
};

export default withSitecoreContext()(withDatasourceCheck()(ModalContainer));
