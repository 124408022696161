import React, { useEffect, useState } from 'react';
import authorService from './authorService';
import { ReactComponent as LoadingIcon } from '../../../../assets/icons/loading.svg';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { withDatasourceCheck } from '../../../Foundation/HOC/withDatasourceCheck';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const AuthorPageArticles = ({ fields, sitecoreContext }) => {
  const authorId = fields?.Author?.id;
  const isEditing = sitecoreContext?.pageEditing;
  if (!authorId) {
    if (isEditing) {
      return <p>Please assign an author to this component.</p>;
    }
    return null;
  }
  let params = {
    categoryID: '',
    pageNumber: 0,
    loadAll: 1,
    pageSize: 3,
    authorId,
  };
  const [loading, setLoading] = useState(true);
  const [articles, setArticles] = useState([]);
  const [articlesTotals, setArticlesTotals] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);

  const setFeaturedArticle = (items) => {
    // Map around items list to change first article IsFeaturedArticle == "1"
    let newList = items?.map((item, index) => {
      if (index === 0) {
        item = { ...item, IsFeaturedArticle: '1' };
      }
      return item;
    });
    return newList;
  };

  useEffect(() => {
    loadArticles();
  }, []);

  const loadArticles = () => {
    authorService.getArticlesByAuthorId(params).then((response) => {
      let responseItem1 = response.m_Item1 || response.Item1;
      let articlesArray = responseItem1;
      setArticlesTotals(response.m_Item2 || response.Item2);
      let hasFeaturedArticle = responseItem1?.find((x) => x.IsFeaturedArticle === '1') || null;

      if (hasFeaturedArticle === null) {
        articlesArray = setFeaturedArticle(responseItem1);
      }

      // Sort List By IsFeaturedArticle
      articlesArray = articlesArray?.sort((x) => {
        return x.IsFeaturedArticle === '1' ? -1 : 1;
      });

      if (!articlesArray) {
        articlesArray = [];
      }

      setLoading(false);
      setArticles(articlesArray);
    });
  };

  const loadMoreArticles = () => {
    setLoading(true);
    let updatedParams = { ...params, pageNumber: currentPage + 1, loadAll: 0 };
    setCurrentPage(currentPage + 1);
    authorService.getArticlesByAuthorId(updatedParams).then((response) => {
      let articlesArray = response.m_Item1 || response.Item1;
      setArticlesTotals(response.m_Item2 || response.Item2);
      setLoading(false);

      setArticles([...articles, ...articlesArray]);
    });
  };
  return (
    <>
      {isEditing ? (
        <section id="author-articles" className="articles articles-page" data-filter={authorId}>
          <div className="container" aria-label="Articles">
            <ul id="author-articles-list" className="articles-list">
              {articles?.map((article, index) => (
                <li key={index} className={`${article.IsFeaturedArticle && 'featured'} `}>
                  <article className="card">
                    <a href={article.Url}>
                      <div className="card-image">
                        <LazyLoadImage src={article.ImageUrl} alt={article.ImageAlt} />
                      </div>
                      <div className="card-body">
                        <h3>{article.Title}</h3>
                        <p>{article.Teaser}</p>
                      </div>
                    </a>
                    <div className="card-footer">
                      <a href={article.PrimaryCategoryUrl}>{article.PrimaryCategoryTitle}</a>
                    </div>
                  </article>
                </li>
              ))}
            </ul>

            {!loading && articles?.length === 0 && (
              <p id="author-articles-empty" className="articles-empty">
                No results to show
              </p>
            )}

            {loading && (
              <div className="loading-indicator">
                <LoadingIcon />
              </div>
            )}

            {articles?.length > 0 && articles?.length < articlesTotals && (
              <div className="articles-pagination">
                <button
                  id="author-articles-load-more"
                  className="btn btn-outline-primary"
                  onClick={() => loadMoreArticles()}
                >
                  View More
                </button>
              </div>
            )}
          </div>
        </section>
      ) : (
        <div>
          <section id="author-articles" className="articles articles-page" data-filter={authorId}>
            <div className="container" role="navigation" aria-label="Articles">
              <ul id="author-articles-list" className="articles-list">
                {articles?.map((article, index) => (
                  <li key={index} className={`${article.IsFeaturedArticle && 'featured'} `}>
                    <article className="card">
                      <a href={article.Url}>
                        <div className="card-image">
                          <LazyLoadImage src={article.ImageUrl} alt={article.ImageAlt} />
                        </div>
                        <div className="card-body">
                          <h3 aria-label="Title">{article.Title}</h3>
                          <p aria-label="Teaser">{article.Teaser}</p>
                        </div>
                      </a>
                      <div className="card-footer">
                        <a href={article.PrimaryCategoryUrl}>{article.PrimaryCategoryTitle}</a>
                      </div>
                    </article>
                  </li>
                ))}
              </ul>

              {!loading && articles?.length === 0 && (
                <p id="author-articles-empty" className="articles-empty">
                  No results to show
                </p>
              )}

              {loading && (
                <div className="loading-indicator">
                  <LoadingIcon />
                </div>
              )}

              {articles?.length > 0 && articles?.length < articlesTotals && (
                <div className="articles-pagination">
                  <button
                    id="author-articles-load-more"
                    className="btn btn-outline-primary"
                    onClick={() => loadMoreArticles()}
                  >
                    View More
                  </button>
                </div>
              )}
            </div>
          </section>
        </div>
      )}
    </>
  );
};
export default withSitecoreContext()(withDatasourceCheck()(AuthorPageArticles));
