import React from 'react';
import { isExperienceEditorActive, Text } from '@sitecore-jss/sitecore-jss-react';
import { withDatasourceCheck } from '../../../Foundation/HOC/withDatasourceCheck';
import Asset from '../../../Foundation/DFS/Asset';
import { getPropertyByPath } from '../../../Foundation/Utils';

const FindResidenceSearchResults = ({ fields }) => {
  const { item, searchResults } = fields;
  const searchText = getPropertyByPath(item, 'Your Search');
  const nearbyLocationsText = getPropertyByPath(item, 'Nearby Locations Found');
  const searchValidationText = getPropertyByPath(item, 'Search Validation Text');
  const visitResidenceCTAText = getPropertyByPath(item, 'Visit Residence CTA');
  const residences = searchResults.residences;
  const noResults = searchResults.noResults;
  const noNearbyResults = searchResults.noNearbyResults;
  const keyword = searchResults.keyword;
  return (
    <section className="find-residence filtered py-3">
      <div className="container-fluid navigation-panel p-0" style={{ background: 'transparent' }}>
        <div className="res-search-result-container container">
          <div className="your-search">
            {residences?.length > 0 ? (
              <>
                <Text
                  tag="span"
                  className="label"
                  field={searchText}
                  style={{ fontWeight: 'bold' }}
                />
                <span className="terms">{` ${keyword.replace('-', ' ')} (${
                  residences.length
                } results)`}</span>
              </>
            ) : (
              <Text
                tag="span"
                className="terms"
                field={
                  noResults && !noNearbyResults
                    ? nearbyLocationsText
                    : noResults && noNearbyResults
                    ? searchValidationText
                    : null
                }
              />
            )}
          </div>
          <div className="row justify-content-center text-center">
            {residences?.map((item, key) => {
              const {
                imageUrl,
                imageAlt,
                residenceImageUrl,
                residenceImageAlt,
                residenceName,
                residenceTitle,
                address1,
                address2,
                cityName,
                url,
                provinceName,
                postalCode,
                title,
                name,
              } = item;
              const image = {
                value: {
                  src: residenceImageUrl || imageUrl,
                  alt: residenceImageAlt || imageAlt,
                  asset: {
                    name: residenceTitle || residenceName,
                  },
                },
              };
              return (
                <div key={key} className="col-lg-3 col-md-6 col-sm-6 py-3">
                  <div className="main-content d-flex flex-column ">
                    <div className="residence-media">
                      <Asset editor={isExperienceEditorActive()} field={image} />
                    </div>
                    <div className="content">
                      {(title || name) && <h2>{title || name}</h2>}
                      <p>
                        {address1} {address2}
                        {`${cityName}, ${provinceName} ${postalCode}`}
                      </p>
                    </div>
                    <div className="d-flex flex-column justify-content-end flex-grow-1">
                      {url && (
                        <a id="visit-res-btn" className="call2action btn" href={url}>
                          {`${visitResidenceCTAText?.value}`}
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default withDatasourceCheck()(FindResidenceSearchResults);
