import React from 'react';
import { isExperienceEditorActive, Text, Link } from '@sitecore-jss/sitecore-jss-react';
import { ReactComponent as LinkIcon } from '../../../../assets/icons/link.svg';
import Asset from '../../../Foundation/DFS/Asset';
import { withDatasourceCheck } from '../../../Foundation/HOC/withDatasourceCheck';

function isEmptyObject(obj) {
  return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
}

function RollUpCardImage({ imageField }) {
  if (!isExperienceEditorActive() || imageField.url) {
    return (
      <Asset editor={isExperienceEditorActive()} field={imageField} style={{ maxWidth: 460 }} />
    );
  } else {
    if (isEmptyObject(imageField)) {
      return <p>Image not found</p>;
    } else {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: imageField,
          }}
        ></div>
      );
    }
  }
}

function AuthorArticlesRollup({ fields }) {
  const rollupItemsCount = fields['Rollup Article Count'];
  const articleRollupItems = fields.articleRollupItems;
  const linkText = fields['Link Text'].value;

  if (!Array.isArray(articleRollupItems) || articleRollupItems.length === 0) {
    if (isExperienceEditorActive()) {
      return <p>There is no author assigned or assigned author has no other articles to show.</p>;
    } else {
      return null;
    }
  }

  return (
    <section className="articles articles-rollup">
      <div role="banner" aria-label="Rollup" className="container">
        <div className="articles-rollup-header">
          <Text tag="h2" field={fields.Heading} />

          {fields.Link?.value?.href && (
            <a className="link-goto" href={fields.Link.value.href}>
              {linkText} <LinkIcon />
            </a>
          )}
        </div>
        <ul className="articles-list">
          {articleRollupItems.map((item, index) => (
            <li key={index}>
              <article className="card">
                <a href={item.url}>
                  <div className="card-image">
                    <RollUpCardImage imageField={item.fields.Image.value} />
                  </div>
                  <div className="card-body">
                    <Text tag="h3" field={item.fields['Page Title']}></Text>
                    <Text tag="p" field={item.fields.Teaser}></Text>
                  </div>
                </a>
                {item.CategoryLandingPageUrl && (
                  <div className="card-footer">
                    <Link field={{ href: item.CategoryLandingPageUrl }}>{item.CategoryTitle}</Link>
                  </div>
                )}
              </article>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}

export default withDatasourceCheck()(AuthorArticlesRollup);
