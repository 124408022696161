import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import Asset from '../../../../../Foundation/DFS/Asset';
import useWindowSize from '../../../../../Foundation/Hooks/useWindowSize';

const HeroMedia = ({
  video = {},
  videoMobile = {},
  videoTablet = {},
  image = {},
  imageMobile = {},
  imageTablet = {},
  hideOnMobile,
}) => {
  const [isIOS, setIOS] = useState(false);
  const windowSize = useWindowSize();

  const showVideo = (() => {
    const media = {
      desktop: {
        video: !!video?.src,
        breakpoint: 1200,
      },
      tablet: {
        video: !!videoTablet?.src,
        breakpoint: 576,
      },
      mobile: {
        video: !!videoMobile?.src,
        breakpoint: 0,
      },
    };
    // eslint-disable-next-line guard-for-in
    for (const key in media) {
      const br = media[key].breakpoint;
      const v = media[key].video;
      if (windowSize.width >= br) return v;
    }
  })();

  useEffect(() => {
    if (/iPad|iPhone|iPod|Macintosh/.test(window.navigator.userAgent)) {
      setIOS(true);
    }
  }, []);

  return (
    <React.Fragment>
      {showVideo && !isIOS && (
        <video
          autoPlay
          muted
          loop
          aria-hidden="true"
          id="video-aux"
          className={clsx(hideOnMobile && 'hidden-on-mobile')}
        >
          <source media="(min-width: 1200)" src={video.src} type="video/mp4" id="video-desktop" />
          <source
            media="(max-width: 575.98px)"
            src={videoMobile.src || video.src}
            type="video/mp4"
            id="video-mobile"
          />
          <source
            media="(min-width: 576px) and (max-width: 1199.98px)"
            src={videoTablet.src || video.src}
            type="video/mp4"
            id="video-tablet"
          />
        </video>
      )}
      {(!showVideo || isIOS) && (
        <picture id="picture-aux" className={clsx(hideOnMobile && 'hidden-on-tablet')}>
          <source media="(min-width: 1200)" srcSet={image.src} />
          <source media="(max-width: 575.98px)" srcSet={imageMobile.src} />
          <source media="(min-width: 576px) and (max-width: 1199.98px)" srcSet={imageTablet.src} />
          {image && image.src ? (
            <Asset editor={true} field={image} aria-hidden="true" />
          ) : (
            imageMobile &&
            imageMobile.src && <Asset editor={true} field={imageMobile} aria-hidden="true" />
          )}
        </picture>
      )}
    </React.Fragment>
  );
};

export default HeroMedia;
