import React, { useState, useEffect } from 'react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { ReactComponent as LoadingIndicator } from '../../../../assets/icons/loading-indicator.svg';
import { useHistory, useLocation } from 'react-router-dom';

import articlesService from '../../../Foundation/services/articlesService';
import { getPropertyByPath, objToQueryString, qsToObj } from '../../../Foundation/Utils';
import { Style } from '../../../Foundation/Utils/stringUtils';

// components
import ArticlesList from './components/articlesList';
import ArticlesFilter from './components/articlesFilter';

const ArticleList = (props) => {
  let history = useHistory();
  const { fields, rendering } = props;
  const CategoryID = getPropertyByPath(fields, 'CategoryID.value') || '';
  const LoadMoreText = getPropertyByPath(fields, 'LoadMoreText.value');
  const NoResultsText = getPropertyByPath(fields, 'NoResultsText.value');
  const QueryLabelText = getPropertyByPath(fields, 'QueryLabelText.value');
  const search = useLocation().search;

  const setDefaultParams = () => {
    const searchObjs = qsToObj(search);
    if (searchObjs && Object.keys(searchObjs).length !== 0 && searchObjs.constructor === Object) {
      return {
        categoryId: searchObjs.category || CategoryID || '',
        pageNumber: searchObjs.page || 1,
        loadAll: 1,
      };
    } else {
      return { categoryId: CategoryID, pageNumber: 0, loadAll: 1 };
    }
  };

  const [params, setParams] = useState(setDefaultParams);
  const [categories, setCategories] = useState([]);
  const [filterCategory, setfilterCategory] = useState([]);
  const [articles, setArticles] = useState([]);
  const [articlesCount, setArticlesCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [showNav, setShowNav] = useState(true);
  if (!fields) {
    return <div>Datasource is required. Please choose a content item for this component.</div>;
  }

  useEffect(() => {
    console.log('i fire twice');
    setHistory();
    loadArticles();
    var blog = document.getElementById('blog');
    var prefilter = blog.getAttribute('data-filter');
    if (prefilter) {
      setShowNav(false);
    }
  }, [params]);

  const setHistory = () => {
    let searchObj = {};

    if (CategoryID === '' && params.categoryId) {
      Object.assign(searchObj, {
        category: params.categoryId,
      });
    }
    if (params.pageNumber > 0) {
      Object.assign(searchObj, {
        page: params.pageNumber,
      });
    }

    const queryString = objToQueryString(searchObj);
    history.push({
      search: `?${queryString}`,
    });
  };

  const loadArticles = () => {
    if (params) {
      let _articlesCount = articlesCount;
      let _articles = articles;

      if (params.pageNumber === 0) {
        setArticlesCount(0);
        _articlesCount = 0;
        setArticles([]);
        _articles = [];
        setTotalCount(0);
      }
      setLoading(true);
      articlesService.getArticles(params).then((data) => {
        setLoading(false);
        const articlesData = data.m_Item1 || data.Item1;
        const articlesTotal = data.m_Item2 || data.Item2;
        const filterCategory = data.m_Item3 || data.Item3;
        const filtersData = data.m_Item4 || data.Item4;
        if (articlesData?.length) {
          let noFeatured = true;
          const numberOfArticlesToSearchForFeatured = 4;
          for (let i = 0; i < numberOfArticlesToSearchForFeatured; ++i) {
            if (articlesData[i].IsFeaturedArticle === '1') {
              noFeatured = false;
              break;
            }
          }
          if (noFeatured) {
            articlesData[0].IsFeaturedArticle = '1';
          }
          setArticlesCount(articlesData.length + _articlesCount);
          setCategories(filtersData);
          setfilterCategory(filterCategory);
          setArticles([..._articles, ...articlesData]);
          setTotalCount(articlesTotal);
        }
      });
    }
  };
  const loadMore = () => {
    setParams({
      ...params,
      ...{
        pageNumber: parseInt(params.pageNumber) + 1,
        loadAll: 0,
      },
    });
  };
  return (
    <section id="blog" className="articles articles-page" data-filter={fields.CategoryID?.value}>
      <Placeholder name="phArticleTop" rendering={rendering} />
      <div className="container">
        <nav
          id="blog-filter"
          className="articles-filters"
          style={showNav ? {} : { display: 'none' }}
        >
          {categories && params && (
            <ArticlesFilter
              option={categories}
              handler={(v) => {
                setParams({
                  ...params,
                  ...{
                    categoryId: v,
                    pageNumber: 0,
                  },
                });
              }}
              selected={params.categoryId}
            />
          )}
        </nav>

        {articlesCount ? (
          <ArticlesList
            articles={articles}
            filterCategory={params.categoryId}
            filterCategoryObject={filterCategory}
          />
        ) : (
          !loading && (
            <p id="blog-empty" className="articles-empty">
              {NoResultsText}
            </p>
          )
        )}
        {loading && <LoadingIndicator />}
        {!loading && articlesCount !== 0 && articlesCount < totalCount && (
          <div className="articles-pagination" style={!loading ? Style('display: block') : ''}>
            <button
              id="media-hits-load-more"
              className="btn btn-outline-primary"
              onClick={loadMore}
            >
              {LoadMoreText}
            </button>
          </div>
        )}
      </div>
    </section>
  );
};
export default ArticleList;
