import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Article = ({
  url,
  imageUrl,
  imageAlt,
  title,
  teaser,
  primaryCategoryUrl,
  primaryCategoryTitle,
}) => (
  <li className="article-list-li">
    <article className="card border">
      <a href={url}>
        <div className="card-image">
          <LazyLoadImage src={imageUrl} alt={imageAlt} />
        </div>
        <div className="card-body">
          <h3 className="card-article-name">{title}</h3>
          <p className="card-article-desc">{teaser}</p>
        </div>
      </a>
      <div className="card-footer">
        <a href={primaryCategoryUrl} onClick={() => (location.href = primaryCategoryUrl)}>
          {primaryCategoryTitle}
        </a>
      </div>
    </article>
  </li>
);

export default Article;
