import React from 'react';
import { getFieldValue, withSitecoreContext, Text, Link } from '@sitecore-jss/sitecore-jss-react';
import ModalDialog from 'react-bootstrap/ModalDialog';
import Modal from 'react-bootstrap/Modal';
import { withDatasourceCheck } from '../../../Foundation/HOC/withDatasourceCheck';
import { ReactComponent as VideoIcon } from '../../../../assets/icons/video.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg';
import VideoAsset from '../../../Foundation/DFS/VideoAsset/index';

function getButtonClasses(fields) {
  if (fields['Hide In Mobile']?.value) {
    return 'btn btn-light hide-cta-in-mobile';
  }
  return 'btn btn-light';
}

const VideoCta = ({ fields, sitecoreContext }) => {
  const isEditing = sitecoreContext && sitecoreContext.pageEditing;
  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const DialogWrapper = ({ children }) => (
    <>
      <button type="button" className="close" aria-label="Close" onClick={handleClose}>
        <CloseIcon />
      </button>
      {children}
    </>
  );
  if (isEditing) {
    return (
      <div>
        <button type="button" className={getButtonClasses(fields)} onClick={handleShow}>
          <Text field={fields?.Label} />
          <br />
          <div className="ee-video-container">
            {getFieldValue(fields, 'Video')?.src ? (
              <VideoAsset field={fields.Video} />
            ) : (
              <Text field={fields['Video URL']} />
            )}
          </div>
        </button>
      </div>
    );
  }
  if (fields !== undefined) {
    return (
      <React.Fragment>
        <button type="button" className={getButtonClasses(fields)} onClick={handleShow}>
          <VideoIcon />
          {getFieldValue(fields, 'Label')}
        </button>
        <Modal className="modal-video" dialogAs={DialogWrapper} show={show} onHide={handleClose}>
          <ModalDialog size="lg" centered role="document">
            <Modal.Body bsPrefix="moda-body">
              <div className="embed-responsive embed-responsive-16by9">
                {getFieldValue(fields, 'Video URL') ? (
                  <iframe
                    className="embed-responsive-item"
                    src={getFieldValue(fields, 'Video URL')
                      .replace('watch?v=', 'embed/')
                      .replace('.be/', 'be.com/embed/')}
                    allowFullScreen
                  />
                ) : (
                  <video className="embed-responsive-item" controls>
                    <source src={getFieldValue(fields, 'Video')?.src} type="video/mp4" />
                  </video>
                )}
              </div>
            </Modal.Body>
          </ModalDialog>
        </Modal>
      </React.Fragment>
    );
  }
};
export default withSitecoreContext()(withDatasourceCheck()(VideoCta));
