import React from 'react';
import { getFieldValue } from '@sitecore-jss/sitecore-jss-react';
import { ReactComponent as SearchIcon } from '../../../../assets/icons/search.svg';

const MainSearchBox = ({ fields }) => {
  const placeHolderText = getFieldValue(fields, 'Placeholder Text');
  const [searchTerm, setSearchTerm] = React.useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (searchTerm) {
      const resultPage = '/SearchResults';
      window.location.href = `${resultPage}?srquery=${searchTerm}`;
    }
  };

  return (
    <div className="search-results">
      <div className="container">
        <div className="search-form">
          <form id={'MainSearchBox'} onSubmit={handleSubmit}>
            <div className="input-group border">
              <label htmlFor="srquery" className="hidden">
                {placeHolderText}
              </label>
              <input
                className="form-control border-0 search-input"
                id="srquery"
                name="srquery"
                placeholder={placeHolderText}
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <div className="input-group-append">
                <button type="submit" className="btn border-0">
                  <SearchIcon />
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default MainSearchBox;
