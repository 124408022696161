import React from 'react';
import { getFieldValue, Text, isExperienceEditorActive } from '@sitecore-jss/sitecore-jss-react';
import { withDatasourceCheck } from '../../../Foundation/HOC/withDatasourceCheck';
import { ReactComponent as SearchIcon } from '../../../../assets/icons/search.svg';
import getLandingPages from '../../../Foundation/Utils/getLandingPages';

const FindResidenceSearchResults = ({ fields }) => {
  const AccessibilityLabelText = getFieldValue(fields, 'Accessibility Label Text');
  const MemoryCareOptionTooltip = getFieldValue(fields, 'Memory Care Option Tooltip');
  const PlaceHolderText = getFieldValue(fields, 'Placeholder Text');
  const DisplayMemoryCareOption = getFieldValue(fields, 'Display Memory Care Option');
  const MemoryCareOptionLabel = getFieldValue(fields, 'Memory Care Option Label');
  const SearchButtonTitle = getFieldValue(fields, 'Search Button Title');
  const Header = getFieldValue(fields, 'Header');
  const Teaser = getFieldValue(fields, 'Teaser');
  const HideBackground = getFieldValue(fields, 'Hide Background');
  const [searchTerm, setSearchTerm] = React.useState('');
  const [isMcChecked, setIsMcChecked] = React.useState(false);
  const [landingPages, setLandingPages] = React.useState([]);

  const onSubmit = (e) => {
    e.preventDefault();
    if (searchTerm) {
      const resultPage = fields['Results Page']?.url || '';
      const encodedSearchTerm = encodeURIComponent(searchTerm.trim().replaceAll(' ', '-'));
      if (!landingPages.includes(encodedSearchTerm.toLowerCase())) {
        window.location.href =
          `${resultPage}/search?llquery=${encodedSearchTerm}&mc=` + isMcChecked;
      } else {
        window.location.href = `${resultPage}/${encodedSearchTerm.toLowerCase()}`;
      }
    } else {
      setShowError(true);
    }
  };

  function getSectionStyle() {
    if (HideBackground) {
      return { backgroundColor: 'white' };
    } else if (fields['Background Image']?.value?.url) {
      return { backgroundImage: `url(${fields['Background Image']?.value?.url})` };
    }
  }

  React.useEffect(() => {
    getLandingPages().then((result) => {
      setLandingPages(result);
    });
  }, []);

  return (
    <section className="find-residence-widget" style={getSectionStyle()}>
      <div role="form" className="container">
        <form onSubmit={onSubmit} method="post" id={'NavigationSearchBox'}>
          <div className="content">
            {(Header || isExperienceEditorActive()) && (
              <label htmlFor="residenceSearchInput" className="h2">
                <Text field={fields.Header} />
              </label>
            )}
            {(Teaser || isExperienceEditorActive()) && <Text field={fields.Teaser} tag="p" />}
            <div className="input-group">
              <input
                className="form-control"
                id="frsbquery"
                name="frsbquery"
                placeholder={PlaceHolderText ? PlaceHolderText : 'Enter a city or postal code'}
                aria-label={AccessibilityLabelText}
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <div className="input-group-append">
                <button type="submit" className="btn">
                  <SearchIcon />
                </button>
              </div>
              {/* @Html.Hidden(url, @Html.Sitecore().Field(RESULTS PAGE, Model.Datasource)) */}
            </div>
            {((DisplayMemoryCareOption && MemoryCareOptionLabel) || isExperienceEditorActive()) && (
              <>
                <label className="checkbox">
                  <input
                    title={MemoryCareOptionTooltip}
                    id="memorycare"
                    type="checkbox"
                    name="memorycare"
                    checked={isMcChecked}
                    onChange={(e) => setIsMcChecked(e.target.checked)}
                  />{' '}
                  <Text field={fields['Memory Care Option Label']} />
                </label>
                {/* Html.Hidden(memorycare, false, new { @name = memorycare }) */}
              </>
            )}
            {(SearchButtonTitle || isExperienceEditorActive()) && (
              <button type="submit" className="btn btn-outline-primary">
                <Text field={fields['Search Button Title']} />
              </button>
            )}
          </div>
        </form>
      </div>
    </section>
  );
};

export default withDatasourceCheck()(FindResidenceSearchResults);
