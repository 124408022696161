import React from 'react';
import clsx from 'clsx';
import {
  RichText,
  Text,
  Placeholder,
  getFieldValue,
  withSitecoreContext,
} from '@sitecore-jss/sitecore-jss-react';
import Asset from '../../../Foundation/DFS/Asset';
import { withDatasourceCheck } from '../../../Foundation/HOC/withDatasourceCheck';
import Media from './media';

const alignmentMapping = {
  Top: 'align-items-start',
  Center: 'align-items-center',
  Bottom: 'align-items-end',
};

const getComponentInstances = (context, rendering) => {
  const placeholders = context.route.placeholders;
  const components = Object.keys(placeholders)
    .map((key) => placeholders[key])
    .flat();
  return components.filter((el) => el.componentName === rendering.componentName && el.fields);
};

const DifferentiatorPanel = ({ fields, rendering, sitecoreContext }) => {
  let backgroundImage = getFieldValue(fields, 'Background Image');
  const isEditing = sitecoreContext && sitecoreContext.pageEditing;

  const sectionStyle =
    backgroundImage !== null && backgroundImage.class !== 'scEmptyImage' && backgroundImage?.src
      ? {
          backgroundImage: `url(${getFieldValue(fields, 'Background Image')?.src})`,
        }
      : {};

  const sectionClassName = clsx(
    'differentiator',
    getFieldValue(fields, 'Background Image')?.src && 'small-diff',
    getFieldValue(fields, 'Additional Styles')
  );

  const isReverse = (() => {
    const differentiatorPanels = getComponentInstances(sitecoreContext, rendering);
    const componentIndex = differentiatorPanels.findIndex((el) => el.uid === rendering.uid);
    return (componentIndex + 1) % 2 === 0;
  })();

  return (
    <section
      id={getFieldValue(fields, 'Anchor ID')}
      className={sectionClassName}
      style={sectionStyle}
      role="complementary"
      aria-label="Panel"
    >
      <div
        className={clsx(
          'panel-container',
          isReverse && 'panel-reverse',
          alignmentMapping[fields.VerticalAlignment?.fields?.Value?.value || 'Center']
        )}
      >
        <div className="content">
          {fields?.Title && <Text tag="h2" field={fields.Title} />}
          {fields?.Description && <RichText className="description" field={fields.Description} />}
          <Placeholder name="phCTA" rendering={rendering} />
        </div>
        <div
          className={
            getFieldValue(fields, 'Show Image In Mobile Mode') ? 'media-show-in-mobile' : 'media'
          }
        >
          <Media fields={fields} isEditing={isEditing} />
        </div>
        {getFieldValue(fields, 'Mobile Background Image') && (
          <Asset
            editor={isEditing}
            className="mobile-background"
            field={fields['Mobile Background Image']}
          />
        )}
      </div>
    </section>
  );
};

export default withSitecoreContext()(withDatasourceCheck()(DifferentiatorPanel));
